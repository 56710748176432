import { get, post } from "../utils/request";

export const getCldAccessToken = async () => {
  const { data } = await post(`/proxy/@auth/api/v2/tokens`, {
    key: process.env.REACT_APP_COMMON_AUTH_KEY,
    user: process.env.REACT_APP_COMMON_AUTH_USER,
    ttl: 3600,
  });
  return data.token;
};

// TODO 缓存
export async function listAuthUsers(project = "cld") {
  let tokenData = await getCldAccessToken();
  if (tokenData) {
    let response = await get(
      // "/proxy/@auth/api/v1/teams/@ne.nie.sa.infra/members",
      `/proxy/@auth/api/v2/users?_project=${project}`,
      {
        headers: {
          "X-Access-Token": tokenData.token,
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  }
}
