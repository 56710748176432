import "./App.css";
import "./theme.less";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useNotification } from "./hooks/common/useNotification";
import { getAuthUserName } from "./utils/cookie";
import { getUserByName } from "./api/user";
import { localSaveAuthUser } from "./localstorage/user";
import AppRoutes from "./routes/new";

export const AppContext = React.createContext();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.log("query error:", err.toString());
      },
    },
    mutations: {
      retry: false,
      onError: (err) => {
        console.log("query error:", err.toString());
      },
    },
  },
});

function App(props) {
  const [routeStack, setRouteStack] = useState([]);
  const [authUser, setAuthUser] = useState({
    metadata: {
      name: getAuthUserName(),
      fullname: "",
      team: {
        code: "",
        name: "",
      },
      annotations: {},
    },
    role: {
      _: "readonly",
    },
  });
  const [namespace, setNamespace] = useState("");
  const notification = useNotification();

  useEffect(() => {
    fetchAuthUser();
  }, [getAuthUserName()]);

  const fetchAuthUser = async () => {
    let user;

    try {
      user = await getUserByName(getAuthUserName());
    } catch (err) {
      notification.error(err.toString());
      console.log("user info:", authUser);
      localSaveAuthUser(user);
      return;
    }
    console.log("user info:", user);
    setAuthUser(user);
    localSaveAuthUser(user);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          routeStack,
          setRouteStack,
          authUser,
          namespace,
          setNamespace,
        }}
      >
        <div className="App">
          <Router>
            <AppRoutes />
          </Router>
        </div>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
