import React from "react";
import Box from "@mui/material/Box";
import LoadingProgress from "../LoadingProgress/LoadingProgress";

function TitledArea({ icon, title, subtitle, isLoading, ...props }) {
  return (
    <Box mb={2} {...props}>
      <span
        style={{
          marginBottom: 16,
          fontSize: 16,
          fontWeight: 500,
          marginRight: 8,
        }}
      >
        {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
        <span>{title}</span>
      </span>
      <span style={{ fontSize: 12, color: "#8C8C8C" }}>{subtitle}</span>
      <Box sx={{ mt: 2 }}>{props.children}</Box>
    </Box>
  );
}

export default TitledArea;
