import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

function CountdownDialog({open, title, description, handleConfirm, handleClose}) {
    const [countdown, setCountdown] = useState(5)

    useEffect(() => {
        if (open && countdown > 0) {
            setTimeout(function () {
                setCountdown(countdown - 1)
            }, 1000)
        } else if (open === false) {
            setCountdown(5)
        }
    }, [open, countdown])

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="text">取消</Button>
                <Button onClick={handleConfirm} autoFocus disabled={countdown > 0}>
                    确认 {countdown > 0 && `(${countdown})`}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CountdownDialog;