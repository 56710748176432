import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";

export const createTaskExecution = async (payload) => {
  const { data } = await post(`/proxy/@ops/api/v1/task-executions`, payload);
  return handleResponseEnvelope(data);
};

export const listTaskExecutions = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/task-executions`, listOptions);
  return handleResponseEnvelope(data);
};

export const getTaskExecutionByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/task-executions/${uid}`);
  return handleResponseEnvelope(data);
};

export const patchTaskExecutionByUid = async ({ uid, payload }) => {
  const { data } = await patch(
    `/proxy/@ops/api/v1/task-executions/${uid}`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const saveTaskExecutionByUid = async ({ uid, payload }) => {
  const { data } = await put(
    `/proxy/@ops/api/v1/task-executions/${uid}`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const deleteTaskExecutionByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@ops/api/v1/task-executions/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};

export const confirmTaskExecutionByUid = async ({ uid, payload }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/task-executions/${uid}/confirm`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const rerunTaskExecutionByUid = async ({
  uid,
  is_ignore_next = false,
  payload,
}) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/task-executions/${uid}/rerun?is_ignore_next=${is_ignore_next}`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const skipTaskExecutionByUid = async ({ uid, payload }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/task-executions/${uid}/skip`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const terminateTaskExecutionByUid = async ({ uid, payload }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/task-executions/${uid}/terminate`,
    payload
  );
  return handleResponseEnvelope(data);
};

// 获取 s3v2 fileLog
export const getS3FileLog = async ({ logRef = "" }) => {
  return await get(
    `/proxy/@s3v2-${process.env.REACT_APP_OPS_S3_BUCKET}/logs/${logRef}.log`
  );
};
