import { del, get, patch, post, put } from "../utils/request";

const create = ({ data }) => {
  return post(`/proxy/@ops/api/v1/datasources`, data);
};

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/datasources`, listOptions);
};

const getByUid = ({ uid }) => {
  return get(`/proxy/@ops/api/v1/datasources/${uid}`);
};

const getDataByUid = ({ uid }) => {
  return get(`/proxy/@ops/api/v1/datasources/${uid}/data`);
};

const debug = ({ data }) => {
  return post(`/proxy/@ops/api/v1/datasources/debug`, data);
};

const patchByUid = ({ uid, data }) => {
  return patch(`/proxy/@ops/api/v1/datasources/${uid}`, data);
};

const saveByUid = ({ uid, data }) => {
  return put(`/proxy/@ops/api/v1/datasources/${uid}`, data);
};

const deleteByUid = ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  return del(`/proxy/@ops/api/v1/datasources/${uid}?force=${force}`);
};

export const DatasourceService = {
  create,
  listAll,
  getByUid,
  getDataByUid,
  debug,
  patchByUid,
  saveByUid,
  deleteByUid,
};
