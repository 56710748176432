import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRouteStack } from "../state/modules/site";
import { NavRoutes } from "../routes/new";

function useRouteStack() {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(updateRouteStack(getRouteStackByLocationPath(location.pathname)));
  }, [location]);

  const getRouteStackByLocationPath = (locationPath = "") => {
    let routeStack = [];

    let paths = locationPath.split("/").filter((p) => p !== "");

    let matchPath = "";
    for (let i = 0; i < paths.length; i++) {
      matchPath += "/" + paths[i];
      let matchRoute = NavRoutes.find(
        (navRoute) => navRoute.path === matchPath
      );
      if (matchRoute) {
        routeStack = [
          ...routeStack,
          {
            path: matchRoute.path,
            title: matchRoute.title,
            description: matchRoute.description,
            isNavCollapse: matchRoute.isNavCollapse,
          },
        ];
      }
    }

    return routeStack;
  };
}

export default useRouteStack;
