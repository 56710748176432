import { del, get, patch, post, put } from "../utils/request";

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/task-executions`, listOptions);
};

const getByUid = ({ uid }) => {
  return get(`/proxy/@ops/api/v1/task-executions/${uid}`);
};

const patchByUid = ({ uid, data }) => {
  return patch(`/proxy/@ops/api/v1/task-executions/${uid}`, data);
};

const saveByUid = ({ uid, data }) => {
  return put(`/proxy/@ops/api/v1/task-executions/${uid}`, data);
};

const deleteByUid = ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  return del(`/proxy/@ops/api/v1/task-executions/${uid}?force=${force}`);
};

const confirmByUid = ({ uid, data }) => {
  return post(`/proxy/@ops/api/v1/task-executions/${uid}/confirm`, data);
};

const rerunByUid = ({ uid, is_ignore_next = false, data }) => {
  return post(
    `/proxy/@ops/api/v1/task-executions/${uid}/rerun?is_ignore_next=${is_ignore_next}`,
    data
  );
};

const skipByUid = ({ uid, data }) => {
  return post(`/proxy/@ops/api/v1/task-executions/${uid}/skip`, data);
};

const terminateByUid = ({ uid, data }) => {
  return post(`/proxy/@ops/api/v1/task-executions/${uid}/terminate`, data);
};

// 获取 s3v2 fileLog
const getS3FileLog = ({ logRef = "" }) => {
  return get(
    `/proxy/@s3v2-${process.env.REACT_APP_OPS_S3_BUCKET}/logs/${logRef}.log`
  );
};

export const TaskExecutionService = {
  listAll,
  getByUid,
  patchByUid,
  saveByUid,
  deleteByUid,
  confirmByUid,
  rerunByUid,
  skipByUid,
  terminateByUid,
  getS3FileLog,
};
