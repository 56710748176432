export const taskExecutionStatus = {
  Waiting: "Waiting",
  Scheduling: "Scheduling",
  Pending: "Pending",
  Skipped: "Skipped",
  Confirming: "Confirming",
  Running: "Running",
  Succeeded: "Succeeded",
  Failed: "Failed",
  Terminated: "Terminated",
};

export const taskExecutionEventMsgType = {
  Updated: "TASK_EXECUTION_UPDATED",
  Confirming: "TASK_EXECUTION_CONFIRMING",
};
