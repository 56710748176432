const THEME_COLOR_LIGHT = "#C5CAE9";
const THEME_COLOR_MAIN = "#3F51B5";
const THEME_COLOR_DARK = "#303F9F";
const THEME_COLOR_SURFACE = "#EAEFF1";
const THEME_COLOR_WHITE = "#fff";

export const GlobalConfig = {
  site: {
    title: "SysOps",
    description: "基础架构工作流自动化解决方案",
    docsUrl: "https://ops.nie.netease.com/docs/",
  },
  router: {
    entryRoute: "/workspace",
  },
  ops: {
    defaultNamespace: "cld",
  },
  style: {
    color: {
      light: THEME_COLOR_LIGHT,
      main: THEME_COLOR_MAIN,
      dark: THEME_COLOR_DARK,
    },
    layout: {
      background: THEME_COLOR_SURFACE,
      nav: {
        maxWidth: "240px", // 导航展开宽度
        background: THEME_COLOR_WHITE,
        borderRight: "1px solid rgba(0,0,0,0.2)",
      },
      header: {
        height: "70px", // 顶栏高度
        background: THEME_COLOR_DARK, // 顶栏背景色
        color: THEME_COLOR_WHITE,
        fontSize: "20px",
        fontWeight: "bold",
        boxShadow: "10px 5px 5px red !important",
        zIndex: 99999,
      },
    },
  },
};
