import React from "react";

import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { LoadingOutlined } from "@ant-design/icons";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";
import { taskStatus } from "../../../../../../constants/execution";
import { Chip } from "@mui/material";

export function FlowExecutionNode(props) {
  const { node } = props; // node 为 x6 自动传入的节点实例
  const nodeData = node?.getData(); // 重要: data 要通过 getData() 进行获取，否则数据变化无法重绘
  const { name = "未命名节点", status = "waiting", task } = nodeData;

  return (
    <div className={`node ${status}`} style={{ height: 60 }}>
      <span className="status">
        {status === taskStatus.Skipped && (
          <DoDisturbAltIcon sx={{ fontSize: 18 }} />
        )}
        {status === taskStatus.Waiting && (
          <AccessTimeIcon sx={{ fontSize: 18 }} />
        )}
        {status === taskStatus.Confirming && (
          <ErrorIcon sx={{ fontSize: 18 }} />
        )}
        {status === taskStatus.Pending && (
          <HourglassTopIcon sx={{ fontSize: 18 }} />
        )}
        {status === taskStatus.Running && (
          <LoadingOutlined style={{ fontSize: 18 }} />
        )}
        {status === taskStatus.Succeeded && (
          <CheckCircleIcon sx={{ fontSize: 18 }} />
        )}
        {status === taskStatus.Failed && <CancelIcon sx={{ fontSize: 18 }} />}
      </span>
      <span className="info">
        <div className="title">
          {nodeData.has_confirming_task_executions && (
            <Chip label="待确认" size="small" color="warning" sx={{ mr: 1 }} />
          )}
          <strong>{name}</strong>
        </div>
        <div className="description">{nodeData.description}</div>
      </span>
    </div>
  );
}
