import React, { useEffect, useState } from "react";
import TopActionsArea from "../../../components/TopBar/TopActionsArea";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import TitledCard from "../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../utils/datetime";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ContentArea from "../../../components/ContentArea/ContentArea";
import { useNotification } from "../../../hooks/common/useNotification";
import { useSearchParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  useDebugTrigger,
  useGetTriggerByUid,
  useSaveTriggerByUid,
} from "../../../hooks/query/trigger";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-tomorrow";
import {
  useGetWorkflowByUid,
  useListWorkflows,
} from "../../../hooks/query/workflow";
import { getAuthUserName } from "../../../utils/cookie";
import { listAuthUsers } from "../../../api/auth";
import { Autocomplete } from "@mui/lab";
import HelpPopover from "../../../components/Popover/HelpPopover";
import { useQueryClient } from "react-query";
import { getWorkflowByUid } from "../../../api/workflow";
import genId from "../../../utils/uuid";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { helperText } from "../../../constants/helper_text";
import EventTriggerLogs from "../../Event/Details/EventTriggerLogs";
import FieldEditDialogWithButton from "../../../components/Dialog/FieldPatchEdit/FieldEditDialogWithButton";
import { patchExecutionByUid } from "../../../api/execution";
import { patchTriggerByUid } from "../../../api/trigger";

function TriggerEdit(props) {
  const queryClient = useQueryClient();
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate: debugTrigger } = useDebugTrigger();
  const { mutate: saveTriggerByUid } = useSaveTriggerByUid();
  const [inputMapper, setInputMapper] = useState("");
  const [targetWorkflowUid, setTargetWorkflowUid] = useState("");
  const [targetWorkflowChangeFlag, setTargetWorkflowChangeFlag] = useState("");
  const [authUsers, setAuthUsers] = useState([]);
  const [maintainer, setMaintainer] = useState({ name: "", fullname: "" });

  const {
    data: trigger,
    isFetching: isFetchingTrigger,
    refetch: refetchTrigger,
  } = useGetTriggerByUid(searchParams.get("uid"));

  const { data: workflows, isFetching: isFetchingWorkflows } =
    useListWorkflows();

  const {
    data: workflow,
    isFetching: isFetchingWorkflow,
    refetch: refetchWorkflow,
  } = useGetWorkflowByUid(targetWorkflowUid, true, false);

  useEffect(() => {
    const fetchAuthUsers = async () => {
      let res = await listAuthUsers("cld");
      setAuthUsers(res.items);
    };
    fetchAuthUsers();
  }, []);

  const [editingTrigger, setEditingTrigger] = useState({
    metadata: {
      uid: "",
      name: "",
      description: "",
      is_enabled: false,
    },
    spec: {
      source: {
        type: "",
        options: {
          event: {
            ce_source: "",
            ce_type: "",
          },
        },
      },
      target: {
        type: "",
        options: {
          execution: {
            workflow_uid: "",
            input_mapper: {},
            name: "",
            description: "",
            maintainer: "",
            is_save_creating: true,
          },
        },
      },
    },
  });

  useEffect(() => {
    if (trigger.metadata.uid && trigger.metadata.uid !== "") {
      setEditingTrigger(cloneDeep(trigger));
      setMaintainer({ name: trigger.spec.target.options.execution.maintainer });
      setTargetWorkflowUid(
        trigger.spec?.target?.options?.execution?.workflow_uid
      );
      setInputMapper(
        JSON.stringify(
          trigger.spec?.target?.options?.execution?.input_mapper,
          "",
          "  "
        )
      );
    }
  }, [trigger]);

  useEffect(() => {
    if (targetWorkflowChangeFlag !== "") {
      getWorkflowByUid(targetWorkflowUid, false).then((selectedWf) => {
        setInputMapper(
          JSON.stringify(generateBaseInputMapper(selectedWf), "", "  ")
        );
      });
    }
  }, [targetWorkflowChangeFlag]);

  useEffect(() => {
    if (workflow.metadata?.uid && workflow.metadata?.uid !== "") {
    }
  }, [workflow]);

  const generateBaseInputMapper = (workflow) => {
    let newInputMapper = {
      global: {},
      tasks: {},
    };

    workflow.spec?.params?.forEach((gParam) => {
      if (gParam.is_required) {
        if (gParam.datasource_uid !== "") {
          // 已绑定数据源
          newInputMapper.global[
            gParam.name
          ] = `{{ 必填字段，已绑定数据源(缺省为第一个value)，描述: ${gParam.description}  }}`;
        } else {
          // 未绑定数据源
          if (gParam.default_value !== "") {
            newInputMapper.global[
              gParam.name
            ] = `{{ 必填字段，有默认值(缺省为默认值: ${gParam.default_value})，描述: ${gParam.description}  }}`;
          } else {
            newInputMapper.global[
              gParam.name
            ] = `{{ 必填字段，无默认值，必须映射，描述: ${gParam.description}  }}`;
          }
        }
      } else {
        newInputMapper.global[
          gParam.name
        ] = `{{ 选填字段，描述: ${gParam.description}  }}`;
      }
    });

    Object.keys(workflow.spec?.tasks)?.forEach((taskId) => {
      workflow.spec?.tasks[taskId]?.params?.forEach((tParam) => {
        if (newInputMapper.tasks[taskId] === undefined) {
          newInputMapper.tasks[taskId] = {};
        }
        if (tParam.is_required) {
          if (tParam.datasource_uid !== "") {
            // 已绑定数据源
            newInputMapper.tasks[taskId][
              tParam.name
            ] = `{{ 必填字段，已绑定数据源(缺省为第一个value)，描述: ${tParam.description} }}`;
          } else {
            // 未绑定数据源
            if (tParam.default_value !== "") {
              newInputMapper.tasks[taskId][
                tParam.name
              ] = `{{ 必填字段，有默认值(缺省为默认值: ${tParam.default_value})，描述: ${tParam.description}  }}`;
            } else {
              newInputMapper.tasks[taskId][
                tParam.name
              ] = `{{ 必填字段，无默认值，必须映射，描述: ${tParam.description}  }}`;
            }
          }
        } else {
          newInputMapper.tasks[taskId][
            tParam.name
          ] = `{{ 选填字段，描述: ${tParam.description}  }}`;
        }
      });
    });

    return newInputMapper;
  };

  const handleSaveTrigger = () => {
    let newTrigger = cloneDeep(editingTrigger);

    // execution options input-mapper edit save
    let newInputMapper;
    if (inputMapper !== "") {
      try {
        newInputMapper = JSON.parse(inputMapper);
      } catch (err) {
        notification.warning("无法解析 InputMapper", err.toString());
        return;
      }
      newTrigger.spec.target.options.execution.input_mapper = newInputMapper;
    }

    if (newTrigger.spec.target.options.execution.maintainer === "") {
      notification.warning("请确保指定实例维护人");
      return;
    } else {
      newTrigger.spec.target.options.execution.maintainer = maintainer.name;
    }

    newTrigger.spec.target.options.execution.workflow_uid = targetWorkflowUid;

    saveTriggerByUid(
      { uid: newTrigger.metadata.uid, payload: newTrigger },
      {
        onSuccess: () => {
          notification.success("触发器配置保存成功");
          refetchTrigger();
        },
      }
    );
  };

  return (
    <ContentArea>
      <TopActionsArea>
        <Button onClick={handleSaveTrigger}>保存</Button>
      </TopActionsArea>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitledCard
            isLoading={isFetchingTrigger}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="触发器基本信息"
            subtitle="触发器定义元数据信息"
          >
            <Descriptions>
              <DescriptionItem label={"名称"} span={6}>
                <Stack spacing={1} direction="row">
                  <span>{editingTrigger?.metadata?.name}</span>
                  <FieldEditDialogWithButton
                    initialValues={{ name: editingTrigger?.metadata?.name }}
                    onConfirm={(values) => {
                      if (values.name !== "") {
                        patchTriggerByUid({
                          uid: editingTrigger?.metadata?.uid,
                          payload: { metadata: { name: values.name } },
                        })
                          .then((res) => {
                            refetchTrigger();
                            notification.success("修改成功");
                          })
                          .catch((err) => {
                            notification.error("修改失败", err.toString());
                          });
                      }
                    }}
                  />
                </Stack>
              </DescriptionItem>
              <DescriptionItem label={"UID"} span={6}>
                {editingTrigger?.metadata.uid}
              </DescriptionItem>
              <DescriptionItem label={"命名空间"} span={6}>
                {editingTrigger?.metadata?.namespace}
              </DescriptionItem>
              <DescriptionItem label={"描述"} span={6}>
                {editingTrigger?.metadata?.description}
              </DescriptionItem>
              <DescriptionItem label={"创建人"} span={6}>
                {editingTrigger?.metadata?.created_by}
              </DescriptionItem>
              <DescriptionItem label={"创建时间"} span={6}>
                {formatDatetime(editingTrigger?.metadata?.created_at)}
              </DescriptionItem>
              <DescriptionItem label={"更新人"} span={6}>
                {editingTrigger?.metadata?.updated_by}
              </DescriptionItem>
              <DescriptionItem label={"更新时间"} span={6}>
                {formatDatetime(editingTrigger?.metadata?.updated_at)}
              </DescriptionItem>
              <DescriptionItem label={"是否启用"} span={6}>
                <ToggleButtonGroup
                  exclusive
                  color="primary"
                  size="small"
                  value={editingTrigger?.metadata?.is_enabled}
                  onChange={(e, val) => {
                    if (val !== null) {
                      let newTrigger = cloneDeep(editingTrigger);
                      newTrigger.metadata.is_enabled = val;
                      setEditingTrigger(newTrigger);
                    }
                  }}
                >
                  <ToggleButton value={true}>启用</ToggleButton>
                  <ToggleButton value={false}>停用</ToggleButton>
                </ToggleButtonGroup>
              </DescriptionItem>
            </Descriptions>
          </TitledCard>
        </Grid>

        <Grid item xs={3}>
          {/* 全局渲染变量 */}
          <TitledCard
            icon={<LibraryBooksIcon sx={{ fontSize: 16 }} />}
            title="全局渲染变量"
            subtitle="可以直接在模板中使用"
          >
            <Stack spacing={1}>
              <Chip label="事件源ID: _CE_ID" />
              <Chip label="事件源标识: _CE_SOURCE" />
              <Chip label="事件类型: _CE_TYPE" />
            </Stack>
          </TitledCard>
        </Grid>

        <Grid item xs={9}>
          <TitledCard
            isLoading={isFetchingTrigger}
            icon={<SettingsApplicationsIcon sx={{ fontSize: 16 }} />}
            title="触发器配置"
            subtitle="触发器配置"
          >
            <Stack spacing={2}>
              {/* 触发源 */}
              <Stack direction={"row"} spacing={2}>
                <Box sx={{ fontSize: 14, lineHeight: "40px" }}>触发源类型</Box>
                <ToggleButtonGroup
                  exclusive
                  color="primary"
                  size="small"
                  value={editingTrigger.spec.source.type}
                  onChange={(e, val) => {
                    if (val !== null) {
                      let newTrigger = cloneDeep(editingTrigger);
                      newTrigger.spec.source.type = val;
                      setEditingTrigger(newTrigger);
                    }
                  }}
                >
                  <ToggleButton value="OpsEvent" disabled>
                    OPS 事件
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              {/* 触发目标 */}
              <Stack direction={"row"} spacing={2}>
                <Box sx={{ fontSize: 14, lineHeight: "40px" }}>触发形式</Box>
                <ToggleButtonGroup
                  exclusive
                  color="primary"
                  size="small"
                  value={editingTrigger.spec.target.type}
                  onChange={(e, val) => {
                    if (val !== null) {
                      let newTrigger = cloneDeep(editingTrigger);
                      newTrigger.spec.target.type = val;
                      setEditingTrigger(newTrigger);
                    }
                  }}
                >
                  <ToggleButton value="Execution" disabled>
                    实例创建
                  </ToggleButton>
                  <ToggleButton value="Notification" disabled>
                    站内通知
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              {/* 触发实例创建形式 */}
              {editingTrigger.spec?.target.type === "Execution" && (
                <Stack direction={"row"} spacing={2}>
                  <Box sx={{ fontSize: 14, lineHeight: "40px" }}>
                    触发实例创建形式
                  </Box>
                  <ToggleButtonGroup
                    exclusive
                    color="primary"
                    size="small"
                    value={
                      editingTrigger.spec.target.options.execution
                        .is_save_creating
                    }
                    onChange={(e, val) => {
                      if (val !== null) {
                        let newTrigger = cloneDeep(editingTrigger);
                        newTrigger.spec.target.options.execution.is_save_creating =
                          val;
                        setEditingTrigger(newTrigger);
                      }
                    }}
                  >
                    <ToggleButton value={true}>仅创建，待手动启动</ToggleButton>
                    <ToggleButton value={false}>创建并自动启动</ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              )}

              {editingTrigger.spec?.source.type === "OpsEvent" && (
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="触发事件源"
                    value={editingTrigger.spec.source.options.event.ce_source}
                    onChange={(e) => {
                      let newTrigger = cloneDeep(editingTrigger);
                      newTrigger.spec.source.options.event.ce_source =
                        e.target.value;
                      setEditingTrigger(newTrigger);
                    }}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    label="触发事件类型"
                    value={editingTrigger.spec.source.options.event.ce_type}
                    onChange={(e) => {
                      let newTrigger = cloneDeep(editingTrigger);
                      newTrigger.spec.source.options.event.ce_type =
                        e.target.value;
                      setEditingTrigger(newTrigger);
                    }}
                  />
                </Stack>
              )}

              {editingTrigger.spec?.target.type === "Execution" && (
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="触发目标工作流"
                    value={targetWorkflowUid}
                    onChange={(e) => {
                      setTargetWorkflowUid(e.target.value);
                      setTargetWorkflowChangeFlag(genId);
                    }}
                  >
                    <MenuItem
                      key="none"
                      style={{ width: "100%", color: "rgba(0,0,0,0.5)" }}
                      value=""
                    >
                      [无]
                    </MenuItem>
                    {isFetchingWorkflows ? (
                      <MenuItem>
                        <CircularProgress />
                      </MenuItem>
                    ) : (
                      workflows.items?.map((wf) => (
                        <MenuItem
                          key={wf.metadata.uid}
                          style={{ width: "100%" }}
                          value={wf.metadata.uid}
                        >
                          {wf.metadata.name}{" "}
                          <span
                            style={{
                              marginLeft: 6,
                              fontSize: 12,
                              color: "#808080",
                            }}
                          >
                            ({wf.metadata.description})
                          </span>
                        </MenuItem>
                      ))
                    )}
                  </TextField>

                  {authUsers.length < 1 ? (
                    <MenuItem>
                      <CircularProgress />
                    </MenuItem>
                  ) : (
                    <Autocomplete
                      disablePortal
                      defaultValue={getAuthUserName()}
                      value={maintainer}
                      onChange={(event, selectedUser) => {
                        if (selectedUser.name !== "") {
                          setMaintainer(selectedUser);

                          let newTrigger = cloneDeep(editingTrigger);
                          newTrigger.spec.target.options.execution.maintainer =
                            selectedUser.name;
                          setEditingTrigger(newTrigger);
                        }
                      }}
                      options={authUsers.filter(
                        (u) => !u.fullname.includes("已离职")
                      )}
                      sx={{ width: 300 }}
                      getOptionLabel={(u) =>
                        `${u.name || ""} ${
                          u.fullname && u.fullname !== ""
                            ? "(" + u.fullname + ")"
                            : ""
                        }`
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="触发实例维护人" />
                      )}
                    />
                  )}

                  <h4>
                    实例创建参数映射{" "}
                    <HelpPopover text="基于 Go Template 语法，将事件 data 内容映射为实例创建各节点的参数值，例如 {{ .name }}，即获取的 event data 对象的 name 字段值" />
                  </h4>
                  <AceEditor
                    style={{ margin: "20px 0" }}
                    mode="yaml"
                    theme="idle_fingers"
                    width="100%"
                    height="195px"
                    minLines={10}
                    maxLines={40}
                    value={inputMapper}
                    onChange={(val) => setInputMapper(val)}
                    name="UNIQUE_ID_OF_DIV"
                    fontSize={13}
                    editorProps={{ $blockScrolling: true }}
                  />
                </Stack>
              )}
            </Stack>
          </TitledCard>
        </Grid>

        <Grid item xs={12}>
          <TitledCard
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="触发日志"
            subtitle="展示近一个月关联的触发日志"
          >
            <EventTriggerLogs
              selector={{
                "metadata.labels.type": "_TRIGGER",
                "metadata.labels.trigger-uid": searchParams.get("uid"),
              }}
            />
          </TitledCard>
        </Grid>
      </Grid>
    </ContentArea>
  );
}

export default TriggerEdit;
