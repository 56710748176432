import {
  datasourceConstraintType,
  datasourceFormatType,
  datasourceSourceType,
} from "../../../../constants/datasource";
import { Chip } from "@mui/material";

export const SourceTypeChip = (sourceType) => {
  switch (sourceType) {
    case datasourceSourceType.HttpGet:
      return <Chip size="small" color="primary" label="HTTP" />;
    case datasourceSourceType.Custom:
      return <Chip size="small" color="primary" label="自定义" />;
    default:
      return <Chip size="small" color="default" label="未知" />;
  }
};

export const FormatTypeChip = (formatType) => {
  switch (formatType) {
    case datasourceFormatType.List:
      return (
        <Chip size="small" variant="outlined" color="primary" label="列表" />
      );
    case datasourceFormatType.Map:
      return (
        <Chip size="small" variant="outlined" color="primary" label="映射" />
      );
    case datasourceFormatType.MapList:
      return (
        <Chip
          size="small"
          variant="outlined"
          color="primary"
          label="映射列表"
        />
      );
    case datasourceFormatType.Text:
      return (
        <Chip size="small" variant="outlined" color="primary" label="纯文本" />
      );
    default:
      return (
        <Chip size="small" variant="outlined" color="default" label="未知" />
      );
  }
};

export const ConstraintTypeChip = (constraintType) => {
  switch (constraintType) {
    case datasourceConstraintType.None:
      return (
        <Chip size="small" variant="outlined" color="secondary" label="无" />
      );
    case datasourceConstraintType.SelectOptionList:
      return (
        <Chip
          size="small"
          variant="outlined"
          color="secondary"
          label="选项列表"
        />
      );
    case datasourceConstraintType.TaskRangeList:
      return (
        <Chip
          size="small"
          variant="outlined"
          color="secondary"
          label="执行列表"
        />
      );
    default:
      return (
        <Chip size="small" variant="outlined" color="secondary" label="未知" />
      );
  }
};
