import React, { useEffect, useRef, useState } from "react";
import ContentArea from "../../../components/ContentArea/ContentArea";
import { useSearchParams } from "react-router-dom";
import TitledCard from "../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../utils/datetime";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ParamsDisplayTable from "../../../components/Params/Table/ParamsDisplayTable";
import ParamsEditDialog from "../../../components/Params/Dialog/ParamsEditDialog";
import { cloneDeep } from "lodash";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-dawn";
import TopActionsArea from "../../../components/TopBar/TopActionsArea";
import { useNotification } from "../../../hooks/common/useNotification";
import {
  useGetActionflowByUid,
  useSaveActionflowByUid,
} from "../../../hooks/query/actionflow";
import genId from "../../../utils/uuid";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-tomorrow";
import ActionflowActionAddDialog from "./Dialog/ActionflowActionAddDialog";
import ActionflowDebugDialog from "./Dialog/ActionflowDebugDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TitledArea from "../../../components/TitledArea/TitledArea";
import { Alert } from "@mui/lab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HandymanIcon from "@mui/icons-material/Handyman";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";

import { actionflowEditor } from "../../../constants/action";
import { helperText } from "../../../constants/helper_text";
import HelpPopover from "../../../components/Popover/HelpPopover";
import FieldEditDialogWithButton from "../../../components/Dialog/FieldPatchEdit/FieldEditDialogWithButton";
import { patchTriggerByUid } from "../../../api/trigger";
import { patchActionflowByUid } from "../../../api/actionflow";

function ActionflowEdit(props) {
  const notification = useNotification();
  const graph = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paramsEditDialogOpen, setParamsEditDialogOpen] = useState(false);
  const [actionAddDialogOpen, setActionAddDialogOpen] = useState(false);
  const [actionflowDebugDialogOpen, setActionflowDebugDialogOpen] =
    useState(false);
  const [graphData, setGraphData] = useState([]); // actionflow graph data

  const {
    data: actionflow,
    isFetching,
    refetch,
  } = useGetActionflowByUid(searchParams.get("uid"));
  const { mutate: saveActionflowByUid } = useSaveActionflowByUid();

  const [editingActionflow, setEditingActionflow] = useState({
    metadata: { name: "", description: "" },
    spec: { params: [], actions: [] },
  });

  useEffect(() => {
    if (actionflow.metadata.uid && actionflow.metadata.uid !== "") {
      setEditingActionflow(actionflow);
    }
  }, [actionflow]);

  const handleSaveActionflow = () => {
    let newAf = cloneDeep(editingActionflow);

    console.log("newAf:", newAf);

    saveActionflowByUid(
      { uid: newAf.metadata.uid, payload: newAf },
      {
        onSuccess: () => {
          notification.success("Actionflow 配置保存成功");
          refetch();
        },
      }
    );
  };

  return (
    <ContentArea>
      <TopActionsArea>
        <Button onClick={handleSaveActionflow}>保存</Button>
      </TopActionsArea>

      {/* Action 添加 Dialog */}
      <ActionflowActionAddDialog
        open={actionAddDialogOpen}
        setOpen={setActionAddDialogOpen}
        handleAddAction={(addedAction) => {
          let newAf = cloneDeep(editingActionflow);
          newAf.spec.actions.push({
            action_ref: addedAction.metadata.ref,
            json_args_tpl: addedAction.spec.executor.json_args_tpl,
            annotations: {},
          });
          setEditingActionflow(newAf);
          setActionAddDialogOpen(false);
        }}
      />

      {/* 调试 Dialog */}
      <ActionflowDebugDialog
        open={actionflowDebugDialogOpen}
        setOpen={setActionflowDebugDialogOpen}
        actionflow={editingActionflow}
      />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          {/* Actionflow 元数据 */}
          <TitledCard
            isLoading={isFetching}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="Actionflow 基本信息"
            subtitle="Actionflow 元数据"
          >
            <Descriptions>
              <DescriptionItem label={"名称"} span={12}>
                <Stack spacing={1} direction="row">
                  <span>{actionflow?.metadata?.name}</span>
                  <FieldEditDialogWithButton
                    initialValues={{ name: actionflow?.metadata?.name }}
                    onConfirm={(values) => {
                      if (values.name !== "") {
                        patchActionflowByUid({
                          uid: actionflow?.metadata?.uid,
                          payload: { metadata: { name: values.name } },
                        })
                          .then((res) => {
                            refetch();
                            notification.success("修改成功");
                          })
                          .catch((err) => {
                            notification.error("修改失败", err.toString());
                          });
                      }
                    }}
                  />
                </Stack>
              </DescriptionItem>
              <DescriptionItem label={"UID"} span={12}>
                {actionflow?.metadata.uid}
              </DescriptionItem>
              <DescriptionItem label={"描述"} span={12}>
                {actionflow?.metadata?.description}
              </DescriptionItem>
              <DescriptionItem label={"创建人"} span={12}>
                {actionflow?.metadata?.created_by}
              </DescriptionItem>
              <DescriptionItem label={"创建时间"} span={12}>
                {formatDatetime(actionflow?.metadata?.created_at)}
              </DescriptionItem>
              <DescriptionItem label={"更新人"} span={12}>
                {actionflow?.metadata?.updated_by}
              </DescriptionItem>
              <DescriptionItem label={"更新时间"} span={12}>
                {formatDatetime(actionflow?.metadata?.updated_at)}
              </DescriptionItem>
            </Descriptions>
          </TitledCard>

          {/* Actionflow 参数声明 */}
          <TitledCard
            isLoading={isFetching}
            icon={<LibraryBooksIcon sx={{ fontSize: 16 }} />}
            title="Actionflow 参数声明"
            subtitle="声明需要提供的参数"
          >
            <Stack spacing={2}>
              <Stack direction={"row"} spacing={1}>
                <Button onClick={() => setParamsEditDialogOpen(true)}>
                  参数编辑
                </Button>
              </Stack>
              <ParamsDisplayTable
                declareMode
                params={editingActionflow.spec.params || []}
              />

              {/* 参数编辑 */}
              <ParamsEditDialog
                declareMode
                open={paramsEditDialogOpen}
                params={editingActionflow.spec?.params}
                onClose={() => setParamsEditDialogOpen(false)}
                onConfirm={(newParams) => {
                  let newAf = cloneDeep(editingActionflow);
                  newAf.spec.params = newParams;
                  setEditingActionflow(newAf);
                  setParamsEditDialogOpen(false);
                }}
              />
            </Stack>
          </TitledCard>

          {/* 全局渲染变量 */}
          <TitledCard
            isLoading={isFetching}
            icon={<LibraryBooksIcon sx={{ fontSize: 16 }} />}
            title="全局渲染变量"
            subtitle="可以直接在模板中使用"
          >
            <Stack spacing={1}>
              <Divider>
                <Chip label="通用渲染变量" color="info" size="small" />
                <HelpPopover text={helperText.ActionflowRenderVarsCommon} />
              </Divider>
              <Chip label="互娱AccessToken(v2): _ACCESS_TOKEN_OPS" />
              <Chip label="互娱AccessUser(OPS系统用户): _ACCESS_USER_OPS" />
              <Chip label="上一步执行结果: _PREV" />
              <Chip label="指定Action的执行结果: _ACTIONS_<index>" />
              <Chip label="Unix时间戳: _TIMESTAMP" />
              <Chip label="随机UUID: _RANDOM_UUID" />
              <Divider>
                <Chip label="实例运行时渲染变量" color="warning" size="small" />
                <HelpPopover text={helperText.ActionflowRenderVarsRuntime} />
              </Divider>
              <Chip label="当前执行项标签: _CURRENT_ITEM" />
              <Chip label="实例UID: _EXECUTION_UID" />
              <Chip label="实例维护人: _EXECUTION_MAINTAINER" />
              <Chip label="执行项实例UID: _TASK_EXECUTION_UID" />
              <Chip label="任务节点ID: _TASK_ID" />
            </Stack>
          </TitledCard>
        </Grid>

        {/* 编排区 */}
        <Grid item xs={9}>
          <TitledArea
            title="Actions 编排"
            subtitle={
              <span>
                配置 Actionflow 各个 Action 的执行模板{" "}
                <HelpPopover text={helperText.ActionflowActionsEdit} />
              </span>
            }
          >
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => {
                  setActionAddDialogOpen(true);
                }}
              >
                添加 Action
              </Button>
              <Button
                size="small"
                startIcon={<HandymanIcon />}
                onClick={() => setActionflowDebugDialogOpen(true)}
              >
                调试 Actionflow
              </Button>
            </Stack>

            {/* 基于折叠框 Actions 编排 */}
            {editingActionflow.spec.actions?.map((act, index) => (
              <Accordion
                key={`${act.action_ref}_${genId()}`}
                sx={{ width: "100%" }}
                defaultExpanded={true}
                // expanded={expanded === te.metadata?.uid}
                // onChange={handleChange(te.metadata?.uid)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container spacing={2}>
                    <Grid item xs={7}>
                      <Chip
                        icon={<HdrAutoIcon />}
                        label={`_ACTIONS_${index}`}
                      />{" "}
                      {act.action_ref}
                    </Grid>
                    <Grid item xs={5}>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        sx={{ float: "right", paddingRight: "16px" }}
                      >
                        {index > 0 && (
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ArrowUpwardIcon />}
                            onClick={() => {
                              let newAf = cloneDeep(editingActionflow);
                              [
                                newAf.spec.actions[index],
                                newAf.spec.actions[index - 1],
                              ] = [
                                newAf.spec.actions[index - 1],
                                newAf.spec.actions[index],
                              ];
                              setEditingActionflow(newAf);
                            }}
                          >
                            上移
                          </Button>
                        )}
                        {index < editingActionflow.spec.actions.length - 1 && (
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<ArrowDownwardIcon />}
                            onClick={() => {
                              let newAf = cloneDeep(editingActionflow);
                              [
                                newAf.spec.actions[index],
                                newAf.spec.actions[index + 1],
                              ] = [
                                newAf.spec.actions[index + 1],
                                newAf.spec.actions[index],
                              ];
                              setEditingActionflow(newAf);
                            }}
                          >
                            下移
                          </Button>
                        )}
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          startIcon={<HighlightOffIcon />}
                          onClick={() => {
                            let newAf = cloneDeep(editingActionflow);
                            newAf.spec.actions.splice(index, 1);
                            setEditingActionflow(newAf);
                          }}
                        >
                          移除
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
                    参数输入模板{" "}
                  </Box>
                  <AceEditor
                    mode="json"
                    theme="idle_fingers"
                    width="100%"
                    minLines={10}
                    maxLines={30}
                    value={act.json_args_tpl}
                    onChange={(val) => {
                      editingActionflow.spec.actions[index].json_args_tpl = val;
                      setEditingActionflow(editingActionflow);
                    }}
                    name="http-getter-script"
                    fontSize={14}
                    editorProps={{ $blockScrolling: true }}
                  />
                </AccordionDetails>
              </Accordion>
            ))}

            {/* TODO 基于 Actionflow Editor 编辑器的编排 */}
            {/*<Box my={2}>*/}
            {/*  {graphData.length > 0 ? (*/}
            {/*    <ActionflowEditor*/}
            {/*      graph={graph}*/}
            {/*      style={{ margin: "16px 0" }}*/}
            {/*      graphData={graphData}*/}
            {/*    />*/}
            {/*  ) : (*/}
            {/*    <LoadingProgress />*/}
            {/*  )}*/}
            {/*</Box>*/}

            {editingActionflow.spec.actions?.length < 1 && (
              <Alert severity="warning">
                暂未添加 Action，请点击上方“添加 Action”按钮选择需要的 Action
                添加到编排区
              </Alert>
            )}
          </TitledArea>
        </Grid>
      </Grid>
    </ContentArea>
  );
}

export default ActionflowEdit;
