import React from "react";

import { Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

function ParamsDisplayTable({ params, isLoading, declareMode = false }) {
  const columns = [
    {
      field: "name",
      headerName: "名称",
      flex: 1,
      declareMode: true,
      valueGetter: (data) => data.row.name,
    },
    {
      field: "description",
      headerName: "描述",
      flex: 2,
      declareMode: true,
      valueGetter: (data) => data.row.description,
    },
    {
      field: "default_value",
      headerName: "默认值",
      flex: 1,
      declareMode: false,
      valueGetter: (data) => data.row.default_value,
    },
    {
      field: "is_required",
      headerName: "必填",
      maxWidth: 60,
      declareMode: false,
      renderCell: (data) => (data.row.is_required ? "是" : "否"),
    },
    {
      field: "datasource_uid",
      headerName: "数据源",
      flex: 1.4,
      declareMode: false,
      valueGetter: (data) => data.row.datasource_uid,
    },
  ];

  const filterColumns = (declareMode = false) => {
    if (declareMode === true) {
      return columns.filter((col) => col.declareMode === true);
    } else {
      return columns;
    }
  };

  return (
    <Stack spacing={2}>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        disableColumnSelector
        rows={params}
        columns={filterColumns(declareMode)}
        getRowId={(row) => row.name}
        pageSize={15}
        loading={isLoading}
      />
    </Stack>
  );
}

export default ParamsDisplayTable;
