import React, { useEffect, useState } from "react";
import { Button, Chip, Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useDeleteWorkflowByUid,
  useListWorkflows,
} from "../../../hooks/query/workflow";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { formatDatetime } from "../../../utils/datetime";
import CustomTable from "../../../components/Table/CustomTable";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import TuneIcon from "@mui/icons-material/Tune";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CountdownDialog from "../../../components/Dialog/Countdown";
import { useDeleteExecutionByUid } from "../../../hooks/query/execution";

function WorkflowTable(props) {
  let navigate = useNavigate();
  const { data: workflows, isFetching, refetch } = useListWorkflows();
  const { mutate: deleteWorkflowByUid } = useDeleteWorkflowByUid();
  const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
  const [deleteWorkflowUid, setDeleteWorkflowUid] = useState("");

  const handleClickDelete = () => {
    deleteWorkflowByUid(
      { uid: deleteWorkflowUid },
      { onSuccess: () => refetch() }
    );
    setDialogConfirmDeleteOpen(false);
    setDeleteWorkflowUid("");
  };

  const columns = [
    {
      field: "name",
      headerName: "名称/UID",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.metadata.name} [uid: ${params.row.metadata.uid}]`,
      renderCell: (data) => {
        return (
          <div>
            <div style={{ fontWeight: "bold" }}>
              <Link
                color={"inherit"}
                underline="none"
                onClick={() => {
                  navigate(`/workflows/edit?uid=${data.row.metadata.uid}`);
                }}
              >
                {data.row.metadata.name}
              </Link>
            </div>
            <div style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>
              {data.row.metadata.uid}
            </div>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "描述",
      minWidth: 200,
      valueGetter: (params) => params.row.metadata.description,
    },
    {
      field: "created_at",
      headerName: "创建时间",
      width: 170,
      valueGetter: (params) => formatDatetime(params.row.metadata.created_at),
    },
    {
      field: "tags",
      headerName: "标签",
      flex: 1,
      valueGetter: (params) => params.row.metadata.tags.toString(),
      renderCell: (data) => (
        <Stack direction="row" spacing={1}>
          {data.row.metadata?.tags?.map((tag) => (
            <Chip key={tag} label={tag} size="small" />
          ))}
        </Stack>
      ),
    },
    {
      field: "created_by",
      headerName: "创建人",
      width: 120,
      valueGetter: (params) => params.row.metadata.created_by,
    },
    {
      field: "actions",
      headerName: "操作",
      width: 240,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<AirplanemodeActiveIcon />}
              variant={"outlined"}
              onClick={() =>
                navigate(`/workflows/exec?uid=${data.row.metadata.uid}`)
              }
            >
              执行
            </Button>
            <Button
              startIcon={<TuneIcon />}
              variant={"outlined"}
              onClick={() =>
                navigate(`/workflows/edit?uid=${data.row.metadata.uid}`)
              }
            >
              编辑
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant={"outlined"}
              color={"error"}
              onClick={() => {
                setDeleteWorkflowUid(data.row.metadata.uid);
                setDialogConfirmDeleteOpen(true);
              }}
            >
              移除
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CountdownDialog
        open={dialogConfirmDeleteOpen}
        title="确认删除"
        description="是否确认删除工作流定义？请确认影响，谨慎操作"
        handleClose={() => setDialogConfirmDeleteOpen(false)}
        handleConfirm={handleClickDelete}
      />
      <CustomTable
        rows={workflows.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default WorkflowTable;
