export const executionStatus = {
  Initializing: "Initializing",
  Executing: "Executing",
  Finished: "Finished",
  Failed: "Failed",
  Terminated: "Terminated",
};

export const taskStatus = {
  Waiting: "Waiting",
  Confirming: "Confirming",
  Pending: "Pending",
  Skipped: "Skipped",
  Running: "Running",
  Succeeded: "Succeeded",
  Failed: "Failed",
};

export const taskNextStatusCode = {
  Unchecked: -1,
  Ok: 0,
  NotOk: 1,
};

export const executionEventMsgType = {
  Updated: "EXECUTION_UPDATED",
};
