import { get, patch, put } from "../utils/request";

const getByName = ({ name }) => {
  return get(`/proxy/@ops/api/v1/users/${name}`);
};

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/users`, listOptions);
};

const patchByName = ({ name, data }) => {
  return patch(`/proxy/@ops/api/v1/users/${name}`, data);
};

const saveByName = ({ name, data }) => {
  return put(`/proxy/@ops/api/v1/users/${name}`, data);
};

export const UserService = {
  getByName,
  listAll,
  patchByName,
  saveByName,
};
