import { del, get, patch, post, put } from "../utils/request";

const create = ({ data }) => {
  return post(`/proxy/@ops/api/v1/executions`, data);
};

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/executions`, listOptions);
};

const getByUid = ({ uid }) => {
  return get(`/proxy/@ops/api/v1/executions/${uid}`);
};

const createWorkOrderByUid = ({ uid, data }) => {
  return post(`/proxy/@ops/api/v1/executions/${uid}/work_order`, data);
};

const terminateByUid = ({ uid }) => {
  return post(`/proxy/@ops/api/v1/executions/${uid}/terminate`);
};

const patchByUid = ({ uid, data }) => {
  return patch(`/proxy/@ops/api/v1/executions/${uid}`, data);
};

const saveByUid = ({ uid, data }) => {
  return put(`/proxy/@ops/api/v1/executions/${uid}`, data);
};

const deleteByUid = ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  return del(`/proxy/@ops/api/v1/executions/${uid}?force=${force}`);
};

const confirmTask = ({ uid, taskId }) => {
  return post(
    `/proxy/@ops/api/v1/executions/${uid}/tasks/${taskId}/confirm`,
    {}
  );
};

const rerunTask = ({ uid, taskId }) => {
  return post(`/proxy/@ops/api/v1/executions/${uid}/tasks/${taskId}/rerun`, {});
};

export const ExecutionService = {
  create,
  listAll,
  getByUid,
  createWorkOrderByUid,
  terminateByUid,
  patchByUid,
  saveByUid,
  deleteByUid,
  confirmTask,
  rerunTask,
};
