import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useNotification } from "../../../hooks/common/useNotification";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useListDatasources } from "../../../hooks/query/datasource";
import { datasourceConstraintType } from "../../../constants/datasource";
import lodash from "lodash";

function ParamsEditDialog({
  open,
  params,
  onConfirm,
  onClose,
  declareMode = false,
}) {
  const [editingParams, setEditingParams] = useState([]);
  const notification = useNotification();
  const {
    data: datasourceOfSelectOptionList,
    isFetching: isSelectOptionListFetching,
    refetch: refetchSelectOptionList,
  } = useListDatasources({
    "spec.constraint_type": datasourceConstraintType.SelectOptionList,
  });

  useEffect(() => {
    if (open) {
      // 增加 index
      setEditingParams(
        params.map((param, index) => {
          param.index = index;
          return param;
        })
      );
    }
  }, [open]);

  const handleConfirm = () => {
    // 校验参数合法性
    for (let i = 0; i < editingParams.length; i++) {
      const param = editingParams[i];
      if (editingParams.filter((p) => param.name === p.name).length > 1) {
        notification.warning("参数名冲突", `存在多个参数名为 ${param.name}`);
        return;
      }
    }

    onConfirm(editingParams);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>参数编辑</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Stack direction={"row"} spacing={1}>
            <Button
              onClick={() => {
                const newParam = {
                  index: editingParams.length,
                  name: `[new_param_${editingParams.length + 1}]`,
                  description: "这是一个新参数",
                  default_value: "",
                  is_required: false,
                  datasource_uid: "",
                };
                setEditingParams([...editingParams, newParam]);
              }}
            >
              增加参数
            </Button>
          </Stack>
          {isSelectOptionListFetching ? (
            <LinearProgress />
          ) : (
            <Grid container spacing={2}>
              {editingParams.map((param, index) => (
                <Grid key={index} item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={declareMode ? 4 : 3}>
                      <TextField
                        fullWidth
                        label="名称"
                        size="small"
                        value={param.name}
                        onChange={(e) => {
                          let newParams = lodash.cloneDeep(editingParams);
                          newParams[index].name = e.target.value;
                          setEditingParams(newParams);
                        }}
                      />
                    </Grid>
                    <Grid item xs={declareMode ? 7 : 3}>
                      <TextField
                        fullWidth
                        label="描述"
                        size="small"
                        value={param.description}
                        onChange={(e) => {
                          let newParams = lodash.cloneDeep(editingParams);
                          newParams[index].description = e.target.value;
                          setEditingParams(newParams);
                        }}
                      />
                    </Grid>
                    {declareMode !== true && (
                      <>
                        <Grid item xs={1}>
                          <TextField
                            select
                            fullWidth
                            label="必填"
                            size="small"
                            value={param.is_required}
                            onChange={(e) => {
                              let newParams = lodash.cloneDeep(editingParams);
                              newParams[index].is_required = e.target.value;
                              setEditingParams(newParams);
                            }}
                          >
                            <MenuItem
                              key="true"
                              style={{ width: "100%" }}
                              value={true}
                            >
                              是
                            </MenuItem>
                            <MenuItem
                              key="false"
                              style={{ width: "100%" }}
                              value={false}
                            >
                              否
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            label={
                              param.datasource_uid !== ""
                                ? "(由数据源指定)"
                                : "默认值"
                            }
                            size="small"
                            disabled={param.datasource_uid !== ""}
                            value={param.default_value}
                            onChange={(e) => {
                              let newParams = lodash.cloneDeep(editingParams);
                              newParams[index].default_value = e.target.value;
                              setEditingParams(newParams);
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            select
                            size="small"
                            label="数据源"
                            name="rangeList"
                            defaultValue=""
                            value={param.datasource_uid}
                            onChange={(e) => {
                              let newParams = lodash.cloneDeep(editingParams);
                              newParams[index].datasource_uid = e.target.value;
                              newParams[index].default_value = "";
                              setEditingParams(newParams);
                            }}
                          >
                            <MenuItem
                              key="none"
                              style={{
                                width: "100%",
                                color: "rgba(0,0,0,0.5)",
                              }}
                              value=""
                            >
                              [移除节点执行列表]
                            </MenuItem>
                            {isSelectOptionListFetching ? (
                              <MenuItem>
                                <CircularProgress />
                              </MenuItem>
                            ) : (
                              datasourceOfSelectOptionList.items?.map(
                                (item) => (
                                  <MenuItem
                                    key={item.metadata.uid}
                                    style={{ width: "100%" }}
                                    value={item.metadata.uid}
                                  >
                                    {item.metadata.name}{" "}
                                    <span
                                      style={{
                                        marginLeft: 6,
                                        fontSize: 12,
                                        color: "#808080",
                                      }}
                                    >
                                      ({item.metadata.description})
                                    </span>
                                  </MenuItem>
                                )
                              )
                            )}
                          </TextField>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={1}>
                      <RemoveCircleIcon
                        onClick={() => {
                          let newParams = editingParams
                            .filter((ep) => ep.index !== index)
                            .map((param, index) => {
                              param.index = index;
                              return param;
                            });
                          setEditingParams(newParams);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          取消
        </Button>
        <Button onClick={handleConfirm} autoFocus>
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ParamsEditDialog;
