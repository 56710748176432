import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FlowEditor from "../../../../components/Flow/Editor";
import LoadingProgress from "../../../../components/LoadingProgress/LoadingProgress";
import TitledCard from "../../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../../utils/datetime";
import { workflowEditor } from "../../../../constants/workflow";

function TaskEdit({ graph, workflow, isFetching, ...props }) {
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (workflow.metadata.uid && workflow.metadata.uid !== "") {
      let newGraphData = [];
      Object.keys(workflow.spec?.tasks).forEach((taskId) => {
        const currentTask = workflow.spec?.tasks[taskId];
        // node
        let newNode = {
          id: taskId,
          shape: "editor-node",
          height: workflowEditor.nodeHeight,
          width: workflowEditor.nodeWidth,
          data: {
            ...currentTask,
            id: taskId,
            status: "wait",
          },
          ports: [
            {
              id: `${taskId}-L`,
              group: "left",
            },
            {
              id: `${taskId}-R`,
              group: "right",
            },
          ],
        };
        newGraphData = [...newGraphData, newNode];

        currentTask.next?.forEach((next) => {
          // edge
          let newEdge = {
            id: `${taskId}-${next.do}`,
            shape: "editor-edge",
            source: {
              cell: `${taskId}`,
              port: `${taskId}-R`,
            },
            target: {
              cell: `${next.do}`,
              port: `${taskId}-L`,
            },
            zIndex: 0,
          };

          newGraphData = [...newGraphData, newEdge];
        });
      });

      console.log("graphData:", newGraphData);

      setGraphData(newGraphData);
    }
  }, [workflow]);

  return (
    <div>
      <Box my={2}>
        {graphData.length > 0 ? (
          <FlowEditor
            graph={graph}
            style={{ margin: "16px 0" }}
            graphData={graphData}
          />
        ) : (
          <LoadingProgress />
        )}
      </Box>

      <TitledCard
        isLoading={isFetching}
        icon={<DiscountIcon sx={{ fontSize: 16 }} />}
        title="基本信息"
        subtitle="工作流定义元数据信息"
      >
        <Descriptions>
          <DescriptionItem label={"名称"} span={4}>
            {workflow?.metadata?.name}
          </DescriptionItem>
          <DescriptionItem label={"UID"} span={4}>
            {workflow?.metadata.uid}
          </DescriptionItem>
          <DescriptionItem label={"命名空间"} span={4}>
            {workflow?.metadata?.namespace}
          </DescriptionItem>
          <DescriptionItem label={"描述"} span={4}>
            {workflow?.metadata?.description}
          </DescriptionItem>
          <DescriptionItem label={"创建人"} span={4}>
            {workflow?.metadata?.created_by}
          </DescriptionItem>
          <DescriptionItem label={"创建时间"} span={4}>
            {formatDatetime(workflow?.metadata?.created_at)}
          </DescriptionItem>
          <DescriptionItem label={"更新人"} span={4}>
            {workflow?.metadata?.updated_by}
          </DescriptionItem>
          <DescriptionItem label={"更新时间"} span={4}>
            {formatDatetime(workflow?.metadata?.updated_at)}
          </DescriptionItem>
        </Descriptions>
      </TitledCard>
    </div>
  );
}

export default TaskEdit;
