import React from 'react';
import {Box} from "@mui/material";

function InnerBox(props) {
    return (
        <Box sx={{mt: 2}}>
            {props.children}
        </Box>
    );
}

export default InnerBox;