import React from "react";
import { Box, Card, CardContent, Divider } from "@mui/material";
import LoadingProgress from "../LoadingProgress/LoadingProgress";

function TitledCard({ icon, title, subtitle, isLoading, fullWidth, ...props }) {
  return (
    <Card sx={{ minWidth: fullWidth ? "100%" : 275, mb: 2 }} {...props}>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Box>
            {icon && <span style={{ marginRight: 8 }}>{icon}</span>}
            <span style={{ fontSize: 16, fontWeight: 500, marginRight: 8 }}>
              {title}
            </span>
            <span style={{ fontSize: 12, color: "#8C8C8C" }}>{subtitle}</span>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {isLoading ? <LoadingProgress /> : props.children}
      </CardContent>
    </Card>
  );
}

export default TitledCard;
