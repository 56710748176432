import React from "react";
import { Nav } from "@douyinfe/semi-ui";
import { GlobalConfig } from "../../globalConfig";
import { Button, Stack } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Box from "@mui/material/Box";
import { getAuthUserName } from "../../utils/cookie";
import Grid from "@mui/material/Grid";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import faviconWhite from "../../assets/favicon-white.png";

function LayoutHeader(props) {
  const navigate = useNavigate();

  return (
    <Nav
      mode={"horizontal"}
      onSelect={(key) => console.log(key)}
      style={{ ...GlobalConfig.style.layout.header }}
      header={
        <div onClick={() => navigate("/")}>
          <Stack spacing={2} direction="row" alignItems="center">
            <img src={faviconWhite} alt="" style={{ width: "30px" }} />
            <Box>
              <Stack direction="row" alignItems="center">
                <span
                  style={{
                    color: "rgba(255,255,255,0.7)",
                    fontWeight: "lighter",
                  }}
                >
                  SYS
                </span>
                <span>OPS</span>

                {process.env.REACT_APP_OPS_ENV !== "prod" && (
                  <span
                    style={{
                      fontSize: 8,
                      marginLeft: "8px",
                      border: "1px solid rgb(12,255,145)",
                      borderRadius: "4px",
                      padding: "2px 4px",
                      color: "rgb(12,255,145)",
                    }}
                  >
                    测试环境
                  </span>
                )}
              </Stack>
              <div style={{ fontSize: "12px", fontWeight: "normal" }}>
                {GlobalConfig.site.description}
              </div>
            </Box>
          </Stack>
        </div>
      }
      footer={
        <>
          <Stack spacing={2} direction="row">
            <Stack
              sx={{ fontSize: "14px" }}
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <AccountCircleIcon sx={{ fontSize: "18px" }} />
              <Box>{getAuthUserName() || "Guest"}</Box>
            </Stack>
            {getAuthUserName() !== undefined && getAuthUserName() !== "" ? (
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<LogoutIcon />}
                  onClick={() => {
                    window.location.href = "/logout";
                  }}
                >
                  登出
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<LoginIcon />}
                  onClick={() => {
                    window.location.href = "/login";
                  }}
                >
                  登入
                </Button>
              </Grid>
            )}
          </Stack>
        </>
      }
    />
  );
}

export default LayoutHeader;
