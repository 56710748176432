import axios from "axios";
import {getAuthV2Token} from "../utils/cookie";
import {authRequest, getCldAccessToken} from "./auth";


export const listNcrImages = async () => {
    const {
        data
    } = await axios.get(`/proxy/@ncr/api/v2.0/repositories?project_id=70243&page_size=0&q=actions.`, {
        headers: {
            "X-Access-Token": await getCldAccessToken(),
        }
    });
    return data
};

export const listNcrImageTags = async (repoName) => {

    const {data} = await axios.get(`/proxy/@ncr/api/v2.0/repositories/${repoName}/tags`, {
        headers: {
            "X-Access-Token": await getCldAccessToken(),
        }
    });
    return data
};

