import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { Button, Paper, Stack, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDeleteEventByUid, useListEvents } from "../../../hooks/query/event";
import CustomTable from "../../../components/Table/CustomTable";
import { formatDatetime } from "../../../utils/datetime";
import PreviewIcon from "@mui/icons-material/Preview";

function EventTable(props) {
  let navigate = useNavigate();

  const { data: events, isFetching, refetch } = useListEvents();

  const columns = [
    {
      field: "ce_source",
      headerName: "事件源",
      width: 300,
      valueGetter: (params) => params.row.metadata.ce_source,
    },
    {
      field: "ce_type",
      headerName: "源类型",
      width: 350,
      valueGetter: (params) => params.row.metadata.ce_type,
    },
    {
      field: "ce_id",
      headerName: "源标识ID",
      flex: 1,
      valueGetter: (params) => params.row.metadata.ce_id,
    },
    {
      field: "ce_time",
      headerName: "事件时间",
      width: 180,
      valueGetter: (params) => formatDatetime(params.row.metadata.ce_time),
    },
    {
      field: "actions",
      headerName: "操作",
      width: 100,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<PreviewIcon />}
              variant={"outlined"}
              onClick={() =>
                navigate(`/events/details?uid=${data.row.metadata.uid}`)
              }
            >
              详情
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CustomTable
        rows={events.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default EventTable;
