import { executionStatus } from "../constants/execution";
import { Chip } from "@mui/material";
import AdjustIcon from "@mui/icons-material/Adjust";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PendingIcon from "@mui/icons-material/Pending";
import React from "react";
import { StopFilled } from "@ant-design/icons";

// getExecutionStatusChip 生成 execution 状态标签
export const getExecutionStatusChip = (status) => {
  switch (status) {
    case executionStatus.Initializing:
      return (
        <Chip
          color="default"
          size="small"
          label="初始化中"
          icon={<PendingIcon />}
        />
      );
    case executionStatus.Executing:
      return (
        <Chip
          color="statusRunning"
          size="small"
          label="正在执行"
          icon={<AdjustIcon />}
        />
      );
    case executionStatus.Finished:
      return (
        <Chip
          color="statusSucceeded"
          size="small"
          label="执行完成"
          icon={<CheckCircleIcon />}
        />
      );
    case executionStatus.Failed:
      return (
        <Chip
          color="statusFailed"
          size="small"
          label="执行异常"
          icon={<CancelIcon />}
        />
      );
    case executionStatus.Terminated:
      return (
        <Chip
          color="statusTerminated"
          size="small"
          label="执行终止"
          icon={<StopFilled />}
        />
      );
    default:
      return <Chip color="default" size="small" label={status} />;
  }
};
