import { Chip } from "@mui/material";
import { actionCreateType } from "../../../../constants/action";

export const ActionCreateTypeChip = (type) => {
  switch (type) {
    case actionCreateType.Base:
      return <Chip size="small" color="warning" label="基础 Action" />;
    case actionCreateType.Derived:
      return <Chip size="small" color="primary" label="派生 Action" />;
    default:
      return <Chip size="small" color="default" label="未知" />;
  }
};
