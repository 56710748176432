import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotification } from "../../../../hooks/common/useNotification";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-kuroir";

function RangeItemDialog({
  open,
  title,
  onConfirm,
  onClose,
  editingRangeItem = { key: "", name: "", labels: {}, annotations: {} },
  ...props
}) {
  const notification = useNotification();
  const [rangeItem, setRangeItem] = useState({
    key: "",
    name: "",
    labels: {},
    annotations: {},
  });
  const [rangeItemLabelsStr, setRangeItemLabelsStr] = useState(
    JSON.stringify(
      { label_key: "label_value (string type required)" },
      "",
      "  "
    )
  );

  useEffect(() => {
    if (editingRangeItem.key !== "") {
      setRangeItem(editingRangeItem);
      setRangeItemLabelsStr(JSON.stringify(editingRangeItem.labels, "", "  "));
    }
  }, [editingRangeItem]);

  const handleConfirm = () => {
    // 校验
    if (rangeItem.key === "" || rangeItem.name === "") {
      notification.warning("执行项名称不可为空");
      return;
    }

    let rangeItemLabels = {};
    try {
      rangeItemLabels = JSON.parse(rangeItemLabelsStr);
    } catch (e) {
      notification.warning("无效的 labels 格式（JSON）");
      return;
    }

    rangeItem.labels = rangeItemLabels;

    onConfirm(rangeItem);
    handleClose();
  };

  const handleClose = () => {
    setRangeItem({ key: "", name: "", labels: {}, annotations: {} });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="1000">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={editingRangeItem.key !== ""}
              label="执行项名称（唯一）"
              size="small"
              value={rangeItem.key}
              onChange={(e) => {
                setRangeItem({
                  ...rangeItem,
                  key: e.target.value,
                  name: e.target.value,
                });
              }}
              sx={{ width: "600px", marginTop: "16px" }}
            />
          </Grid>

          <Grid item xs={12}>
            <strong style={{ marginTop: "16px" }}>
              {/*执行项标签（JSON格式，value为string，可以在 Action 中读取）*/}
              执行项标签（JSON格式，value为string）
            </strong>
            <AceEditor
              mode="json"
              theme="kuroir"
              width="100%"
              minLines={15}
              maxLines={30}
              value={rangeItemLabelsStr}
              onChange={(val) => {
                setRangeItemLabelsStr(val);
              }}
              name="http-getter-script"
              fontSize={14}
              editorProps={{ $blockScrolling: true }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="text">
          取消
        </Button>
        <Button onClick={handleConfirm} autoFocus>
          确认
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RangeItemDialog;
