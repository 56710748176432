import React, { useEffect, useRef, useState } from "react";
import {
  useGetWorkflowByUid,
  useSaveWorkflowByUid,
} from "../../../hooks/query/workflow";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AppBar from "@mui/material/AppBar";
import OuterBox from "../../../components/Box/OuterBox/OuterBox";
import { useNotification } from "../../../hooks/common/useNotification";
import lodash from "lodash";
import TaskEdit from "./TaskEdit/TaskEdit";
import InfoEdit from "./InfoEdit/InfoEdit";
import HelpPopover from "../../../components/Popover/HelpPopover";

function WorkflowEdit(props) {
  const notification = useNotification();
  const { mutate: saveWorkflowByUid } = useSaveWorkflowByUid();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: workflow,
    isFetching,
    refetch,
  } = useGetWorkflowByUid(searchParams.get("uid"), false, true);
  const [activeTab, setActiveTab] = useState("1");
  const graph = useRef(null);
  const formRef = useRef(null);
  const [editingWorkflow, setEditingWorkflow] = useState({
    metadata: {
      uid: "",
      tags: [],
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (workflow.metadata.uid && workflow.metadata.uid !== "") {
      setEditingWorkflow(workflow);
    }
  }, [workflow]);

  const handleClickSaveWorkflow = () => {
    // 检查 ref 冲突
    let duplicateRefNodes = new Set();
    let idCheckMap = {};
    // 前端校验合法性
    graph.current.getNodes().forEach((no) => {
      if (idCheckMap[no.data.id] === undefined) {
        idCheckMap[no.data.id] = true;
      } else if (idCheckMap[no.data.id]) {
        duplicateRefNodes.add(no.data.id);
      }
    });

    if (Array.from(duplicateRefNodes).length > 0) {
      notification.warning(
        "存在 Ref 冲突节点，请确保唯一性",
        JSON.stringify(Array.from(duplicateRefNodes))
      );
      return;
    }

    // 生成 workflow
    const nodes = graph.current.getNodes();
    let newWorkflow = lodash.cloneDeep(workflow);

    // 合并 editingWorkflow tags
    newWorkflow.metadata.tags = editingWorkflow.metadata.tags;
    // 合并 editingWorkflow subscribers
    newWorkflow.metadata.subscribers = editingWorkflow.metadata.subscribers;
    // newWorkflow = lodash.merge(newWorkflow, editingWorkflow);

    // 合并 form 修改
    if (formRef?.current?.values) {
      newWorkflow = lodash.merge(newWorkflow, formRef.current.values);
    }

    newWorkflow.spec.tasks = {}; // clear
    nodes.forEach((no) => {
      let taskId = no.data.id;
      newWorkflow.spec.tasks[taskId] = no.getData();
    });

    console.log("newWorkflow:", newWorkflow);

    saveWorkflowByUid(
      { uid: workflow.metadata.uid, payload: newWorkflow },
      {
        onSuccess: () => {
          notification.success(
            "工作流保存成功",
            `UID: ${workflow.metadata.uid}`
          );
          refetch();
        },
        onError: (err) => {
          notification.error("工作流保存失败", `原因: ${err.toString()}`);
        },
      }
    );
  };

  return (
    <TabContext value={activeTab}>
      <AppBar
        color="inherit"
        component="div"
        position="static"
        elevation={1}
        sx={{ zIndex: 0 }}
      >
        <TabList
          onChange={(e, tabValue) => {
            setActiveTab(tabValue);
          }}
          aria-label="lab API tabs example"
        >
          <Tab label="任务节点编排" value="1" />
          <Tab label="工作流配置" value="2" />
        </TabList>
      </AppBar>

      <OuterBox>
        <Stack direction={"row"} spacing={2}>
          <Button onClick={handleClickSaveWorkflow}>保存当前页面修改</Button>{" "}
        </Stack>

        <TabPanel value="1" sx={{ p: 0 }}>
          <TaskEdit graph={graph} workflow={workflow} isFetching={isFetching} />
        </TabPanel>

        <TabPanel value="2" sx={{ p: 0, mt: 2 }}>
          <InfoEdit
            formRef={formRef}
            workflow={workflow}
            isFetching={isFetching}
            editingWorkflow={editingWorkflow}
            setEditingWorkflow={setEditingWorkflow}
          />
        </TabPanel>
      </OuterBox>
    </TabContext>
  );
}

export default WorkflowEdit;
