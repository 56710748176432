import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetEventByUid, useReplayEvent } from "../../../hooks/query/event";
import {
  Box,
  Button,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import TitledCard from "../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../utils/datetime";
import ContentArea from "../../../components/ContentArea/ContentArea";
import TopActionsArea from "../../../components/TopBar/TopActionsArea";
import ButtonWithConfirmDialog from "../../../components/Button/ButtonWithConfirmDialog";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import { useNotification } from "../../../hooks/common/useNotification";
import EventTriggerLogs from "./EventTriggerLogs";

function EventDetails(props) {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: event,
    isFetching,
    refetch,
  } = useGetEventByUid(searchParams.get("uid"));

  const { mutate: replayEvent } = useReplayEvent();

  const handleConfirmReplay = (eventUid) => {
    console.log("重放", eventUid);

    replayEvent(
      { uid: eventUid, payload: {} },
      {
        onSuccess: (data) => {
          notification.success("事件重放成功", `UID: ${eventUid}`);
        },
        onError: (err) => {
          notification.error("事件重放失败", `error: ${err.toString()}`);
        },
      }
    );
  };

  return (
    <ContentArea>
      <TopActionsArea>
        <ButtonWithConfirmDialog
          ButtonProps={{
            startIcon: <ReplayCircleFilledIcon />,
          }}
          confirmTitle={"重放确认"}
          confirmText={
            <div>
              是否确认重放该事件，重放会使相关触发器再次执行触发动作，请根据实际需要将相关触发器调整至“启用/停用”状态
            </div>
          }
          onConfirm={() => handleConfirmReplay(searchParams.get("uid"))}
        >
          事件重放
        </ButtonWithConfirmDialog>
      </TopActionsArea>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitledCard
            isLoading={isFetching}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="事件元数据"
            subtitle="基于 CloudEvents 的 OPS Event 元数据"
          >
            <Descriptions>
              <DescriptionItem label={"UID"} span={12}>
                {event?.metadata.uid}
              </DescriptionItem>
              <DescriptionItem label={"事件源"} span={6}>
                {event?.metadata?.ce_source}
              </DescriptionItem>
              <DescriptionItem label={"源标识ID"} span={6}>
                {event?.metadata?.ce_id}
              </DescriptionItem>
              <DescriptionItem label={"事件类型"} span={6}>
                {event?.metadata?.ce_type}
              </DescriptionItem>
              <DescriptionItem label={"事件时间"} span={6}>
                {event?.metadata?.ce_time}
              </DescriptionItem>
              <DescriptionItem label={"触发回调方法"} span={6}>
                {event?.metadata?.callback?.method || "无"}
              </DescriptionItem>
              <DescriptionItem label={"触发回调地址"} span={6}>
                {event?.metadata?.callback?.url || "无"}
              </DescriptionItem>
            </Descriptions>
          </TitledCard>
        </Grid>

        <Grid item xs={12}>
          <TitledCard
            isLoading={isFetching}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="事件数据"
            subtitle="事件携带的业务数据"
          >
            <Box
              sx={{
                background: "#eef0f1",
                px: 2,
                py: 1,
                borderRadius: 1,
                border: "1px solid #ccc",
              }}
            >
              <pre style={{ margin: 0 }}>
                {JSON.stringify(event.data, "", "  ")}
              </pre>
            </Box>
          </TitledCard>
        </Grid>

        <Grid item xs={12}>
          <TitledCard
            isLoading={isFetching}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="触发日志"
            subtitle="展示近一个月关联的触发日志"
          >
            <EventTriggerLogs
              selector={{
                "metadata.labels.type": "_TRIGGER",
                "metadata.labels.event-uid": searchParams.get("uid"),
              }}
            />
          </TitledCard>
        </Grid>
      </Grid>
    </ContentArea>
  );
}

export default EventDetails;
