import React, { useState } from "react";
import ContentArea from "../../components/ContentArea/ContentArea";
import { Button, Grid } from "@mui/material";
import EventTable from "./Table";

function Event(props) {
  return (
    <ContentArea>
      <Grid container spacing={2}>
        <Grid sx={{ mb: 3 }} item xs={12}>
          <EventTable />
        </Grid>
      </Grid>
    </ContentArea>
  );
}

export default Event;
