import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseEnvelope } from "../../utils/request";
import { TaskExecutionService } from "../../services/task_execution";

export const listTaskExecutions = createAsyncThunk(
  "taskExecution/listAll",
  async ({ listOptions }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.listAll({ listOptions })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getTaskExecutionByUid = createAsyncThunk(
  "taskExecution/getByUid",
  async ({ uid }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.getByUid({ uid })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const patchTaskExecutionByUid = createAsyncThunk(
  "taskExecution/patchByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.patchByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const saveTaskExecutionByUid = createAsyncThunk(
  "taskExecution/saveByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.saveByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const deleteTaskExecutionByUid = createAsyncThunk(
  "taskExecution/deleteByUid",
  async ({ uid, force }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.deleteByUid({ uid, force })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const confirmTaskExecutionTask = createAsyncThunk(
  "taskExecution/confirmByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.confirmByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const rerunTaskExecutionTask = createAsyncThunk(
  "taskExecution/rerunByUid",
  async ({ uid, is_ignore_next = false, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.rerunByUid({ uid, is_ignore_next, data }))
          .data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const skipTaskExecutionTask = createAsyncThunk(
  "taskExecution/skipByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.skipByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const terminateTaskExecutionTask = createAsyncThunk(
  "taskExecution/terminateByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TaskExecutionService.terminateByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getTaskExecutionLog = createAsyncThunk(
  "taskExecution/getS3FileLog",
  async ({ logRef }, { rejectWithValue }) => {
    try {
      return (await TaskExecutionService.getS3FileLog({ logRef })).data;
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const taskExecutionSlice = createSlice({
  name: "taskExecution",
  initialState: {
    current: {
      metadata: {
        uid: "",
        name: "",
      },
    },
    list: { items: [], total: 0 },
    logContent: "",
    isCurrentLoading: false,
    isListLoading: false,
    error: "",
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.current = { name: "", uid: "" };
    },
    clearList: (state, action) => {
      state.list = { items: [], total: 0 };
    },
  },
  extraReducers: {
    [listTaskExecutions.pending]: (state, action) => {
      state.isListLoading = true;
    },
    [listTaskExecutions.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isCurrentLoading = false;
    },
    [listTaskExecutions.rejected]: (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    [getTaskExecutionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [getTaskExecutionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [getTaskExecutionByUid.fulfilled]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [patchTaskExecutionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [patchTaskExecutionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listTaskExecutions.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [saveTaskExecutionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [saveTaskExecutionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listTaskExecutions.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [deleteTaskExecutionByUid.fulfilled]: (state, action) => {},
    [deleteTaskExecutionByUid.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { clearCurrent, clearList } = taskExecutionSlice.actions;

export default taskExecutionSlice.reducer;
