import lodash from 'lodash'

export const deepOverride = (dst, src) => {
    // make a replication of destination data
    let dstCopy = lodash.cloneDeep(dst)

    // override
    lodash.mergeWith(dstCopy, src, (oldVal, newVal) => {
        // if is array, override directly
        if (Array.isArray(newVal)) {
            return newVal;
        }
    })

    // return the replica
    return dstCopy
}