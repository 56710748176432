import { useMutation, useQuery } from "react-query";
import {
  createAction,
  debugActionByUid,
  deleteActionByUid,
  getActionByUid,
  listActions,
  patchActionByUid,
  saveActionByUid,
} from "../../api/action";

export const useCreateAction = () => {
  return useMutation(createAction);
};

export const useListActions = (listOptions, auto = true) => {
  return useQuery(
    ["actions", JSON.stringify(listOptions)],
    () => listActions(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
      enabled: auto,
    }
  );
};

export const useGetActionByUid = (uid) => {
  return useQuery(["actions", uid], () => getActionByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
        ref: "",
      },
      spec: {
        readme: "",
        params: [],
        executor: {
          image: "",
          entrypoint: [],
          json_args_tpl: "",
        },
      },
    },
  });
};

export const usePatchActionByUid = () => {
  return useMutation(patchActionByUid);
};

export const useSaveActionByUid = () => {
  return useMutation(saveActionByUid);
};

export const useDeleteActionByUid = () => {
  return useMutation(deleteActionByUid);
};

export const useDebugActionByUid = () => {
  return useMutation(debugActionByUid);
};
