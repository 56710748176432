export const helperText = {
  AEIJsonInputArgs: "支持 Go 模板语法渲染，渲染结果作为参数传递至 AEI 镜像",
  AEIJsonInputSchema:
    "如果当前 Actionflow 为基础 Actionflow，请提供完善的 JSON Schema 文件用于 Actionflow 执行及派生 Actionflow 执行的 AEI 参数校验",
  AEIEntrypoint: "即 AEI 镜像的 Command 参数（in k8s）",
  AEICommonRenderVars: "无需声明，可以直接用于渲染使用",
  ActionflowActionsEdit: "获取上一步的结果请使用 ._PREV 渲染变量",
  ActionflowRenderVarsCommon: "具体渲染值在调试阶段也可以正常渲染",
  ActionflowRenderVarsRuntime:
    "具体渲染值会在实例运行时执行 Actionflow 时渲染，调试时仅能得到 Mock 值",
};
