import axios from "axios";
import { getAuthV2Token } from "./cookie";
import { localGetCurrentNamespace } from "../localstorage/namespace";

const instance = axios.create({
  timeout: 300000,
});

// 全局请求拦截
instance.interceptors.request.use(
  function (config) {
    let access_token = getAuthV2Token();
    if (access_token) {
      config.headers["X-Access-Token"] = `${access_token}`;
      config.headers["X-Auth-Namespace"] = localGetCurrentNamespace();
      return config;
    } else {
      console.log("Headers 未携带 X-Access-Token，跳转至认证页面");
      window.location.href = "/login";
      return false;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 全局响应拦截
instance.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (err) => {
    let errStatusCode = err.response.status;
    if (errStatusCode === 401 || errStatusCode === 403) {
      console.log(`认证鉴权失败[${errStatusCode}]: ${err.toString()}`);
      window.location.href = "/logout";
      return false;
    }
    // 其它放行
    console.log(err.response);
    return err.response;
  }
);

/**
 * 处理响应信封，成功返回业务数据，业务逻辑错误抛出错误
 * @param envelope
 */
export const handleResponseEnvelope = (envelope) => {
  if (envelope.code !== 0) {
    const errorInfo = `business error (${envelope.code}): ${envelope.msg}`;
    // console.log(`[debug] handleResponseEnvelope error msg: ${errorInfo}`)
    throw new Error(errorInfo);
  } else {
    // console.log(`[debug] handleResponseEnvelope data:`, envelope.data)
    return envelope.data;
  }
};

/**
 * 处理响应，成功返回业务数据，业务逻辑错误抛出错误
 */
export const handleResponse = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    const errorInfo = response.data.error;
    if (errorInfo) {
      throw new Error(
        `request failed: (${errorInfo.code}): ${errorInfo.message}`
      );
    } else {
      throw new Error(response.data);
    }
  }
};

export function get(url, params) {
  return instance.get(url, { params });
}

export function post(url, data) {
  return instance.post(url, data);
}

export function put(url, data) {
  return instance.put(url, data);
}

export function patch(url, data) {
  return instance.patch(url, data);
}

export function del(url) {
  return instance.delete(url);
}
