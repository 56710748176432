import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  Tab,
  TextField as MuiTextField,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import moment from "moment";
import ConfirmDialog from "../../../components/Dialog/Confirm";
import { useNotification } from "../../../hooks/common/useNotification";
import { useNavigate } from "react-router-dom";
import {
  useListNcrImages,
  useListNcrImageTags,
} from "../../../hooks/query/ncr";
import { useCreateAction } from "../../../hooks/query/action";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OuterBox from "../../../components/Box/OuterBox/OuterBox";
import TaskEdit from "../../Workflow/Edit/TaskEdit/TaskEdit";
import InfoEdit from "../../Workflow/Edit/InfoEdit/InfoEdit";

function ActionRegisterDialog({ open, setOpen, actions }) {
  const notification = useNotification();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const [actionRef, setActionRef] = useState("");
  const [actionModule, setActionModule] = useState("");
  const [imageName, setImageName] = useState("");

  const [activeTab, setActiveTab] = useState("1");

  const {
    data: images,
    isFetching: isImagesFetching,
    refetch: refetchImages,
  } = useListNcrImages();
  const {
    data: imageTags,
    isFetching: isImageTagsFetching,
    refetch: refetchImageTags,
  } = useListNcrImageTags(imageName);
  const { mutate: createAction } = useCreateAction();

  // fetch image list when createDialogOpen is true
  useEffect(() => {
    if (open) {
      refetchImages();
    } else {
      setActionRef("");
      setImageName("");
      setActionModule("");
    }
  }, [open]);

  // fetch image tags when imageName changed from form select
  useEffect(() => {
    if (imageName !== "") {
      refetchImageTags();
    }
  }, [imageName]);

  return (
    <TabContext value={activeTab}>
      <ConfirmDialog
        open={open}
        title="注册 Action"
        onClose={() => setOpen(false)}
        onConfirm={() => formRef.current.submitForm()}
      >
        <Box sx={{ width: 800 }}>
          <TabList
            onChange={(e, tabValue) => {
              setActiveTab(tabValue);
            }}
            aria-label="lab API tabs example"
          >
            <Tab label="基础 Action 派生创建" value="1" />
            <Tab label="由 AEI 创建基础 Action" value="2" />
          </TabList>

          <OuterBox>
            <Formik
              innerRef={formRef}
              validate={(values) => {
                if (values.spec.executor.repo !== "") {
                  const ref = values.spec.executor.repo.replaceAll(
                    "ops/actions.",
                    ""
                  );
                  const module = ref.split(".")[0];
                  setImageName(values.spec.executor.repo);
                  setActionRef(ref);
                  setActionModule(module);
                }
              }}
              initialValues={{
                metadata: {
                  name: "",
                  description: "",
                  derived: {
                    name: "",
                    base_action_ref: "",
                    base_action_uid: "",
                  },
                },
                spec: {
                  executor: {
                    repo: "",
                    tag: "",
                  },
                },
              }}
              onSubmit={(values, { setSubmitting }) => {
                // 根据 Tab Key 处理提交数据
                if (activeTab === "2") {
                  // 新创建，清除派生数据
                  values.metadata.derived.base_action_uid = "";
                  values.metadata.derived.name = "";
                }

                let newAction = {
                  metadata: {
                    ref: actionRef,
                    name: actionRef,
                    description: values.metadata.description,
                  },
                  spec: {
                    executor: {
                      image: `${process.env.REACT_APP_ACTIONS_REGISTRY}/${values.spec.executor.repo}:${values.spec.executor.tag}`,
                    },
                  },
                };

                let finalData = {
                  newAction: newAction,
                  derivedFrom: values.metadata.derived.base_action_uid,
                  derivedName: values.metadata.derived.name,
                };

                console.log("finalData:", finalData);

                createAction(finalData, {
                  onSuccess: (data) => {
                    setSubmitting(false);
                    notification.success(
                      "Actionflow 创建成功",
                      `UID: ${data.metadata.uid}`
                    );
                    navigate(`/actions/edit?uid=${data.metadata.uid}`);
                  },
                  onError: (err) => {
                    setSubmitting(false);
                    console.log("error data:", err);
                  },
                });
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <Grid container spacing={2} sx={{ py: 2 }}>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          select
                          size="small"
                          label="派生基础 Action"
                          component={TextField}
                          name="metadata.derived.base_action_uid"
                          validate={(val) => {
                            let error;
                            if (!val) {
                              error = `派生基础 Action 为必选项`;
                            }
                            return error;
                          }}
                        >
                          {actions.items
                            .filter(
                              (act) =>
                                act.metadata.derived?.base_action_ref === ""
                            )
                            .map((act) => (
                              <MenuItem
                                key={act.metadata.uid}
                                style={{ width: "100%" }}
                                value={act.metadata.uid}
                              >
                                {`${act.metadata.ref} (${act.metadata.description})`}
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>

                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          component={TextField}
                          size="small"
                          label="派生名称"
                          helperText="使用英文小写字符，通过下划线连接，示例: create_lbc_instance"
                          name="metadata.derived.name"
                          validate={(val) => {
                            let error;
                            if (!val) {
                              error = `派生名称为必选项，注意使用英文小写字符，通过下划线连接`;
                            }
                            return error;
                          }}
                        />
                      </Grid>

                      {isSubmitting && (
                        <Grid item xs={12}>
                          <LinearProgress />
                        </Grid>
                      )}
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    <Grid container spacing={2} sx={{ py: 2 }}>
                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          select
                          size="small"
                          label="Action Executor 镜像"
                          component={TextField}
                          name="spec.executor.repo"
                          validate={(val) => {
                            let error;
                            if (!val) {
                              error = `镜像仓库为必选项`;
                            }
                            return error;
                          }}
                        >
                          {isImagesFetching ? (
                            <MenuItem>
                              <CircularProgress />
                            </MenuItem>
                          ) : (
                            images.map((image) => (
                              <MenuItem
                                key={image.id}
                                style={{ width: "100%" }}
                                value={image.name}
                                disabled={
                                  actions.items.find(
                                    (action) =>
                                      action.metadata.ref ===
                                      image.name.replaceAll("ops/actions.", "")
                                  )?.metadata.uid !== undefined
                                }
                              >
                                {image.name}{" "}
                                {actions.items.find(
                                  (action) =>
                                    action.metadata.ref ===
                                    image.name.replaceAll("ops/actions.", "")
                                )?.metadata.uid !== undefined && "(已注册)"}
                              </MenuItem>
                            ))
                          )}
                        </Field>
                      </Grid>

                      {actionModule !== "" && (
                        <Grid item xs={4}>
                          <MuiTextField
                            fullWidth
                            disabled
                            size="small"
                            label="Module"
                            value={actionModule}
                          />
                        </Grid>
                      )}

                      {actionRef !== "" && (
                        <Grid item xs={8}>
                          <MuiTextField
                            fullWidth
                            disabled
                            size="small"
                            label="ActionRef"
                            value={actionRef}
                          />
                        </Grid>
                      )}

                      {imageName !== "" && (
                        <Grid item xs={12}>
                          <Field
                            fullWidth
                            select
                            size="small"
                            label="镜像 Tag"
                            component={TextField}
                            name="spec.executor.tag"
                            validate={(val) => {
                              let error;
                              if (!val) {
                                error = `镜像 Tag 为必选项`;
                              }
                              return error;
                            }}
                          >
                            {isImageTagsFetching ? (
                              <MenuItem>
                                <CircularProgress />
                              </MenuItem>
                            ) : (
                              imageTags?.map((imageTag) => (
                                <MenuItem
                                  key={imageTag.digest}
                                  style={{ width: "100%" }}
                                  value={imageTag.name}
                                >
                                  {imageTag.name}{" "}
                                  <span
                                    style={{
                                      marginLeft: 6,
                                      fontSize: 12,
                                      color: "#808080",
                                    }}
                                  >
                                    上传于{" "}
                                    {moment(imageTag.push_time).format(
                                      "YYYY-MM-DD HH:mm:ss"
                                    )}
                                  </span>
                                </MenuItem>
                              ))
                            )}
                          </Field>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Field
                          fullWidth
                          component={TextField}
                          label="描述"
                          name="metadata.description"
                          size="small"
                          validate={(val) => {
                            let error;
                            if (!val) {
                              error = `描述为必填项，简要描述该 Action 用途`;
                            }
                            return error;
                          }}
                        />
                      </Grid>

                      {isSubmitting && (
                        <Grid item xs={12}>
                          <LinearProgress />
                        </Grid>
                      )}
                    </Grid>
                  </TabPanel>
                </Form>
              )}
            </Formik>
          </OuterBox>
        </Box>
      </ConfirmDialog>
    </TabContext>
  );
}

export default ActionRegisterDialog;
