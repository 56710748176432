import { del, get, handleResponseEnvelope, post } from "../utils/request";

export const replayEvent = async ({ uid, payload }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/events/${uid}/replay`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const listEvents = async (listOptions) => {
  const { data } = await get(`/proxy/@ops/api/v1/events`, listOptions);
  return handleResponseEnvelope(data);
};

export const getEventByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/events/${uid}`);
  return handleResponseEnvelope(data);
};

export const deleteEventByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(`/proxy/@ops/api/v1/events/${uid}?force=${force}`);
  return handleResponseEnvelope(data);
};
