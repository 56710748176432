import React, { useEffect, useState } from "react";
import ContentArea from "../../../components/ContentArea/ContentArea";
import { useSearchParams } from "react-router-dom";
import {
  useGetActionByUid,
  useSaveActionByUid,
} from "../../../hooks/query/action";
import TitledCard from "../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../utils/datetime";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import moment from "moment";
import { useListNcrImageTags } from "../../../hooks/query/ncr";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ParamsDisplayTable from "../../../components/Params/Table/ParamsDisplayTable";
import ParamsEditDialog from "../../../components/Params/Dialog/ParamsEditDialog";
import MDEditor from "@uiw/react-md-editor";
import { cloneDeep } from "lodash";
import AceEditor from "react-ace";
import YAML from "yaml";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-dawn";
import TopActionsArea from "../../../components/TopBar/TopActionsArea";
import { useNotification } from "../../../hooks/common/useNotification";
import ActionDebugConsoleDialog from "./Dialog/ActionDebugConsoleDialog";
import HelpPopover from "../../../components/Popover/HelpPopover";
import { helperText } from "../../../constants/helper_text";

function ActionEdit(props) {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [image, setImage] = useState({
    imageRepo: "",
    imageTag: "",
  });
  const [editingEntrypoint, setEditingEntrypoint] = useState("");
  const [editingJsonArgsTpl, setEditingJsonArgsTpl] = useState("");
  const [editingJsonArgsSchema, setEditingJsonArgsSchema] = useState("");
  const [editingAction, setEditingAction] = useState({
    metadata: {
      uid: "",
      ref: "",
    },
    spec: {
      readme: "",
      params: [],
      executor: {
        image: "",
        entrypoint: [],
        json_args_tpl: [],
      },
    },
  });
  const {
    data: action,
    isFetching,
    refetch,
  } = useGetActionByUid(searchParams.get("uid"));
  const { mutate: saveActionByUid } = useSaveActionByUid();
  const [paramsEditDialogOpen, setParamsEditDialogOpen] = useState(false);
  const [actionDebugDialogOpen, setActionDebugDialogOpen] = useState(false);

  const getActionBaseRef = () => {
    if (
      action.metadata?.derived &&
      action.metadata?.derived?.base_action_ref &&
      action.metadata?.derived?.base_action_ref !== ""
    ) {
      return action.metadata?.derived?.base_action_ref;
    } else {
      return action.metadata.ref;
    }
  };

  const {
    data: imageTags,
    isFetching: isImageTagsFetching,
    refetch: refetchImageTags,
  } = useListNcrImageTags(`ops/actions.${getActionBaseRef()}`);

  useEffect(() => {
    if (action.metadata.uid && action.metadata.uid !== "") {
      refetchImageTags();
      let act = cloneDeep(action);
      setImage({
        imageRepo: action.spec.executor.image.split(":")[0],
        imageTag: action.spec.executor.image.split(":")[1],
      });

      setEditingEntrypoint(JSON.stringify(action.spec.executor.entrypoint));
      setEditingJsonArgsTpl(action.spec.executor.json_args_tpl);
      setEditingJsonArgsSchema(action.spec.executor.json_args_schema);

      setEditingAction(act);
    }
  }, [action]);

  const handleSaveAction = () => {
    let newAction = cloneDeep(editingAction);

    // image
    newAction.spec.executor.image = `${
      action.spec.executor.image.split(":")[0]
    }:${image.imageTag}`;

    let newEntrypoint;
    let newJsonArgsTpl;
    let newJsonArgsSchema;
    try {
      newEntrypoint = JSON.parse(editingEntrypoint);
    } catch (err) {
      notification.warning("Entrypoint JSON 解析失败", err.toString());
      return;
    }

    // 给定 schema 时，校验 schema 定义格式
    if (editingJsonArgsSchema !== "") {
      try {
        JSON.parse(editingJsonArgsSchema);
      } catch (err) {
        notification.warning(
          "AEI 执行参数 Schema JSON 解析失败",
          err.toString()
        );
        return;
      }
    }

    newJsonArgsTpl = editingJsonArgsTpl;
    newJsonArgsSchema = editingJsonArgsSchema;

    newAction.spec.executor.entrypoint = newEntrypoint;
    newAction.spec.executor.json_args_tpl = newJsonArgsTpl;
    newAction.spec.executor.json_args_schema = newJsonArgsSchema;

    console.log("newAction:", newAction);

    saveActionByUid(
      { uid: newAction.metadata.uid, payload: newAction },
      {
        onSuccess: () => {
          notification.success("Actionflow 配置保存成功");
          refetch();
        },
      }
    );
  };

  return (
    <ContentArea>
      <TopActionsArea>
        <Button onClick={handleSaveAction}>保存</Button>
      </TopActionsArea>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          {/* Actionflow 元数据 */}
          <TitledCard
            isLoading={isFetching}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="Action 基本信息"
            subtitle="Action 定义元数据信息"
          >
            <Descriptions>
              <DescriptionItem label={"Ref"} span={12}>
                {action?.metadata?.ref}{" "}
                <Chip
                  size="small"
                  color="info"
                  label={`Type: ${action?.spec?.type}`}
                />
              </DescriptionItem>
              <DescriptionItem label={"UID"} span={12}>
                {action?.metadata.uid}
              </DescriptionItem>
              <DescriptionItem label={"模块"} span={12}>
                {action?.metadata.module}
              </DescriptionItem>
              <DescriptionItem label={"命名空间"} span={12}>
                {action?.metadata?.namespace}
              </DescriptionItem>
              <DescriptionItem label={"描述"} span={12}>
                {action?.metadata?.description}
              </DescriptionItem>
              <DescriptionItem label={"创建人"} span={12}>
                {action?.metadata?.created_by}
              </DescriptionItem>
              <DescriptionItem label={"创建时间"} span={12}>
                {formatDatetime(action?.metadata?.created_at)}
              </DescriptionItem>
              <DescriptionItem label={"更新人"} span={12}>
                {action?.metadata?.updated_by}
              </DescriptionItem>
              <DescriptionItem label={"更新时间"} span={12}>
                {formatDatetime(action?.metadata?.updated_at)}
              </DescriptionItem>
            </Descriptions>
          </TitledCard>
        </Grid>

        <Grid item xs={7}>
          {/* Actionflow 参数声明 */}
          <TitledCard
            isLoading={isFetching}
            icon={<LibraryBooksIcon sx={{ fontSize: 16 }} />}
            title="Action 参数声明"
            subtitle="声明 Action Executor 需要用到的参数"
          >
            <Stack spacing={2}>
              <Stack direction={"row"} spacing={1}>
                <Button onClick={() => setParamsEditDialogOpen(true)}>
                  参数编辑
                </Button>
              </Stack>
              <ParamsDisplayTable
                declareMode
                params={editingAction.spec.params || []}
              />

              {/* 参数编辑 */}
              <ParamsEditDialog
                declareMode
                open={paramsEditDialogOpen}
                params={editingAction.spec.params}
                onClose={() => setParamsEditDialogOpen(false)}
                onConfirm={(newParams) => {
                  let newAction = cloneDeep(editingAction);
                  newAction.spec.params = newParams;
                  setEditingAction(newAction);
                  setParamsEditDialogOpen(false);
                }}
              />
            </Stack>
          </TitledCard>
        </Grid>

        {/* Actionflow AEI 配置 */}
        <Grid item xs={12}>
          {/* action executor config */}
          <TitledCard
            isLoading={isFetching}
            icon={<SettingsApplicationsIcon sx={{ fontSize: 16 }} />}
            title="Action Executor Image 配置"
            subtitle="Action 执行所依赖的各项配置"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="镜像仓库"
                  value={image.imageRepo}
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                {isImageTagsFetching ? (
                  <span style={{ fontSize: 14 }}>
                    <CircularProgress size={20} sx={{ mr: 1 }} />
                    载入 Image Tags
                  </span>
                ) : (
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="镜像 Tag"
                    disabled={action.metadata?.derived?.base_action_ref !== ""}
                    value={image.imageTag}
                    onChange={(e) => {
                      setImage({ ...image, imageTag: e.target.value });
                    }}
                  >
                    {imageTags?.map((imageTag) => (
                      <MenuItem
                        key={imageTag.digest}
                        style={{ width: "100%" }}
                        value={imageTag.name}
                      >
                        {imageTag.name}{" "}
                        <span
                          style={{
                            marginLeft: 6,
                            fontSize: 12,
                            color: "#808080",
                          }}
                        >
                          上传于{" "}
                          {moment(imageTag.push_time).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </span>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
                  Entrypoint 配置{" "}
                  <HelpPopover text={helperText.AEIEntrypoint} />
                </Box>
                <AceEditor
                  style={{ margin: "8px 0" }}
                  mode="yaml"
                  theme="idle_fingers"
                  width="100%"
                  height="195px"
                  value={editingEntrypoint}
                  maxLines={2}
                  minLines={2}
                  onChange={(val) => {
                    setEditingEntrypoint(val);
                  }}
                  name="UNIQUE_ID_OF_DIV"
                  fontSize={13}
                  editorProps={{ $blockScrolling: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
                  公共渲染变量{" "}
                  <HelpPopover text={helperText.AEICommonRenderVars} />
                </Box>
                <Box>
                  <Chip label="互娱AuthToken(v2): ACCESS_TOKEN_OPS" />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
                  AEI 输入参数模板{" "}
                  <HelpPopover text={helperText.AEIJsonInputArgs} />
                </Box>
                <AceEditor
                  style={{ margin: "8px 0" }}
                  mode="yaml"
                  theme="idle_fingers"
                  width="100%"
                  height="195px"
                  value={editingJsonArgsTpl}
                  maxLines={20}
                  minLines={20}
                  onChange={(val) => {
                    setEditingJsonArgsTpl(val);
                  }}
                  name="UNIQUE_ID_OF_DIV"
                  fontSize={13}
                  editorProps={{ $blockScrolling: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ fontSize: 14, fontWeight: "bold" }}>
                  AEI 输入参数 Schema{" "}
                  <HelpPopover text={helperText.AEIJsonInputSchema} />
                </Box>
                <AceEditor
                  style={{ margin: "8px 0" }}
                  mode="yaml"
                  theme="dawn"
                  width="100%"
                  height="195px"
                  value={editingJsonArgsSchema}
                  maxLines={20}
                  minLines={20}
                  readOnly={action.metadata?.derived?.base_action_ref !== ""}
                  onChange={(val) => {
                    setEditingJsonArgsSchema(val);
                  }}
                  name="UNIQUE_ID_OF_DIV"
                  fontSize={13}
                  editorProps={{ $blockScrolling: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2}>
                  <Button onClick={() => setActionDebugDialogOpen(true)}>
                    调试当前配置
                  </Button>

                  <ActionDebugConsoleDialog
                    action={action}
                    params={editingAction.spec.params}
                    image={image.imageRepo}
                    tag={image.imageTag}
                    entrypoint={editingEntrypoint}
                    jsonArgsTpl={editingJsonArgsTpl}
                    jsonArgsSchema={editingJsonArgsSchema}
                    open={actionDebugDialogOpen}
                    onClose={() => setActionDebugDialogOpen(false)}
                  />
                </Stack>
              </Grid>
            </Grid>
          </TitledCard>
        </Grid>
      </Grid>

      {/* action readme */}
      {/*<TitledCard*/}
      {/*  isLoading={isFetching}*/}
      {/*  icon={<DiscountIcon sx={{ fontSize: 16 }} />}*/}
      {/*  title="Actionflow README"*/}
      {/*  subtitle="Actionflow 功能说明 & 使用文档 & FAQ"*/}
      {/*>*/}
      {/*  <MDEditor*/}
      {/*    value={editingAction.spec.readme}*/}
      {/*    onChange={(val) => {*/}
      {/*      let newAction = cloneDeep(editingAction);*/}
      {/*      newAction.spec.readme = val;*/}
      {/*      setEditingAction(newAction);*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</TitledCard>*/}
    </ContentArea>
  );
}

export default ActionEdit;
