import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useNotification } from "../../../../hooks/common/useNotification";
import lodash from "lodash";
import { useDebugActionByUid } from "../../../../hooks/query/action";

function ActionDebugConsoleDialog({
  action,
  open,
  onClose,
  params,
  image,
  tag,
  entrypoint,
  jsonArgsTpl,
  jsonArgsSchema,
}) {
  const notification = useNotification();
  const [debugArgs, setDebugArgs] = useState({});
  const { mutate: debugActionByUid } = useDebugActionByUid();
  const [debugOutput, setDebugOutput] = useState("");
  const [debuging, setDebuging] = useState(false);

  useEffect(() => {
    let newDebugArgs = {};
    params?.forEach((param) => {
      newDebugArgs[param.name] = "";
    });
    setDebugArgs(newDebugArgs);
  }, [open]);

  const handleDebug = () => {
    let debugPayload = {};
    debugPayload.ref = action.metadata?.ref;
    debugPayload.image = `${image}:${tag}`;
    debugPayload.entrypoint = JSON.parse(entrypoint);
    debugPayload.json_args_tpl = jsonArgsTpl;
    debugPayload.json_args_schema = jsonArgsSchema;
    debugPayload.debug_args = debugArgs;

    console.log(debugPayload);

    setDebuging(true);
    debugActionByUid(
      { payload: debugPayload },
      {
        onSuccess: (data) => {
          setDebuging(false);
          setDebugOutput(data);
          notification.success("调试完成");
        },
        onError: (err) => {
          setDebuging(false);
          setDebugOutput(err.toString());
        },
      }
    );
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Action 调试台</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              label="调试 AEI 镜像"
              size="small"
              value={image}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              disabled
              fullWidth
              label="调试 AEI Tag"
              size="small"
              value={tag}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              label="调试 Entrypoint"
              size="small"
              value={entrypoint}
            />
          </Grid>
          <Grid item xs={12}>
            <b>调试 Input JSON Args 模板</b>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#eef0f1",
                px: 2,
                py: 1,
                borderRadius: 1,
                border: "1px solid #ccc",
                maxHeight: "600px",
                overflow: "auto",
              }}
            >
              <pre style={{ margin: 0 }}>{jsonArgsTpl}</pre>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <b>调试渲染参数</b>
          </Grid>
          {Object.keys(debugArgs).length > 0 &&
            params?.map((param) => (
              <Grid key={param.name} item xs={6}>
                <TextField
                  fullWidth
                  label={param.name}
                  size="small"
                  helperText={param.description}
                  value={debugArgs[param.name]}
                  defaultValue={""}
                  onChange={(e) => {
                    let newDebugArgs = lodash.cloneDeep(debugArgs);
                    newDebugArgs[param.name] = e.target.value;
                    setDebugArgs(newDebugArgs);
                  }}
                />
              </Grid>
            ))}
          <Grid item xs={12}>
            <b>调试结果</b>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#eef0f1",
                px: 2,
                py: 1,
                borderRadius: 1,
                border: "1px solid #ccc",
                maxHeight: "600px",
                overflow: "auto",
              }}
            >
              <pre style={{ margin: 0 }}>{debugOutput}</pre>
            </Box>
            {debuging && <LinearProgress />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          取消
        </Button>
        <Button onClick={handleDebug} autoFocus>
          执行调试
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionDebugConsoleDialog;
