import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseEnvelope } from "../../utils/request";
import { TriggerService } from "../../services/trigger";

export const listTriggers = createAsyncThunk(
  "trigger/listAll",
  async ({ listOptions }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TriggerService.listAll({ listOptions })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getTriggerByUid = createAsyncThunk(
  "trigger/getByUid",
  async ({ uid }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TriggerService.getByUid({ uid })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const patchTriggerByUid = createAsyncThunk(
  "trigger/patchByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TriggerService.patchByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const saveTriggerByUid = createAsyncThunk(
  "trigger/saveByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TriggerService.saveByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const deleteTriggerByUid = createAsyncThunk(
  "trigger/deleteByUid",
  async ({ uid, force }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await TriggerService.deleteByUid({ uid, force })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const triggerSlice = createSlice({
  name: "trigger",
  initialState: {
    current: {
      metadata: {
        uid: "",
        name: "",
      },
    },
    list: { items: [], total: 0 },
    isCurrentLoading: false,
    isListLoading: false,
    error: "",
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.current = { name: "", uid: "" };
    },
    clearList: (state, action) => {
      state.list = { items: [], total: 0 };
    },
  },
  extraReducers: {
    [listTriggers.pending]: (state, action) => {
      state.isListLoading = true;
    },
    [listTriggers.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isCurrentLoading = false;
    },
    [listTriggers.rejected]: (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    [getTriggerByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [getTriggerByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [getTriggerByUid.fulfilled]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [patchTriggerByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [patchTriggerByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listTriggers.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [saveTriggerByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [saveTriggerByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listTriggers.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [deleteTriggerByUid.fulfilled]: (state, action) => {},
    [deleteTriggerByUid.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { clearCurrent, clearList } = triggerSlice.actions;

export default triggerSlice.reducer;
