import { useQuery } from "react-query";
import { getSyslogByUid, listSyslogs } from "../../api/syslog";

export const useListSyslogs = (selector) => {
  return useQuery(
    ["syslogs", JSON.stringify(selector)],
    () => listSyslogs(selector),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
    }
  );
};

export const useGetSyslogByUid = (uid) => {
  return useQuery(["syslogs", uid], () => getSyslogByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
        labels: {},
      },
      level: "",
      content: "",
    },
  });
};
