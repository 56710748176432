import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DragSortTable from "../../../../components/Table/DragSortTable";

import { sortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import RangeItemDialog from "./RangeItemDialog";
import { useNotification } from "../../../../hooks/common/useNotification";
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));

function RangeListAccordion({ taskRangeList = [], onChange }) {
  const notification = useNotification();

  const [expanded, setExpanded] = useState(false);
  const [rangeItemAddDialogOpen, setRangeItemAddDialogOpen] = useState(false);
  const [rangeItemEditDialogOpen, setRangeItemEditDialogOpen] = useState(false);
  const [editingRangeItem, setEditingRangeItem] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <RangeItemDialog
        open={rangeItemAddDialogOpen}
        onConfirm={(addedItem) => {
          let isConflict = false;
          taskRangeList.forEach((item) => {
            if (item.key === addedItem.key) {
              isConflict = true;
            }
          });
          if (isConflict) {
            notification.warning(`${addedItem.key} 与现有执行项冲突`);
          } else {
            let newTaskRangeList = [...taskRangeList, addedItem];
            onChange(newTaskRangeList);
            notification.success(`${addedItem.key} 已添加到执行项列表`);
          }
        }}
        onClose={() => setRangeItemAddDialogOpen(false)}
        title={"添加新执行项"}
      />

      <RangeItemDialog
        open={rangeItemEditDialogOpen}
        onConfirm={(editedItem) => {
          let newTaskRangeList = taskRangeList.map((item) => {
            if (item.key === editedItem.key) {
              return editedItem;
            } else {
              return item;
            }
          });

          onChange(newTaskRangeList);
          notification.success(`${editedItem.key} 编辑成功`);
        }}
        onClose={() => setRangeItemEditDialogOpen(false)}
        editingRangeItem={editingRangeItem}
        title={"编辑执行项"}
      />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction={"row"} spacing={1}>
            {taskRangeList.map((rangeItem) => (
              <Chip
                color="warning"
                key={rangeItem.key}
                label={rangeItem.key}
                size="small"
              />
            ))}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={2}>
            <Button onClick={() => setRangeItemAddDialogOpen(true)}>
              新增执行项
            </Button>
          </Stack>
          <DragSortTable
            keyIndex="key"
            columns={[
              {
                key: "sort",
                title: "排序",
                dataIndex: "sort",
                width: 70,
                className: "drag-visible",
                render: () => <DragHandle />,
              },
              {
                key: "key",
                title: "执行项",
                width: 200,
                render: (rowData) => (
                  <Chip color="warning" size="small" label={rowData.key} />
                ),
              },
              {
                key: "labels",
                title: "标签",
                width: 400,
                dataIndex: "labels",
              },
              {
                key: "labels",
                title: "编辑",
                width: 140,
                render: (rowData) => (
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={() => {
                        setEditingRangeItem(rowData);
                        setRangeItemEditDialogOpen(true);
                      }}
                    >
                      编辑
                    </Button>
                    <Button
                      color="error"
                      onClick={() => {
                        if (taskRangeList.length <= 1) {
                          notification.warning("必须保留至少一个执行项");
                          return;
                        }
                        onChange(
                          taskRangeList.filter(
                            (item) => item.key !== rowData.key
                          )
                        );
                        notification.success(`已移除执行项 ${rowData.key}`);
                      }}
                    >
                      移除
                    </Button>
                  </Stack>
                ),
              },
            ]}
            dataSource={taskRangeList}
            onSort={(sortedList) => {
              console.log("sorted:", sortedList);
              onChange(sortedList);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default RangeListAccordion;
