import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Chip, Drawer, Stack, Tab } from "@mui/material";
import {
  DescriptionItem,
  Descriptions,
} from "../../../../../../components/Descriptions/Descriptions";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AppBar from "@mui/material/AppBar";
import TitledCard from "../../../../../../components/TitledCard/TitledCard";
import { useListTaskExecutions } from "../../../../../../hooks/query/task_execution";
import TaskExecutionAccordion from "./Accordion/TaskExecutionAccordion";
import TitledArea from "../../../../../../components/TitledArea/TitledArea";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useConfirmTaskByExecutionUidAndTaskId } from "../../../../../../hooks/query/execution";
import { useNotification } from "../../../../../../hooks/common/useNotification";
import { getAuthUserName } from "../../../../../../utils/cookie";
import {
  taskNextStatusCode,
  taskStatus,
} from "../../../../../../constants/execution";
import { taskExecutionEventMsgType } from "../../../../../../constants/task_execution";
import FieldEditDialogWithButton from "../../../../../../components/Dialog/FieldPatchEdit/FieldEditDialogWithButton";
import { patchExecutionByUid } from "../../../../../../api/execution";

function FlowExecutionDrawer({
  visible,
  setVisible,
  executionUid,
  taskId,
  taskState,
}) {
  const ws = useRef(null);

  const notification = useNotification();
  const [activeTab, setActiveTab] = useState("1");
  const [args, setArgs] = useState([]);
  const [taskExecutionListOptions, setTaskExecutionListOptions] = useState({
    "metadata.owner_references.execution_uid": "",
    "metadata.owner_references.task_id": "",
  });

  // data init
  const {
    data: taskExecutions,
    isFetching: isTaskExecutionsFetching,
    refetch: refetchTaskExecutions,
  } = useListTaskExecutions(taskExecutionListOptions);

  const { mutate: confirmTaskByExecutionUidAndTaskId } =
    useConfirmTaskByExecutionUidAndTaskId();

  useEffect(() => {
    console.log("taskId:", taskId, "taskState", taskState);
  }, [taskId, taskState]);

  useEffect(() => {
    if (visible) {
      setActiveTab("1");

      setTaskExecutionListOptions({
        "metadata.owner_references.execution_uid": executionUid,
        "metadata.owner_references.task_id": taskId,
      });

      // Drawer 打开时，建立 ws 连接，实时更新 task executions 状态
      // websocket 注意，要用 ingress 地址，客户端从外部请求需要访问 ingress 地址，nginx 配置 proxy_pass 比较麻烦
      ws.current = new WebSocket(
        `wss://${process.env.REACT_APP_OPS_SERVER}/api/v1/stream/executions/${executionUid}/events`
      );
      //连接错误时触发
      ws.current.onerror = function (err) {
        console.log("ws: task-executions events watch ERROR:", err);
      };
      //连接打开时触发
      ws.current.onopen = function (evt) {
        console.log("ws: task-executions events watch started~");
        ws.current.send(
          `task-executions events watch started~, user: ${getAuthUserName()}`
        );
      };
      //接收到消息时触发
      ws.current.onmessage = function (evt) {
        const eventMsg = JSON.parse(evt.data);

        switch (eventMsg.type) {
          case taskExecutionEventMsgType.Updated:
          case taskExecutionEventMsgType.Confirming:
            // 只关注当前 task 事件
            if (eventMsg.data.task_id && eventMsg.data.task_id === taskId) {
              refetchTaskExecutions();
            }
            break;
        }
      };
      //连接关闭时触发
      ws.current.onclose = function (evt) {
        console.log("ws: task-executions events watch stopped~");
      };

      return () => {
        ws.current?.close(
          1000,
          `task-executions events watch stopped~, user: ${getAuthUserName()}`
        );
      };
    }

    return () => {
      console.log("Drawer close, stop task-executions polling");
      ws.current?.close(
        1000,
        `task-execution events watch stopped~, user: ${getAuthUserName()}`
      );
    };
  }, [visible]);

  useEffect(() => {
    return () => {
      console.log("Page close, stop task-executions polling");
      ws.current?.close(
        1000,
        `task-execution events watch stopped~, user: ${getAuthUserName()}`
      );
    };
  }, []);

  useEffect(() => {
    if (
      taskExecutionListOptions["metadata.owner_references.execution_uid"] !==
        "" &&
      taskExecutionListOptions["metadata.owner_references.task_id"] !== ""
    ) {
      refetchTaskExecutions();
    }
  }, [taskExecutionListOptions]);

  // 初始化数据
  useEffect(() => {
    if (taskState.args) {
      setArgs(taskState.args);
    }
  }, [taskState]);

  const handleConfirmTaskExecution = () => {
    confirmTaskByExecutionUidAndTaskId(
      { uid: executionUid, taskId: taskId },
      {
        onSuccess: () => {
          notification.success(
            "执行已确认",
            `uid: ${executionUid} taskId: ${taskId}`
          );
        },
      }
    );
  };

  const getNextStatusChip = (statusCode = taskNextStatusCode.Unchecked) => {
    switch (statusCode) {
      case taskNextStatusCode.Unchecked:
        return <Chip label="待判定" />;
      case taskNextStatusCode.Ok:
        return <Chip label="通过" color="success" />;
      case taskNextStatusCode.NotOk:
        return <Chip label="不通过" color="warning" />;
    }
  };

  return (
    <TabContext value={activeTab}>
      <Drawer
        sx={{ width: 800 }}
        anchor="right"
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        PaperProps={{ style: { background: "#eaeff1" } }}
      >
        <div style={{ width: 700 }}>
          <AppBar
            color="inherit"
            component="div"
            position="static"
            elevation={1}
            sx={{ zIndex: 0 }}
          >
            <TabList
              onChange={(e, tabValue) => {
                setActiveTab(tabValue);
              }}
              aria-label="lab API tabs example"
            >
              <Tab label="执行详情" value="1" />
              <Tab label="参数信息" value="2" />
              <Tab label="任务转移" value="3" />
            </TabList>
          </AppBar>

          <TabPanel value="1" sx={{ p: 0 }}>
            <Stack sx={{ m: 2 }} spacing={2}>
              {taskState.status === taskStatus.Confirming && (
                <Button
                  startIcon={<HowToRegIcon />}
                  onClick={() => handleConfirmTaskExecution()}
                >
                  确认执行 {taskState.name}
                </Button>
              )}

              {/* 基本信息 */}
              <TitledCard title="执行信息" subtitle="任务节点执行基本信息">
                <Descriptions>
                  <DescriptionItem key="name" span={12} label="任务名称">
                    {taskState.name}
                  </DescriptionItem>
                  <DescriptionItem key="taskId" span={12} label="任务ID">
                    {taskId}
                  </DescriptionItem>
                  <DescriptionItem key="description" span={12} label="任务描述">
                    {taskState.description}
                  </DescriptionItem>
                  <DescriptionItem
                    key="actionflow_uid"
                    span={12}
                    label="Actionflow"
                  >
                    {taskState.actionflow_uid}
                    <Button
                      variant={"text"}
                      onClick={() =>
                        window.open(
                          `/actionflows/edit?uid=${taskState.actionflow_uid}`,
                          "_blank"
                        )
                      }
                    >
                      调试
                    </Button>
                  </DescriptionItem>
                  <DescriptionItem key="status" span={12} label="状态">
                    {taskState.status}
                  </DescriptionItem>
                </Descriptions>
              </TitledCard>
              {/* 执行列表 */}
              <TitledArea
                title="执行列表"
                subtitle="任务节点各执行对象执行结果"
              >
                <TaskExecutionAccordion
                  drawerVisible={visible}
                  taskExecutions={
                    isTaskExecutionsFetching
                      ? { items: [], total: 0 }
                      : taskExecutions
                  }
                  isTaskExecutionsFetching={isTaskExecutionsFetching}
                  setDrawerVisible={setVisible}
                />
              </TitledArea>
            </Stack>
          </TabPanel>

          <TabPanel value="2" sx={{ p: 0 }}>
            <Stack sx={{ m: 2 }}>
              {/* 节点参数 */}
              <TitledCard title="节点参数" subtitle="任务节点输入参数">
                {/*节点参数修改*/}
                <FieldEditDialogWithButton
                  initialValues={args}
                  onConfirm={(newArgs) => {
                    patchExecutionByUid({
                      uid: executionUid,
                      payload: {
                        state: {
                          tasks: {
                            [taskId]: {
                              args: newArgs,
                            },
                          },
                        },
                      },
                    })
                      .then((res) => {
                        notification.success("修改成功");
                        setArgs(newArgs);
                      })
                      .catch((err) => {
                        notification.error("修改失败", err.toString());
                      });
                  }}
                />

                <Stack spacing={1}>
                  {Object.keys(args).map((argKey) => (
                    <div key={argKey}>
                      <span style={{ marginRight: "8px", fontWeight: "bold" }}>
                        {argKey}:
                      </span>
                      <Chip
                        key={argKey}
                        color="default"
                        label={`${args[argKey]}`}
                      />
                    </div>
                  ))}
                </Stack>
              </TitledCard>
            </Stack>
          </TabPanel>

          <TabPanel value="3" sx={{ p: 0 }}>
            <Stack sx={{ m: 2 }}>
              {/* 任务转移 */}
              {taskState.next?.map((next) => (
                <TitledCard
                  key={next.do}
                  title={`任务转移目标节点：${next.do}`}
                >
                  <Stack spacing={2}>
                    <Box>任务转移判定表达式：{next.if || "直接触发"}</Box>
                    <Box>判定结果：{getNextStatusChip(next.status?.code)}</Box>
                    {next.status?.msg !== "" && (
                      <Box>判定详情：{next.status.msg}</Box>
                    )}
                  </Stack>
                </TitledCard>
              ))}
            </Stack>
          </TabPanel>
        </div>
      </Drawer>
    </TabContext>
  );
}

export default FlowExecutionDrawer;
