import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./state/store";
import "./index.css";
import App from "./App";
import { Slide } from "@mui/material";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={6}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      TransitionComponent={Slide}
      transitionDuration={{ enter: 100, exit: 200 }}
    >
      <App />
    </SnackbarProvider>
  </Provider>
);
