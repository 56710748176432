import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";
import { localGetCurrentNamespace } from "../localstorage/namespace";

export const createWorkflow = async (payload) => {
  const { data } = await post(`/proxy/@ops/api/v1/workflows`, payload);
  return handleResponseEnvelope(data);
};

export const listWorkflows = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/workflows`, listOptions);
  return handleResponseEnvelope(data);
};

export const getWorkflowByUid = async (uid, loadDatasource = false) => {
  const { data } = await get(
    `/proxy/@ops/api/v1/workflows/${uid}?load_datasource=${loadDatasource}`
  );
  return handleResponseEnvelope(data);
};

export const patchWorkflowByUid = async ({ uid, payload }) => {
  const { data } = await patch(`/proxy/@ops/api/v1/workflows/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const saveWorkflowByUid = async ({ uid, payload }) => {
  const { data } = await put(`/proxy/@ops/api/v1/workflows/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const deleteWorkflowByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@ops/api/v1/workflows/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};
