import React, { useEffect, useState } from "react";
import ConfirmDialog from "../../../../components/Dialog/Confirm";
import { Box, Grid, Stack, TextField } from "@mui/material";
import { useListActions } from "../../../../hooks/query/action";
import { Autocomplete } from "@mui/lab";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-kuroir";
import moment from "moment";

function ActionflowActionAddDialog({ open, setOpen, handleAddAction }) {
  const [selectedActionRef, setSelectedActionRef] = useState("");
  const [selectedAction, setSelectedAction] = useState({
    metadata: { name: "", description: "", ref: "" },
    spec: { executor: { json_args_tpl: "", json_args_schema: "" } },
  });

  const {
    data: actions,
    isFetching: isActionsFetching,
    refetch: refetchActions,
  } = useListActions({}, false);

  const handleConfirm = () => {
    handleAddAction(selectedAction);
    setSelectedAction({
      metadata: { name: "", description: "", ref: "" },
      spec: { executor: { json_args_tpl: "", json_args_schema: "" } },
    });
  };

  useEffect(() => {
    if (open) {
      refetchActions();
    }
  }, [open]);

  return (
    <ConfirmDialog
      open={open}
      title="添加 Action"
      onClose={() => setOpen(false)}
      onConfirm={handleConfirm}
    >
      {open && (
        <Stack spacing={2} sx={{ width: 800 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                fullWidth
                disablePortal
                options={actions.items}
                value={selectedAction.metadata.ref}
                onChange={(event, act) => {
                  if (act) {
                    setSelectedAction(act);
                  }
                }}
                isOptionEqualToValue={(option, ref) => {
                  console.log("option:", option);
                  console.log("ref:", ref);
                  return option.metadata?.ref === ref;
                }}
                getOptionLabel={(value) =>
                  typeof value === "undefined" ? "<undefined>" : `${value}`
                }
                renderOption={(props, act) => (
                  <li {...props} key={act.metadata.uid}>
                    {act.metadata?.ref} ({act.metadata?.description})
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Action" />
                )}
                sx={{ margin: "8px 0" }}
              />
            </Grid>

            {selectedAction.metadata?.ref !== "" && (
              <Grid item xs={12}>
                <Box>名称: {selectedAction.metadata?.name}</Box>
                <Box>描述: {selectedAction.metadata?.description}</Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <strong>参数模板</strong>
              <Box sx={{ width: 800 }}>
                <AceEditor
                  readOnly
                  mode="json"
                  theme="kuroir"
                  width="100%"
                  minLines={15}
                  maxLines={30}
                  value={selectedAction.spec.executor.json_args_tpl}
                  name="http-getter-script"
                  fontSize={14}
                  editorProps={{ $blockScrolling: true }}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <strong>参数 Schema</strong>
              <Box sx={{ width: 800 }}>
                <AceEditor
                  readOnly
                  mode="json"
                  theme="kuroir"
                  width="100%"
                  minLines={15}
                  maxLines={30}
                  value={selectedAction.spec.executor.json_args_schema}
                  name="http-getter-script"
                  fontSize={14}
                  editorProps={{ $blockScrolling: true }}
                />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      )}
    </ConfirmDialog>
  );
}

export default ActionflowActionAddDialog;
