import React from 'react';
import {Box} from "@mui/material";

function OuterBox(props) {
    return (
        <Box sx={{mt: 2, mx: 2}}>
            {props.children}
        </Box>
    );
}

export default OuterBox;