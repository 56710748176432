import React from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";

import "./TaskNodeCard.css";

import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { LoadingOutlined } from "@ant-design/icons";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorIcon from "@mui/icons-material/Error";
import { taskStatus } from "../../../../constants/execution";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

function TaskNodeCard({
  taskId,
  taskState,
  onClickDetails,
  isNeedConfirm,
  ...props
}) {
  const renderTaskStatus = (status) => {
    switch (status) {
      case taskStatus.Skipped:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#939393" }}
          >
            <DoDisturbAltIcon sx={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>跳过执行</Box>
          </Stack>
        );
      case taskStatus.Waiting:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#b7a266" }}
          >
            <AccessTimeIcon sx={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>等待执行</Box>
          </Stack>
        );
      case taskStatus.Confirming:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#ff7300" }}
          >
            <ErrorIcon className="status-blink fast" sx={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>等待确认</Box>
          </Stack>
        );
      case taskStatus.Pending:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#6c7293" }}
          >
            <HourglassTopIcon
              className="status-blink slow"
              sx={{ fontSize: 18 }}
            />
            <Box sx={{ fontSize: "14px" }}>正在挂起</Box>
          </Stack>
        );
      case taskStatus.Running:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#0080ff" }}
          >
            <LoadingOutlined style={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>正在进行</Box>
          </Stack>
        );
      case taskStatus.Succeeded:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#008f53" }}
          >
            <CheckCircleIcon sx={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>执行完成</Box>
          </Stack>
        );
      case taskStatus.Failed:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#bb2121" }}
          >
            <CancelIcon className="status-blink fast" sx={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>执行失败</Box>
          </Stack>
        );
      default:
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ color: "#777777" }}
          >
            <ErrorIcon style={{ fontSize: 18 }} />
            <Box sx={{ fontSize: "14px" }}>未知状态</Box>
          </Stack>
        );
    }
  };

  return (
    <>
      <Card
        key={taskId}
        sx={{ width: "480px", height: "180px", padding: "16px" }}
        {...props}
      >
        <Stack direction="column" spacing={1}>
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item xs={9}>
              <Stack direction="row" spacing={1.5} alignItems="center">
                <DeviceHubIcon sx={{ color: "rgba(0,0,0,0.5)" }} />

                <Stack direction="column">
                  <Box
                    sx={{
                      fontSize: "12px",
                      width: 300,
                      color: "rgba(0,0,0,0.5)",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {taskId}
                  </Box>
                  <Box>
                    <strong>{taskState.name}</strong>
                  </Box>
                  <Box sx={{ fontSize: "14px" }}>{taskState.description}</Box>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={3}>
              <Button
                className={
                  (isNeedConfirm || taskState.status === taskStatus.Failed) &&
                  "status-blink fast"
                }
                variant="outlined"
                startIcon={<MenuOpenIcon />}
                onClick={() => {
                  onClickDetails(taskId, taskState);
                }}
              >
                详情
              </Button>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={1} alignItems="center">
            <Box sx={{ color: "rgba(0,0,0,0.5)", fontSize: "14px" }}>
              执行方式
            </Box>
            <Box sx={{ fontSize: "14px" }}>
              {taskState.actionflow_uid ? (
                <Button
                  variant="text"
                  color="success"
                  startIcon={<MotionPhotosAutoIcon />}
                >
                  Actionflow 自动执行
                </Button>
              ) : (
                <Button variant="text" color="warning">
                  手动执行
                </Button>
              )}
            </Box>
          </Stack>

          <Stack direction="row" spacing={1} alignItems="center">
            <Box sx={{ color: "rgba(0,0,0,0.5)", fontSize: "14px" }}>
              节点状态
            </Box>
            {isNeedConfirm ? (
              <Chip
                className="status-blink fast"
                icon={<TouchAppIcon />}
                label="等待确认"
                color="warning"
                size="small"
              />
            ) : (
              renderTaskStatus(taskState.status)
            )}
          </Stack>
        </Stack>
      </Card>
    </>
  );
}

export default TaskNodeCard;
