import { useMutation, useQuery } from "react-query";
import {
  createActionflow,
  debugActionflowByUid,
  deleteActionflowByUid,
  getActionflowByUid,
  listActionflows,
  patchActionflowByUid,
  saveActionflowByUid,
} from "../../api/actionflow";

export const useCreateActionflow = () => {
  return useMutation(createActionflow);
};

export const useListActionflows = (listOptions, auto = true) => {
  return useQuery(
    ["actionflows", JSON.stringify(listOptions)],
    () => listActionflows(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
      enabled: auto,
    }
  );
};

export const useGetActionflowByUid = (uid) => {
  return useQuery(["actionflows", uid], () => getActionflowByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
        ref: "",
      },
      spec: {
        readme: "",
        params: [],
        executor: {
          image: "",
          entrypoint: [],
          json_args_tpl: "",
        },
      },
    },
  });
};

export const usePatchActionflowByUid = () => {
  return useMutation(patchActionflowByUid);
};

export const useSaveActionflowByUid = () => {
  return useMutation(saveActionflowByUid);
};

export const useDeleteActionflowByUid = () => {
  return useMutation(deleteActionflowByUid);
};

export const useDebugActionflowByUid = () => {
  return useMutation(debugActionflowByUid);
};
