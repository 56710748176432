export const actionCreateType = {
  Base: "Base",
  Derived: "Derived",
};

export const actionflowEditor = {
  // actionflow 编辑 node 节点高度
  actionNodeHeight: 80,
  // actionflow 编辑 node 节点宽度
  actionNodeWidth: 240,
  // actionflow 编辑 node 节点高度
  paramNodeHeight: 60,
  // actionflow 编辑 node 节点宽度
  paramNodeWidth: 200,
};
