import React, { useEffect, useRef, useState } from "react";
import { Box, Paper, Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useGetWorkflowByUid } from "../../../hooks/query/workflow";
import OuterBox from "../../../components/Box/OuterBox/OuterBox";
import InnerBox from "../../../components/Box/InnerBox/InnerBox";
import WorkflowExecForm from "./Form";
import BugReportIcon from "@mui/icons-material/BugReport";
import { formatDatetime } from "../../../utils/datetime";
import { getAuthUserName } from "../../../utils/cookie";
import { getExecutionByUid } from "../../../api/execution";

function WorkflowExec(props) {
  const formRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: workflow,
    isFetching,
    isError,
    error,
  } = useGetWorkflowByUid(searchParams.get("uid"), true);

  return isError ? (
    <Paper sx={{ m: 2, p: 2 }}>
      <Stack spacing={2}>
        <Stack direction={"row"} spacing={1}>
          <BugReportIcon sx={{ fontSize: 26 }} />
          <Box sx={{ fontSize: 18, fontWeight: "bold", lineHeight: 2 }}>
            工作流执行初始化失败
          </Box>
        </Stack>
        <Box>请将以下错误 Debug 信息提交至管理员进行工作流检查与修复</Box>
        <Box
          sx={{
            p: 2,
            borderRadius: 2,
            border: "1px solid #aaa",
            fontSize: 12,
            color: "#c24242",
          }}
        >
          <div>
            [ERROR_AT]
            <br />
            {formatDatetime(new Date())}
          </div>
          <br />
          <div>
            [ERROR_BY]
            <br />
            {getAuthUserName()}
          </div>
          <br />
          <div>
            [ERROR_WORKFLOW]
            <br />
            {searchParams.get("uid")}
          </div>
          <br />
          <div>
            [ERROR_DETAILS]
            <br />
            {error.toString()}
          </div>
        </Box>
      </Stack>
    </Paper>
  ) : (
    <OuterBox>
      <InnerBox>
        <WorkflowExecForm
          formRef={formRef}
          workflow={workflow}
          fromExecutionUid={searchParams.get("from_execution_uid")}
        />
      </InnerBox>
    </OuterBox>
  );
}

export default WorkflowExec;
