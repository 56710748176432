import React, { useRef, useState } from "react";
import ParamsDisplayTable from "../../../../components/Params/Table/ParamsDisplayTable";
import {
  Box,
  Button,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Autocomplete as AutocompleteMui,
  TextField as TextFieldMui,
} from "@mui/material";
import TitledCard from "../../../../components/TitledCard/TitledCard";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ParamsEditDialog from "../../../../components/Params/Dialog/ParamsEditDialog";
import { cloneDeep } from "lodash";
import { Field, Form, Formik } from "formik";
import { TextField, Autocomplete } from "formik-mui";
import DiscountIcon from "@mui/icons-material/Discount";
import lodash from "lodash";

function InfoEdit({
  formRef,
  workflow,
  isFetching,
  editingWorkflow,
  setEditingWorkflow,
  ...props
}) {
  const [paramsEditDialogOpen, setParamsEditDialogOpen] = useState(false);

  return (
    <div>
      <Formik
        innerRef={formRef}
        initialValues={{
          metadata: {
            name: workflow.metadata?.name,
            description: workflow.metadata?.description,
            subscribers: workflow.metadata?.subscribers,
          },
          spec: {
            work_order: {
              name_template: workflow.spec.work_order?.name_template,
              description_template:
                workflow.spec.work_order?.description_template,
            },
          },
        }}
        onSubmit={(values, { setSubmitting }) => {
          // console.log("values", values);
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TitledCard
                  title="工作流元数据编辑"
                  subtitle="完善工作流命名/描述/分类等"
                  icon={<DiscountIcon sx={{ fontSize: 16 }} />}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={TextField}
                        label="名称"
                        name="metadata.name"
                        size="small"
                        validate={(val) => {
                          let error;
                          if (!val) {
                            error = `名称为必填项`;
                          }
                          return error;
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={TextField}
                        label="描述"
                        name="metadata.description"
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <AutocompleteMui
                        multiple
                        id="tags-filled"
                        options={editingWorkflow?.metadata?.tags || []}
                        value={editingWorkflow?.metadata?.tags || []}
                        onChange={(e, newVal) => {
                          let newEditingWorkflow =
                            lodash.cloneDeep(editingWorkflow);
                          newEditingWorkflow.metadata.tags = newVal;
                          setEditingWorkflow(newEditingWorkflow);
                        }}
                        defaultValue={editingWorkflow?.metadata?.tags || []}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="filled"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextFieldMui
                            {...params}
                            label="标签"
                            placeholder="新标签"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <AutocompleteMui
                        multiple
                        options={editingWorkflow?.metadata?.subscribers || []}
                        value={editingWorkflow?.metadata?.subscribers || []}
                        onChange={(e, newVal) => {
                          let newEditingWorkflow =
                            lodash.cloneDeep(editingWorkflow);
                          newEditingWorkflow.metadata.subscribers = newVal;
                          setEditingWorkflow(newEditingWorkflow);
                        }}
                        defaultValue={
                          editingWorkflow?.metadata?.subscribers || []
                        }
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant="filled"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextFieldMui
                            {...params}
                            label="订阅/通知列表 (POPO通知)"
                            placeholder="支持个人邮箱或已在 Workflow 加入项目的 POPO 群号"
                          />
                        )}
                      />
                    </Grid>

                    {isSubmitting && (
                      <Grid item xs={12}>
                        <LinearProgress />
                      </Grid>
                    )}
                  </Grid>
                </TitledCard>
              </Grid>

              <Grid item xs={6}>
                <TitledCard
                  title="工单模板编辑"
                  subtitle="用于创建 SA-PM 工单，支持引用全局参数渲染 (Go模板语法)"
                  icon={<DiscountIcon sx={{ fontSize: 16 }} />}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={TextField}
                        label="工单名称模板"
                        name="spec.work_order.name_template"
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={TextField}
                        label="工单描述模板"
                        name="spec.work_order.description_template"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </TitledCard>
              </Grid>

              <Grid item xs={12}>
                <TitledCard
                  isLoading={isFetching}
                  icon={<LibraryBooksIcon sx={{ fontSize: 16 }} />}
                  title="全局参数声明"
                  subtitle="全局参数可以被所有工作流任务节点所访问"
                >
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1}>
                      <Button onClick={() => setParamsEditDialogOpen(true)}>
                        参数编辑
                      </Button>
                    </Stack>
                    <ParamsDisplayTable
                      params={workflow.spec?.params || []}
                      isLoading={isFetching}
                    />

                    {/* 参数编辑 */}
                    <ParamsEditDialog
                      open={paramsEditDialogOpen}
                      params={workflow.spec?.params}
                      onClose={() => setParamsEditDialogOpen(false)}
                      onConfirm={(newParams) => {
                        let newWorkflow = cloneDeep(workflow);
                        workflow.spec.params = newParams;
                        // setEditingAction(newAction)
                        setParamsEditDialogOpen(false);
                      }}
                    />
                  </Stack>
                </TitledCard>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default InfoEdit;
