import React from "react";

import { Navigate, useRoutes } from "react-router-dom";

import { GlobalConfig } from "../globalConfig";

import ViewCompactAltIcon from "@mui/icons-material/ViewCompactAlt";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import EventIcon from "@mui/icons-material/Event";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BallotIcon from "@mui/icons-material/Ballot";
import MotionPhotosAutoIcon from "@mui/icons-material/MotionPhotosAuto";
import WebhookIcon from "@mui/icons-material/Webhook";
import MapIcon from "@mui/icons-material/Map";

import Workspace from "../pages/Workspace";
import Workflow from "../pages/Workflow";
import Execution from "../pages/Execution";
import Actionflow from "../pages/Actionflow";
import Datasource from "../pages/Datasource";
import WorkflowEdit from "../pages/Workflow/Edit";
import WorkflowExec from "../pages/Workflow/Exec";
import ExecutionDashboard from "../pages/Execution/Dashboard/ExecutionDashboard";
import ActionEdit from "../pages/Actionflow/Edit/action_edit";
import DatasourceEdit from "../pages/Datasource/Edit";
import Event from "../pages/Event";
import Trigger from "../pages/Trigger";
import EventDetails from "../pages/Event/Details/EventDetails";
import TriggerEdit from "../pages/Trigger/Edit";
import ActionflowEdit from "../pages/Actionflow/Edit/actionflow_edit";
import PageNotFound from "../pages/Common/PageNotFound";
import NoPrivilege from "../pages/Common/NoPrivilege";
import LayoutContainer from "../components/Layout/LayoutContainer";

/** +++++++++++++++++++++++++ ops-ui 路由规约 +++++++++++++++++++++++++
 *
 * 1. 扁平化列表，即在 routes 下第一级列表中列出所有路由，不使用 children 嵌套
 * 2. 使用 searchParams 而非 pathParams，例: /workflows/edit?uid=1 代替 /workflows/1/edit
 *
 */

export const routeConfig = {
  // 开放用户路由
  userRoutes: {
    title: "用户面板",
    isNav: true,
    routes: [],
  },
  // 管理员路由
  adminRoutes: {
    title: "管理面板",
    isNav: true,
    routes: [],
  },
};

/** +++++++++++++++++++++++++ 路由规约 +++++++++++++++++++++++++
 *
 * 1. 扁平化列表，即在 routes 下第一级列表中列出所有路由，不使用 children 嵌套
 * 2. 使用 searchParams 而非 pathParams，例: /user/edit?uid=1 代替 /user/1/edit
 *
 */

export const NavRoutes = [
  {
    path: "/workspace",
    title: "工作空间",
    isNav: true,
    icon: <ViewCompactAltIcon />,
    element: <Workspace />,
  },
  {
    path: "/executions",
    title: "实例列表",
    isNav: true,
    icon: <AllInclusiveIcon />,
    element: <Execution />,
  },
  {
    path: "/executions/dashboard",
    title: "实例执行面板",
    element: <ExecutionDashboard />,
  },
  {
    path: "/events/details",
    title: "事件详情",
    element: <EventDetails />,
  },
  {
    path: "/workflows",
    title: "工作流",
    isNav: true,
    icon: <AccountTreeIcon />,
    element: <Workflow />,
  },
  {
    path: "/workflows/edit",
    title: "工作流编辑",
    element: <WorkflowEdit />,
  },
  {
    path: "/workflows/exec",
    title: "工作流执行",
    element: <WorkflowExec />,
  },
  {
    path: "/actionflows",
    title: "Actionflow",
    isNav: true,
    icon: <MotionPhotosAutoIcon />,
    element: <Actionflow />,
  },
  {
    path: "/actions/edit",
    title: "Action 配置",
    element: <ActionEdit />,
  },
  {
    path: "/actionflows/edit",
    title: "Actionflow 编排",
    element: <ActionflowEdit />,
  },
  {
    path: "/datasources",
    title: "数据源",
    isNav: true,
    icon: <BallotIcon />,
    element: <Datasource />,
  },
  {
    path: "/datasources/edit",
    title: "数据源配置",
    element: <DatasourceEdit />,
  },
  {
    path: "/triggers",
    title: "触发器",
    isNav: true,
    icon: <WebhookIcon />,
    element: <Trigger />,
  },
  {
    path: "/triggers/edit",
    title: "触发器配置",
    element: <TriggerEdit />,
  },
  {
    path: "/events",
    title: "事件列表",
    isNav: true,
    icon: <EventIcon />,
    element: <Event />,
  },
  {
    path: GlobalConfig.site.docsUrl,
    title: "文档",
    isNav: true,
    icon: <MapIcon />,
  },
];

export const RouteConfig = [
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/",
    element: <Navigate to={GlobalConfig.router.entryRoute} />,
  },
  {
    path: "/",
    element: <LayoutContainer />,
    children: NavRoutes,
  },
  {
    path: "/403",
    element: <NoPrivilege />,
  },
];

function AppRoutes() {
  return useRoutes(RouteConfig);
}

export default AppRoutes;
