import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { Button, Chip, Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useDeleteTriggerByUid,
  useListTriggers,
} from "../../../hooks/query/trigger";
import CountdownDialog from "../../../components/Dialog/Countdown";
import CustomTable from "../../../components/Table/CustomTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TuneIcon from "@mui/icons-material/Tune";

function TriggerTable(props) {
  let navigate = useNavigate();
  const { data: triggers, isFetching, refetch } = useListTriggers();

  const { mutate: deleteTriggerByUid } = useDeleteTriggerByUid();
  const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
  const [deleteTriggerId, setDeleteTriggerId] = useState("");

  const handleClickDelete = () => {
    deleteTriggerByUid(
      { uid: deleteTriggerId },
      { onSuccess: () => refetch() }
    );
    setDialogConfirmDeleteOpen(false);
    setDeleteTriggerId("");
  };

  const columns = [
    {
      field: "name",
      headerName: "名称/UID",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.metadata.name} [uid: ${params.row.metadata.uid}]`,
      renderCell: (data) => {
        return (
          <div>
            <div style={{ fontWeight: "bold" }}>
              <Link
                color={"inherit"}
                underline="none"
                onClick={() => {
                  navigate(`/triggers/edit?uid=${data.row.metadata.uid}`);
                }}
              >
                {data.row.metadata.name}
              </Link>
            </div>
            <div style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>
              {data.row.metadata.uid}
            </div>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "描述",
      flex: 1,
      valueGetter: (params) => params.row.metadata.description,
    },
    {
      field: "source_type",
      headerName: "触发源",
      width: 120,
      valueGetter: (params) => params.row.spec.source.type,
      renderCell: (data) => (
        <Chip label={data.row.spec.source.type} color="primary" size="small" />
      ),
    },
    {
      field: "target_type",
      headerName: "触发目标",
      width: 120,
      valueGetter: (params) => params.row.spec.target.type,
      renderCell: (data) => (
        <Chip label={data.row.spec.target.type} color="info" size="small" />
      ),
    },
    {
      field: "is_enabled",
      headerName: "启用",
      width: 80,
      valueGetter: (params) => params.row.metadata.is_enabled,
      renderCell: (data) =>
        data.row.metadata.is_enabled ? (
          <Chip label="启用" color="success" size="small" />
        ) : (
          <Chip label="停用" color="default" size="small" />
        ),
    },
    {
      field: "actions",
      headerName: "操作",
      width: 170,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<TuneIcon />}
              variant={"outlined"}
              onClick={() =>
                navigate(`/triggers/edit?uid=${data.row.metadata.uid}`)
              }
            >
              编辑
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant={"outlined"}
              color={"error"}
              onClick={() => {
                setDeleteTriggerId(data.row.metadata.uid);
                setDialogConfirmDeleteOpen(true);
              }}
            >
              移除
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CountdownDialog
        open={dialogConfirmDeleteOpen}
        title="确认删除"
        description="是否确认删除触发器？请确认影响，谨慎操作"
        handleClose={() => setDialogConfirmDeleteOpen(false)}
        handleConfirm={handleClickDelete}
      />
      <CustomTable
        rows={triggers.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default TriggerTable;
