import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
} from "@mui/material";
import lodash from "lodash";
import { useNotification } from "../../../../hooks/common/useNotification";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-idle_fingers";
import { handleResponseEnvelope, post } from "../../../../utils/request";
import { debugActionflowByUid } from "../../../../api/actionflow";

const DEFAULT_DEBUG_ARGS = `{
  "arg_a": "value_a",
  "arg_b": "value_b"
}`;

function ActionflowDebugDialog({ open, setOpen, actionflow }) {
  const notification = useNotification();
  // const { mutate: debugActionflowByUid } = useDebugActionflowByUid();
  const [debugOutput, setDebugOutput] = useState("");
  const [debuging, setDebuging] = useState(false);
  const [breakpoint, setBreakpoint] = useState(0);
  const [debugArgsStr, setDebugArgsStr] = useState(DEFAULT_DEBUG_ARGS);

  const onClose = () => {
    setDebugOutput("");

    // 清理调试参数
    // setDebugArgsStr(DEFAULT_DEBUG_ARGS);

    setOpen(false);
  };

  const handleDebug = () => {
    setDebugOutput("");
    let debugPayload = {};

    let debugArgs;
    try {
      debugArgs = JSON.parse(debugArgsStr);
      console.log("debugArgs parsed:", debugArgs);
    } catch (err) {
      notification.warning("无法解析调试参数 JSON", err.toString());
      return;
    }

    debugPayload.actions = actionflow?.spec?.actions;
    debugPayload.debug_args = debugArgs;
    debugPayload.breakpoint = breakpoint;

    console.log(debugPayload);

    setDebuging(true);

    debugActionflowByUid({ payload: debugPayload })
      .then((data) => {
        console.log("data:", data);
        setDebuging(false);
        setDebugOutput(data);
        notification.success("调试完成");
      })
      .catch((err) => {
        console.log("error!:", err.toString());
        setDebuging(false);
        setDebugOutput(err.toString());
        notification.warning("调试异常");
      });

    // debugActionflowByUid(
    //   { payload: debugPayload },
    //   {
    //     onSuccess: (data) => {
    //       setDebuging(false);
    //       setDebugOutput(data);
    //       notification.success("调试完成");
    //     },
    //     onError: (err) => {
    //       setDebuging(false);
    //       setDebugOutput(err.toString());
    //     },
    //   }
    // );
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Actionflow 调试台</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <b>调试渲染参数（JSON 格式）</b>
          </Grid>
          <Grid item xs={12}>
            <AceEditor
              mode="json"
              theme="idle_fingers"
              width="100%"
              maxLines={15}
              minLines={5}
              value={debugArgsStr}
              onChange={(val) => setDebugArgsStr(val)}
              name="http-getter-script"
              fontSize={14}
              editorProps={{ $blockScrolling: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <b>调试结果</b>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#eef0f1",
                px: 2,
                py: 1,
                borderRadius: 1,
                border: "1px solid #ccc",
                maxHeight: "600px",
                overflow: "auto",
              }}
            >
              <pre style={{ margin: 0 }}>
                {debuging &&
                  "调试中...（如果 AEI 镜像未预热，执行时间会有所增加）"}
              </pre>
              <pre style={{ margin: 0 }}>{debugOutput}</pre>
            </Box>
            {debuging && <LinearProgress />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          取消
        </Button>
        <Button onClick={handleDebug} autoFocus>
          执行调试
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ActionflowDebugDialog;
