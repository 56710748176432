import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";
import { localGetCurrentNamespace } from "../localstorage/namespace";

export const createAction = async ({ newAction, derivedFrom, derivedName }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/actions?derived_from=${derivedFrom}&derived_name=${derivedName}`,
    newAction
  );
  return handleResponseEnvelope(data);
};

export const listActions = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/actions`, listOptions);
  return handleResponseEnvelope(data);
};

export const getActionByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/actions/${uid}`);
  return handleResponseEnvelope(data);
};

export const patchActionByUid = async ({ uid, payload }) => {
  const { data } = await patch(`/proxy/@ops/api/v1/actions/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const saveActionByUid = async ({ uid, payload }) => {
  const { data } = await put(`/proxy/@ops/api/v1/actions/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const deleteActionByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@ops/api/v1/actions/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};

export const debugActionByUid = async ({ payload }) => {
  const { data } = await post(`/proxy/@ops/api/v1/actions/debug`, payload);
  return handleResponseEnvelope(data);
};
