import { get, handleResponseEnvelope } from "../utils/request";

export const listSyslogs = async (selector = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/syslogs`, selector);
  return handleResponseEnvelope(data);
};

export const getSyslogByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/syslogs/${uid}`);
  return handleResponseEnvelope(data);
};
