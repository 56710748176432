import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";

export const createExecution = async (payload) => {
  const { data } = await post(`/proxy/@ops/api/v1/executions`, payload);
  return handleResponseEnvelope(data);
};

export const listExecutions = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/executions`, listOptions);
  return handleResponseEnvelope(data);
};

export const getExecutionByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/executions/${uid}`);
  return handleResponseEnvelope(data);
};

export const createExecutionWorkOrderByUid = async ({ uid, payload }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/executions/${uid}/work_order`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const patchExecutionByUid = async ({ uid, payload }) => {
  const { data } = await patch(`/proxy/@ops/api/v1/executions/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const patchExecutionStateVarsByUid = async ({ uid, payload }) => {
  const { data } = await patch(
    `/proxy/@ops/api/v1/executions/${uid}/state/vars`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const saveExecutionByUid = async ({ uid, payload }) => {
  const { data } = await put(`/proxy/@ops/api/v1/executions/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const deleteExecutionByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@ops/api/v1/executions/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};

export const confirmTaskByExecutionUidAndTaskId = async ({ uid, taskId }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/executions/${uid}/tasks/${taskId}/confirm`,
    {}
  );
  return handleResponseEnvelope(data);
};

export const rerunTaskByExecutionUidAndTaskId = async ({ uid, taskId }) => {
  const { data } = await post(
    `/proxy/@ops/api/v1/executions/${uid}/tasks/${taskId}/rerun`,
    {}
  );
  return handleResponseEnvelope(data);
};
