import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TitledArea from "../../components/TitledArea/TitledArea";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  InputAdornment,
  LinearProgress,
  Stack,
  TextField,
} from "@mui/material";
import { useNotification } from "../../hooks/common/useNotification";
import ContentArea from "../../components/ContentArea/ContentArea";
import { useListExecutions } from "../../hooks/query/execution";
import { getAuthUserName } from "../../utils/cookie";
import { executionStatus } from "../../constants/execution";
import { useListWorkflows } from "../../hooks/query/workflow";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Alert, Autocomplete } from "@mui/lab";
import lodash from "lodash";
import { getExecutionStatusChip } from "../../utils/chips";
import { formatDatetime } from "../../utils/datetime";
import { AppContext } from "../../App";
import { getS3FileLog } from "../../api/task_execution";

function Workspace(props) {
  const { namespace, authUser } = useContext(AppContext);

  const navigate = useNavigate();
  const notification = useNotification();

  const [filterKeyword, setFilterKeyword] = useState("");
  const [filterTags, setFilterTags] = useState([]);

  const { data: executions, isFetching: isExecutionsFetching } =
    useListExecutions(
      { "metadata.owner_references.maintainer": getAuthUserName() },
      "metadata,status"
    );

  const { data: workflows, isFetching: isWorkflowsFetching } = useListWorkflows(
    {}
  );
  const [filteredWorkflows, setFilteredWorkflows] = useState({
    items: [],
  });
  const [allTags, setAllTags] = useState([]);

  useEffect(() => {
    // copy workflows
    setFilteredWorkflows(lodash.cloneDeep(workflows));

    // 获取 tags 集合
    let tagSet = new Set();
    workflows.items?.forEach((wf) => {
      wf.metadata.tags?.forEach((tag) => tagSet.add(tag));
    });
    setAllTags(Array.from(tagSet));
  }, [workflows]);

  useEffect(() => {
    setFilteredWorkflows({
      items: workflows.items.filter((wf) => {
        let allMatch = true;

        filterTags.forEach((filterTag) => {
          if (wf.metadata.tags.includes(filterTag) === false) {
            allMatch = false;
          }
        });

        if (
          !(
            wf.metadata.name
              .toLowerCase()
              .includes(filterKeyword.toLowerCase()) ||
            wf.metadata.description
              .toLowerCase()
              .includes(filterKeyword.toLowerCase())
          )
        ) {
          allMatch = false;
        }

        return allMatch;
      }),
    });
  }, [filterKeyword, filterTags]);

  return (
    <div>
      <ContentArea>
        {/* 进行中的实例 */}
        <TitledArea title={`${authUser.metadata.fullname} 进行中的实例`}>
          <Grid container spacing={2}>
            {/* executions 载入 */}
            {isExecutionsFetching && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            {executions.items?.filter(
              (e) => e.status !== executionStatus.Finished
            ).length === 0 && (
              <Grid item xs={12}>
                <Alert severity="info">暂无进行中的实例</Alert>
              </Grid>
            )}
            {executions.items
              ?.filter((e) => e.status !== executionStatus.Finished)
              .map((e) => (
                <Grid key={e.metadata.uid} item xs={12} md={6} xl={4}>
                  <Card sx={{ p: 2 }}>
                    <Stack spacing={1}>
                      <Box
                        style={{
                          fontSize: 12,
                          color: "#8C8C8C",
                        }}
                      >
                        {`${e.metadata.created_by} 创建于 ${formatDatetime(
                          e.metadata.created_at
                        )}`}
                        <span style={{ float: "right" }}>
                          {getExecutionStatusChip(e.status)}{" "}
                        </span>
                      </Box>
                      <Box
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          marginRight: 8,
                        }}
                      >
                        {e.metadata.name}
                      </Box>
                      <Box
                        style={{
                          fontSize: 12,
                          color: "#8C8C8C",
                          lineHeight: "30px",
                        }}
                      >
                        <Button
                          // variant="outlined"
                          startIcon={<TelegramIcon sx={{ fontSize: 16 }} />}
                          sx={{ float: "right" }}
                          size="small"
                          variant="outlined"
                          onClick={() => {
                            if (e.status === executionStatus.Initializing) {
                              // Initializing 的转入 workflow exec 页面
                              navigate(
                                `/workflows/exec?uid=${e.metadata.owner_references.workflow_uid}&from_execution_uid=${e.metadata.uid}`
                              );
                            } else {
                              navigate(
                                `/executions/dashboard?uid=${e.metadata.uid}`
                              );
                            }
                          }}
                        >
                          详情
                        </Button>
                        {e.metadata.description}
                      </Box>
                    </Stack>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </TitledArea>
        {/*<TitledArea title={"已关注的实例"}>*/}
        {/*    <Grid container spacing={2}>*/}
        {/*        <Grid item xs={12} md={6} xl={4}>*/}
        {/*            <Item>xs=4</Item>*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*</TitledArea>*/}
        {/* 快速启动 */}
        <TitledArea title={"工作流执行"} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {/* 基于关键词搜索 */}
                  <TextField
                    fullWidth
                    label="基于关键词搜索"
                    value={filterKeyword}
                    onChange={(e) => setFilterKeyword(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* 基于标签搜索*/}
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    size="small"
                    value={filterTags}
                    onChange={(e, selectedTags) => setFilterTags(selectedTags)}
                    options={allTags}
                    getOptionLabel={(tag) => tag}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} label="基于标签搜索" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* workflows 载入 */}
            {isWorkflowsFetching && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            {filteredWorkflows.items?.length === 0 && (
              <Grid item xs={12}>
                <Alert severity="info">暂无可执行的工作流</Alert>
              </Grid>
            )}

            <Grid item xs={12} sx={{ maxHeight: "600px", overflowY: "auto" }}>
              <Grid container spacing={2} sx={{ pb: 1 }}>
                {filteredWorkflows.items?.map((wf) => (
                  <Grid key={wf.metadata.uid} item xs={6} md={4} xl={3}>
                    <Card
                      onClick={() =>
                        navigate(`/workflows/exec?uid=${wf.metadata.uid}`)
                      }
                    >
                      <CardContent>
                        <Stack
                          spacing={1}
                          sx={{ textAlign: "center", cursor: "pointer" }}
                        >
                          <Box
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#3F51B5",
                            }}
                          >
                            {wf.metadata.name}
                          </Box>
                          <Box
                            style={{
                              fontSize: 12,
                              color: "#00000099",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {wf.metadata.description || "无描述"}
                          </Box>
                          <Stack
                            direction={"row"}
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                          >
                            {wf.metadata.tags?.map((tag) => (
                              <Chip key={tag} label={tag} size={"small"} />
                            ))}
                            {wf.metadata.tags?.length === 0 && (
                              <Chip
                                size="small"
                                label="无标签"
                                variant="outlined"
                              />
                            )}
                          </Stack>
                        </Stack>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </TitledArea>
      </ContentArea>
    </div>
  );
}

export default Workspace;
