/**
 * Site 全局状态管理模块
 */

import { createSlice } from "@reduxjs/toolkit";


export const siteSlice = createSlice({
    name: "site",
    initialState: {
        routeStack: [], // 当前路由栈
        currentRoute: {},
    },
    reducers: {
        // 更新当前路由栈
        updateRouteStack: (state, action) => {
            state.routeStack = action.payload
        }
    },
    extraReducers: {

    },
});

export const {
    updateRouteStack
} = siteSlice.actions


export default siteSlice.reducer;