import { del, get, patch, post, put } from "../utils/request";

const create = ({ data }) => {
  return post(`/proxy/@ops/api/v1/workflows`, data);
};

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/workflows`, listOptions);
};

const getByUid = ({ uid, loadDatasource = false }) => {
  return get(
    `/proxy/@ops/api/v1/workflows/${uid}?load_datasource=${loadDatasource}`
  );
};

const patchByUid = ({ uid, data }) => {
  return patch(`/proxy/@ops/api/v1/workflows/${uid}`, data);
};

const saveByUid = ({ uid, data }) => {
  return put(`/proxy/@ops/api/v1/workflows/${uid}`, data);
};

const deleteByUid = ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  return del(`/proxy/@ops/api/v1/workflows/${uid}?force=${force}`);
};

export const WorkflowService = {
  create,
  listAll,
  getByUid,
  patchByUid,
  saveByUid,
  deleteByUid,
};
