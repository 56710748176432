export const datasourceSourceType = {
    Custom: "Custom",
    HttpGet: "HttpGet"
}

export const datasourceFormatType = {
    List: "List",
    Map: "Map",
    MapList: "MapList",
    Text: "Text"
}

export const datasourceConstraintType = {
    None: "",
    SelectOptionList: "SelectOptionList",
    TaskRangeList: "TaskRangeList"
}