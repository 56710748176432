import React from 'react';
import {useSnackbar} from "notistack";
import {Box} from "@mui/material";

const NotificationBody = ({title, details}) => {
    return (
        <Box sx={{textAlign: "left", maxWidth: 600}}>
            <div style={{fontWeight: details ? 'bold' : 'normal'}}>{title}</div>
            {details && <div>{details}</div>}
        </Box>
    )
}

export const useNotification = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    return {
        success: (title, details) => {
            enqueueSnackbar(<NotificationBody title={title} details={details}/>, {variant: 'success'});
        },
        error: (title, details) => {
            enqueueSnackbar(<NotificationBody title={title} details={details}/>, {variant: 'error'});
        },
        warning: (title, details) => {
            enqueueSnackbar(<NotificationBody title={title} details={details}/>, {variant: 'warning'});
        },
        info: (title, details) => {
            enqueueSnackbar(<NotificationBody title={title} details={details}/>, {variant: 'info'});
        },
    }
}