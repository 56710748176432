import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import {
  Autocomplete as AutocompleteMui,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  MenuItem,
  Stack,
  TextField as TextFieldMui,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { TextField } from "formik-mui";
import LoadingProgress from "../../../../components/LoadingProgress/LoadingProgress";
import TitledCard from "../../../../components/TitledCard/TitledCard";
import {
  DescriptionItem,
  Descriptions,
} from "../../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../../utils/datetime";
import { getAuthUserName } from "../../../../utils/cookie";
import { useCreateExecution } from "../../../../hooks/query/execution";
import { useNotification } from "../../../../hooks/common/useNotification";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/lab";
import lodash from "lodash";
import { getExecutionByUid } from "../../../../api/execution";
import DragSortTable from "../../../../components/Table/DragSortTable";
import { MenuOutlined } from "@ant-design/icons";
import RangeListAccordion from "./RangeListAccordion";

function WorkflowExecForm({ formRef, workflow, fromExecutionUid, ...props }) {
  const [fromExecution, setFromExecution] = useState({ metadata: {} });
  const [initialValues, setInitialValues] = useState({});
  const { mutate: createExecution } = useCreateExecution();
  const notification = useNotification();
  const navigate = useNavigate();
  const [allTasksRangeList, setAllAllTasksRangeList] = useState({});
  const [isSaveCreating, setIsSaveCreating] = useState(false);

  useEffect(() => {
    if (workflow.metadata.uid && workflow.metadata.uid !== "") {
      // 如果是从已有 execution
      if (fromExecutionUid && fromExecutionUid !== "") {
        fetchFromExecutionAndInitValues(workflow, fromExecutionUid);
      } else {
        initFormValuesByWorkflow(workflow);
      }
    }
  }, [workflow]);

  const fetchFromExecutionAndInitValues = async (
    workflow,
    fromExecutionUid
  ) => {
    let e = await getExecutionByUid(fromExecutionUid);
    setFromExecution(e);
    initFormValuesByWorkflowAndExecution(workflow, e);
  };

  const initFormValuesByWorkflow = (workflow) => {
    let globalArgs = {};
    let tasksInitState = {};

    // 处理全局初始状态
    workflow.spec?.params?.forEach((gParam) => {
      if (gParam.datasource_uid !== "") {
        // select
        let defaultOptionVal = gParam.options?.find(
          (item) => item.is_default
        )?.value;
        if (defaultOptionVal) {
          globalArgs[gParam.name] = defaultOptionVal;
        } else {
          // 缺省取第一个选项值为默认
          globalArgs[gParam.name] = gParam.select_options[0]?.value;
        }
      } else {
        // input
        globalArgs[gParam.name] = gParam.default_value;
      }
    });

    // 处理任务节点初始状态
    Object.keys(workflow.spec.tasks)?.forEach((taskId) => {
      if (tasksInitState[taskId] === undefined) {
        tasksInitState[taskId] = {
          args: {},
          range_list: workflow.spec.tasks[taskId].range?.items, // 初始化 rangeList
        };
      }

      // 处理任务节点 args
      workflow.spec.tasks[taskId].params?.forEach((tParam) => {
        // 普通输入变量
        tasksInitState[taskId].args[tParam.name] = tParam.default_value;
        if (tParam.datasource_uid !== "") {
          // select
          let defaultOptionVal = tParam.options?.find(
            (item) => item.is_default
          )?.value;
          if (defaultOptionVal) {
            tasksInitState[taskId].args[tParam.name] = defaultOptionVal;
          } else {
            // 缺省取第一个选项值为默认
            tasksInitState[taskId].args[tParam.name] =
              tParam.select_options[0]?.value;
          }
        } else {
          // input
          tasksInitState[taskId].args[tParam.name] = tParam.default_value;
        }
      });
    });

    let initialData = {
      mode: "Manual",
      is_save_creating: false,
      workflow_uid: workflow.metadata.uid,
      name: `${workflow.metadata.name}_@${getAuthUserName()}`,
      description: "描述",
      maintainer: getAuthUserName(),
      initial_state: {
        global_args: globalArgs,
        tasks: tasksInitState,
      },
    };

    // 初始化 rangeList 可控数据
    let newTasksRangeList = {};
    Object.keys(tasksInitState).forEach((stateTaskId) => {
      // 只获取名称
      newTasksRangeList[stateTaskId] = tasksInitState[stateTaskId].range_list;
    });
    setAllAllTasksRangeList(newTasksRangeList);

    setInitialValues(initialData);
  };

  const initFormValuesByWorkflowAndExecution = (workflow, execution) => {
    let globalArgs = {};
    let tasksInitState = {};

    // 处理全局初始状态
    workflow.spec?.params?.forEach((gParam) => {
      globalArgs[gParam.name] = execution.state?.global_args[gParam.name];
    });

    // 处理任务节点初始状态
    Object.keys(workflow.spec.tasks)?.forEach((taskId) => {
      if (tasksInitState[taskId] === undefined) {
        tasksInitState[taskId] = {
          args: {},
          range_list: workflow.spec.tasks[taskId].range?.items, // 初始化 rangeList
        };
      }

      // 处理任务节点 args
      workflow.spec.tasks[taskId].params?.forEach((tParam) => {
        // 普通输入变量
        tasksInitState[taskId].args[tParam.name] =
          execution.state?.tasks[taskId].args[tParam.name];
      });
    });

    let initialData = {
      mode: "Manual",
      is_save_creating: false,
      workflow_uid: workflow.metadata?.uid,
      name: `${execution.metadata?.name}`,
      description: `${execution.metadata?.description}`,
      trigger_references: {
        ce_source: `${execution.metadata?.trigger_references?.ce_source}`,
        ce_id: `${execution.metadata?.trigger_references?.ce_id}`,
        ce_type: `${execution.metadata?.trigger_references?.ce_type}`,
        event_uid: `${execution.metadata?.trigger_references?.event_uid}`,
        trigger_uid: `${execution.metadata?.trigger_references?.trigger_uid}`,
      },
      maintainer: `${execution.metadata?.maintainer || getAuthUserName()}`,
      initial_state: {
        global_args: globalArgs,
        tasks: tasksInitState,
      },
    };

    // 初始化 rangeList 可控数据
    let newTasksRangeList = {};
    Object.keys(tasksInitState).forEach((stateTaskId) => {
      // 只获取名称
      newTasksRangeList[stateTaskId] = tasksInitState[stateTaskId].range_list;
    });
    setAllAllTasksRangeList(newTasksRangeList);

    setInitialValues(initialData);
  };

  return initialValues.name && initialValues.name !== "" ? (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={(createOptions, { setSubmitting }) => {
        console.log("createOptions:", createOptions);

        // 保存创建状态
        createOptions.is_save_creating = isSaveCreating;
        createOptions.from_saved_execution_uid = fromExecutionUid;

        // 加入 RangeList 数据
        Object.keys(createOptions.initial_state?.tasks).map((taskId) => {
          createOptions.initial_state.tasks[taskId].range_list =
            allTasksRangeList[taskId];
        });

        createExecution(createOptions, {
          onSuccess: (data) => {
            setSubmitting(false);
            if (isSaveCreating === false) {
              notification.success(
                "工作流实例创建成功",
                `UID: ${data.metadata.uid}`
              );
              navigate(`/executions/dashboard?uid=${data.metadata.uid}`);
            } else {
              notification.success(
                "工作流实例创建参数保存成功",
                `UID: ${data.metadata.uid}`
              );
            }
          },
          onError: (err) => {
            setSubmitting(false);
            console.log("error data:", err.toString());
          },
        });
      }}
    >
      {({ submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={2} sx={{ flexGrow: 1 }}>
            {isSubmitting && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <TitledCard title="工作流信息" subtitle="工作流定义元数据信息">
                <Descriptions>
                  <DescriptionItem label={"名称"} span={12}>
                    {workflow?.metadata?.name}
                  </DescriptionItem>
                  <DescriptionItem label={"UID"} span={12}>
                    {workflow?.metadata.uid}
                  </DescriptionItem>
                  <DescriptionItem label={"命名空间"} span={12}>
                    {workflow?.metadata?.namespace}
                  </DescriptionItem>
                  <DescriptionItem label={"描述"} span={12}>
                    {workflow?.metadata?.description}
                  </DescriptionItem>
                  <DescriptionItem label={"创建人"} span={12}>
                    {workflow?.metadata?.created_by}
                  </DescriptionItem>
                  <DescriptionItem label={"创建时间"} span={12}>
                    {formatDatetime(workflow?.metadata?.created_at)}
                  </DescriptionItem>
                  <DescriptionItem label={"更新人"} span={12}>
                    {workflow?.metadata?.updated_by}
                  </DescriptionItem>
                  <DescriptionItem label={"更新时间"} span={12}>
                    {formatDatetime(workflow?.metadata?.updated_at)}
                  </DescriptionItem>
                </Descriptions>
              </TitledCard>

              <TitledCard
                title="实例基本信息"
                subtitle="简洁有效的文字可以传达更多的信息"
              >
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="实例名称"
                      name="name"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      label="实例描述"
                      name="description"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      component={TextField}
                      disabled
                      label="执行人"
                      name="maintainer"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </TitledCard>

              <Grid item xs={12}>
                <Stack spacing={2}>
                  <ToggleButtonGroup
                    exclusive
                    color="primary"
                    size="small"
                    value={isSaveCreating}
                    onChange={(e, val) => {
                      if (val !== null) {
                        setIsSaveCreating(val);
                      }
                    }}
                  >
                    <ToggleButton value={true}>保存当前创建</ToggleButton>
                    <ToggleButton value={false}>立即启动执行</ToggleButton>
                  </ToggleButtonGroup>
                  <Button onClick={submitForm}>
                    {isSaveCreating
                      ? "保存当前创建状态，不启动执行"
                      : "立即启动执行"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12} md={9}>
              <TitledCard
                title="全局参数"
                subtitle="生效范围为工作流实例全部节点"
              >
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                  {workflow.metadata.uid && workflow.metadata.uid !== "" ? (
                    workflow?.spec?.params?.map((param) =>
                      param.datasource_uid === "" ? (
                        // 无数据源
                        <Grid key={param.name} item xs={12} md={6}>
                          <Field
                            fullWidth
                            component={TextField}
                            size="small"
                            label={param.name}
                            name={`initial_state.global_args.${param.name}`}
                            helperText={param.description}
                            validate={(val) => {
                              let error;
                              if (param.is_required && !val) {
                                error = `${param.name} 为必填项`;
                              }
                              return error;
                            }}
                          />
                        </Grid>
                      ) : (
                        // 有数据源
                        <Grid key={param.name} item xs={12} sx={{ pl: 2 }}>
                          <Field
                            fullWidth
                            select
                            size="small"
                            component={TextField}
                            helperText={param.description}
                            name={`initial_state.global_args.${param.name}`}
                            label={param.name}
                            style={{ marginBottom: 16 }}
                            validate={(val) => {
                              let error;
                              if (param.is_required && !val) {
                                error = `${param.name} 为必填项`;
                              }
                              return error;
                            }}
                          >
                            {param.select_options?.map((opt) => (
                              <MenuItem
                                key={`${opt.label}_${opt.value}`}
                                style={{ width: "100%" }}
                                value={opt.value}
                              >
                                {opt.label}{" "}
                                <span
                                  style={{
                                    fontSize: 14,
                                    marginLeft: 8,
                                    color: "#00000080",
                                  }}
                                >
                                  (value: {opt.value})
                                </span>
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                      )
                    )
                  ) : (
                    <LoadingProgress />
                  )}

                  {/* 无全局参数时友好提示 */}
                  {workflow?.spec?.params?.length !== undefined &&
                  workflow?.spec?.params?.length > 0 ? null : (
                    <Grid item xs={12}>
                      <Alert severity="info">无全局参数</Alert>
                    </Grid>
                  )}
                </Grid>
              </TitledCard>

              {workflow.metadata.uid && workflow.metadata.uid !== "" ? (
                Object.keys(workflow?.spec?.tasks)?.map((taskId) => (
                  <TitledCard
                    key={taskId}
                    title={`任务节点：${workflow?.spec?.tasks[taskId]?.name}`}
                    subtitle={workflow?.spec?.tasks[taskId]?.description}
                  >
                    <Grid container spacing={2}>
                      {/* 执行列表相关 */}
                      <Grid item xs={12}>
                        执行项
                      </Grid>

                      <Grid item xs={12}>
                        <RangeListAccordion
                          taskRangeList={allTasksRangeList[taskId] || []}
                          onChange={(newList) => {
                            let newAllTaskRangeList =
                              lodash.cloneDeep(allTasksRangeList);
                            newAllTaskRangeList[taskId] = newList;
                            console.log(
                              "newAllTaskRangeList",
                              newAllTaskRangeList
                            );
                            setAllAllTasksRangeList(newAllTaskRangeList);
                          }}
                        />
                      </Grid>

                      {/* 参数相关 */}
                      <Grid item xs={12}>
                        参数
                      </Grid>
                      {workflow?.spec?.tasks[taskId]?.params?.length === 0 && (
                        <Grid item xs={12}>
                          <Alert severity="info">该任务节点无参数输入 </Alert>
                        </Grid>
                      )}
                      {workflow?.spec?.tasks[taskId]?.params?.map((param) =>
                        param.datasource_uid === "" ? (
                          // 无数据源
                          <Grid key={`${taskId}.${param.name}`} item xs={12}>
                            <Field
                              fullWidth
                              component={TextField}
                              size="small"
                              label={param.name}
                              name={`initial_state.tasks.${taskId}.args.${param.name}`}
                              helperText={param.description}
                              validate={(val) => {
                                let error;
                                if (param.is_required && !val) {
                                  error = `${param.name} 为必填项`;
                                }
                                return error;
                              }}
                            />
                          </Grid>
                        ) : (
                          // 有数据源
                          <Grid key={`${taskId}.${param.name}`} item xs={12}>
                            <Field
                              fullWidth
                              select
                              size="small"
                              component={TextField}
                              helperText={param.description}
                              name={`initial_state.tasks.${taskId}.args.${param.name}`}
                              label={param.name}
                              validate={(val) => {
                                let error;
                                if (param.is_required && !val) {
                                  error = `${param.name} 为必填项`;
                                }
                                return error;
                              }}
                            >
                              {param.select_options?.map((opt) => (
                                <MenuItem
                                  key={`${taskId}.${param.name}.${opt.label}`}
                                  style={{ width: "100%" }}
                                  value={opt.value}
                                >
                                  {opt.label}{" "}
                                  <span
                                    style={{
                                      fontSize: 14,
                                      marginLeft: 8,
                                      color: "#00000080",
                                    }}
                                  >
                                    (value: {opt.value})
                                  </span>
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                        )
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 2 }}></Grid>
                  </TitledCard>
                ))
              ) : (
                <LoadingProgress />
              )}
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  ) : (
    <TitledCard title="实例初始化过程中，请稍后">
      <LinearProgress />
    </TitledCard>
  );
}

export default WorkflowExecForm;
