import React from 'react';
import {fieldToToggleButtonGroup} from "formik-mui";
import {ToggleButtonGroup} from "@mui/material";

function FormikToggleButtonGroup(props) {
    return (
        <ToggleButtonGroup
            exclusive
            color="primary"
            {...fieldToToggleButtonGroup(props)}
        >
        </ToggleButtonGroup>
    );
}

export default FormikToggleButtonGroup;
