import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Graph } from "@antv/x6";
import "@antv/x6-react-shape";
import { usePortal } from "@antv/x6-react-shape";
import { DagreLayout } from "@antv/layout";
import { FlowExecutionNode } from "./Node";
import genId from "../../../../../utils/uuid";
import FlowExecutionDrawer from "./Drawer";
import Box from "@mui/material/Box";
import "../../../../../components/Flow/index.less";
import { Button, Stack } from "@mui/material";
import { useWindowSize } from "../../../../../hooks/common/useWindowSize";

//自定义了一个Node节点
Graph.registerNode(
  "execution-node",
  {
    inherit: "react-shape",
    width: 240,
    height: 60, // 超过40需要在less中同步指定，线路由以此处为准 || node height
    component: <FlowExecutionNode />,
    ports: {
      groups: {
        top: {
          position: "left",
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#C2C8D5",
              strokeWidth: 1,
              fill: "#fff",
            },
          },
        },
        bottom: {
          position: "right",
          attrs: {
            circle: {
              r: 4,
              magnet: true,
              stroke: "#C2C8D5",
              strokeWidth: 1,
              fill: "#fff",
            },
          },
        },
      },
    },
  },
  true
);
// 注册到X6框架上:这里面最重要的是给框架注册了这个节点,功能上来说就是加了连接桩,本来自己写的是没有的

Graph.registerEdge(
  "execution-edge",
  {
    inherit: "edge",
    attrs: {
      line: {
        stroke: "#a3a6af",
        strokeWidth: 3,
        targetMarker: "classic",
      },
    },
  },
  true
);

// 任意字符串,是画布的唯一标识,一张画布的全部用这个id创建
// 看这个意思是全部节点可以分批同时创建
const UNIQ_GRAPH_ID = "flow-execution";

export default function FlowExecution({ graphData, executionUid, ...props }) {
  const graph = useRef();
  const [Portal, setPortal] = usePortal(UNIQ_GRAPH_ID);
  const [nodeEditDrawerVisible, setNodeEditDrawerVisible] = useState(false);
  const [editNode, setEditNode] = useState(null);

  const windowSize = useWindowSize();

  useEffect(() => {
    drawGraph();
  }, [setPortal]);

  useEffect(() => {
    updateAllNodesData(graphData);
  }, [graphData]);

  useEffect(() => {
    graph.current.resize("100%", windowSize.innerHeight - 370);
  }, [windowSize]);

  const updateAllNodesData = (graphData) => {
    graphData.forEach((cell) => {
      if (cell.shape === "execution-node") {
        const node = graph.current.getCellById(cell.id);
        const nodeData = node?.getData();
        node.replaceData({
          ...nodeData,
          ...cell.data,
        });
      }
    });
  };

  // 绘制 graph
  const drawGraph = () => {
    const newGraph = new Graph({
      container: document.getElementById("container"),
      width: "100%",
      height: windowSize.innerHeight - 370,
      snapline: true,
      mousewheel: {
        // 滚轮缩放
        enabled: true,
        // modifiers: ['ctrl', 'meta'],
      },
      panning: {
        enabled: true,
        eventTypes: ["leftMouseDown"],
      },
      selecting: {
        enabled: true,
        multiple: true,
        rubberEdge: true,
        rubberNode: true,
        modifiers: "shift",
        rubberband: true,
      },
      onPortRendered(args) {
        // 这里是所有桩子的回调,自定义样式都要走这里
        //还是这种方式没有读到args属性的原因..还是要找出这个问题
        const selectors = args.contentSelectors;
        const container = selectors && selectors.foContent;
        if (container) {
          ReactDOM.render(<div>-</div>, container);
        }
      },
      connecting: {
        router: {
          name: "manhattan",
          args: {
            padding: 10,
          },
        },
        connector: {
          name: "rounded",
          args: {
            radius: 20,
          },
        },
        anchor: "center",
        connectionPoint: "boundary",
        highlight: true,
        allowNode: false,
        allowPort: true,
        allowMulti: false,
        allowBlank: false,
        allowLoop: false,
        snap: {
          radius: 20,
        },
        createEdge() {
          return newGraph.createEdge({
            shape: "execution-edge",
            attrs: {
              line: {
                targetMarker: "classic", // 实心箭头
                strokeWidth: 2,
              },
            },
            zIndex: -1,
          });
        },
        validateConnection({ targetMagnet }) {
          return !!targetMagnet;
        },
      },
    });
    // 把nodeData的数据应用到x6里面:初始化操作.插入是要这个操作
    setPortal(newGraph);
    graph.current = newGraph;

    let cells = graphData.map((dataItem) => {
      if (dataItem.shape === "execution-node") {
        return newGraph.createNode({
          ...dataItem,
          component: <FlowExecutionNode data={dataItem} />,
          view: UNIQ_GRAPH_ID,
        });
        //关键竟然还是这个操作,要return,还有component这个参数必须有
      } else {
        return newGraph.createEdge(dataItem);
      }
    });

    newGraph.addCell(cells);

    // 自动布局
    autoLayout();
    // 节点点击事件注册
    newGraph.on("node:click", handleNodeClicked);
    // 边点击事件注册
    newGraph.on("edge:click", handleEdgeClicked);
  };

  // 节点点击事件处理函数
  const handleNodeClicked = (evt) => {
    // console.log(`node [${evt.node.id}] selected:`, evt.node);
    setEditNode(evt.node);
    setNodeEditDrawerVisible(true);
  };

  // 边点击事件处理函数
  const handleEdgeClicked = (evt) => {
    // console.log(`edge [${evt.edge.id}] selected:`, evt.edge);
  };

  // 自动布局
  const autoLayout = () => {
    let nodes = [];
    let edges = [];

    const jsonOut = graph.current.toJSON();
    jsonOut.cells.forEach((cell) => {
      if (cell.shape === "execution-node") {
        nodes.push({
          id: cell.id,
          shape: cell.shape,
          height: cell.size.height,
          width: cell.size.width,
        });
      } else if (cell.shape === "execution-edge") {
        edges.push({
          source: cell.source.cell,
          target: cell.target.cell,
        });
      }
    });

    let model = {
      nodes: nodes,
      edges: edges,
    };

    const dagreLayout = new DagreLayout({
      type: "dagre",
      rankdir: "LR",
      align: undefined, // undefined 居中对齐
      ranksep: 90,
      nodesep: 30,
      controlPoints: true,
      workerEnabled: true, // 启动 worker 加快布局计算速度
    });

    const newModel = dagreLayout.layout(model);

    let jsonIn = {
      cells: jsonOut.cells.map((cell) => {
        if (cell.shape === "execution-node") {
          let layoutCell = newModel.nodes.find((no) => no.id === cell.id);
          cell.position.x = layoutCell.x;
          cell.position.y = layoutCell.y;
        }
        return cell;
      }),
    };
    graph.current.fromJSON(jsonIn);
  };

  // 添加孤立节点
  const handleAddNode = (newNodeId) => {
    let nodeId = `task-${genId()}`;
    if (newNodeId && newNodeId !== "") {
      // 外部提供 id
      nodeId = newNodeId;
    }
    const nodeData = {
      id: nodeId,
      shape: "execution-node",
      data: {
        id: nodeId,
        name: nodeId,
        description: "new node",
        status: "wait",
        task: {},
      },
      ports: [
        {
          id: `${nodeId}-L`,
          group: "top",
        },
        {
          id: `${nodeId}-R`,
          group: "bottom",
        },
      ],
    };

    graph.current.addNode({
      ...nodeData,
      component: <FlowExecutionNode data={nodeData} />,
      view: UNIQ_GRAPH_ID,
    });
  };

  // 添加后序节点
  const handleAddNextNode = (curId) => {
    let nextId = `task-${genId()}`;
    handleAddNode(nextId);

    let newEdge = {
      id: `${curId}-${nextId}`,
      shape: "execution-edge",
      source: {
        cell: `${curId}`,
        port: `${curId}-R`,
      },
      target: {
        cell: `${nextId}`,
        port: `${nextId}-L`,
      },
      zIndex: 0,
    };

    graph.current.addCell(graph.current.createEdge(newEdge));
    autoLayout();
  };

  return (
    <Box>
      {/* 节点执行 Drawer */}
      <FlowExecutionDrawer
        executionUid={executionUid}
        taskId={editNode?.data?.id || ""}
        taskState={editNode?.data || {}}
        visible={nodeEditDrawerVisible}
        setVisible={setNodeEditDrawerVisible}
      />

      {/* FlowViewer 容器 */}
      <div className="flow-container">
        <div id="container">
          {/* Portal 多节点组件渲染性能优化 */}
          <Portal />
        </div>
      </div>
    </Box>
  );
}
