import React, { useState } from "react";
import TitledCard from "../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../utils/datetime";
import { Box, Button, Chip, Grid, Stack } from "@mui/material";
import { getExecutionStatusChip } from "../../../utils/chips";
import { Alert } from "@mui/lab";
import FieldEditDialogWithButton from "../../../components/Dialog/FieldPatchEdit/FieldEditDialogWithButton";
import { patchExecutionByUid } from "../../../api/execution";
import { useNotification } from "../../../hooks/common/useNotification";
import WorkOrderDialog from "./components/WorkOrderDialog";
import StateVarsEditDialog from "./components/StateVarsEditDialog";

function DashboardDetails({ execution, isLoading, refetchExecution }) {
  const notification = useNotification();

  const [workOrderDialogOpen, setWorkOrderDialogOpen] = useState(false);

  return (
    <>
      {/* 工单登记 Dialog */}
      <WorkOrderDialog
        execution={execution}
        open={workOrderDialogOpen}
        setOpen={setWorkOrderDialogOpen}
        refetchExecution={refetchExecution}
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            disabled={execution.state?.work_order?.is_registered}
            onClick={() => setWorkOrderDialogOpen(true)}
          >
            快捷登记工单
            {execution.state?.work_order?.is_registered &&
              `(工单已登记 -> ID: ${execution.state?.work_order?.registered_id})`}
          </Button>
        </Grid>

        {/* 基本信息 */}
        <Grid item xs={12}>
          <TitledCard
            isLoading={isLoading}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="基本信息"
            subtitle="工作流执行实例元数据信息"
          >
            <Descriptions>
              <DescriptionItem label={"名称"} span={4}>
                {execution?.metadata?.name}
              </DescriptionItem>
              <DescriptionItem label={"UID"} span={4}>
                {execution?.metadata.uid}
              </DescriptionItem>
              <DescriptionItem label={"命名空间"} span={4}>
                {execution?.metadata?.namespace}
              </DescriptionItem>
              <DescriptionItem label={"描述"} span={4}>
                {execution?.metadata?.description}
              </DescriptionItem>
              <DescriptionItem label={"创建人"} span={4}>
                {execution?.metadata?.created_by}
              </DescriptionItem>
              <DescriptionItem label={"创建时间"} span={4}>
                {formatDatetime(execution?.metadata?.created_at)}
              </DescriptionItem>
              <DescriptionItem label={"更新人"} span={4}>
                {execution?.metadata?.updated_by}
              </DescriptionItem>
              <DescriptionItem label={"更新时间"} span={4}>
                {formatDatetime(execution?.metadata?.updated_at)}
              </DescriptionItem>
              <DescriptionItem label={"当前状态"} span={4}>
                {getExecutionStatusChip(execution?.status)}
              </DescriptionItem>
              <DescriptionItem label={"工单名称"} span={4}>
                {execution?.state?.work_order?.name}
              </DescriptionItem>
              <DescriptionItem label={"工单描述"} span={4}>
                {execution?.state?.work_order?.description}
              </DescriptionItem>
            </Descriptions>
          </TitledCard>
        </Grid>

        <Grid item xs={12}>
          <TitledCard title="全局参数输入" subtitle="实例初始化全局参数输入">
            {/*参数编辑*/}
            <FieldEditDialogWithButton
              initialValues={execution?.state?.global_args}
              onConfirm={(values) => {
                patchExecutionByUid({
                  uid: execution.metadata.uid,
                  payload: {
                    "state.global_args": values,
                  },
                })
                  .then((res) => {
                    notification.success("修改成功");
                    refetchExecution();
                  })
                  .catch((err) => {
                    notification.error("修改失败", err.toString());
                  });
              }}
            />

            <Stack direction="row" spacing={2}>
              {Object.keys(execution?.state?.global_args).length > 0 ? (
                Object.keys(execution?.state?.global_args).map((argKey) => (
                  <Chip
                    key={argKey}
                    color="info"
                    label={`${argKey}: ${execution?.state?.global_args[argKey]}`}
                  />
                ))
              ) : (
                <Alert severity="info">无全局参数输入</Alert>
              )}
            </Stack>
          </TitledCard>
        </Grid>

        {/* 变量池 */}
        <Grid item xs={12}>
          <TitledCard
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="实例变量池"
            subtitle="工作流实例各任务节点共享变量"
          >
            <StateVarsEditDialog
              execution={execution}
              refetchExecution={refetchExecution}
            />
            <Box
              sx={{
                background: "#eef0f1",
                px: 2,
                py: 1,
                borderRadius: 1,
                border: "1px solid #ccc",
              }}
            >
              <pre style={{ margin: 0 }}>
                {JSON.stringify(execution?.state?.vars, "", "  ")}
              </pre>
            </Box>
          </TitledCard>
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardDetails;
