import React, { useEffect, useState } from "react";
import { Button, Paper, Stack } from "@mui/material";
import { executionStatus, taskStatus } from "../../../../constants/execution";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  LoadingOutlined,
  StopFilled,
} from "@ant-design/icons";
import { formatDatetime } from "../../../../utils/datetime";
import { Popconfirm, Progress } from "@douyinfe/semi-ui";
import { ExecutionService } from "../../../../services/execution";
import { useNotification } from "../../../../hooks/common/useNotification";

function ExecutionStatusBar({ execution, ...props }) {
  const [progress, setProgress] = useState(0);
  const notification = useNotification();

  useEffect(() => {
    if (
      execution &&
      execution.status &&
      execution.status === executionStatus.Finished
    ) {
      setProgress(100);
    } else {
      if (execution && execution.state && execution.state.tasks) {
        const allTaskIds = Object.keys(execution.state.tasks);
        const doneTasks = allTaskIds.filter(
          (taskId) =>
            execution.state.tasks[taskId].status === taskStatus.Succeeded
        );
        setProgress(Math.round((doneTasks.length / allTaskIds.length) * 100));
      }
    }
  }, [execution]);

  const handleConfirmTerminateExecution = (uid) => {
    ExecutionService.terminateByUid({ uid }).then((res) => {
      const envelope = res.data;
      if (envelope.code === 0) {
        notification.success("工作流实例终止执行", `UID: ${uid}`);
      } else {
        notification.warning("工作流实例终止执行失败", `UID: ${envelope.msg}`);
      }
    });
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Stack direction="row" spacing={2}>
          <span
            style={{
              margin: "0 16px",
              lineHeight: "100px",
              width: "140px",
            }}
          >
            {execution?.status === executionStatus.Executing && (
              <span style={{ color: "#00628c", fontWeight: "bold" }}>
                <LoadingOutlined style={{ fontSize: 24, marginRight: 16 }} />
                <span>正在执行</span>
              </span>
            )}
            {execution?.status === executionStatus.Finished && (
              <span style={{ color: "#008c62", fontWeight: "bold" }}>
                <CheckCircleFilled style={{ fontSize: 24, marginRight: 16 }} />
                <span>执行完成</span>
              </span>
            )}
            {execution?.status === executionStatus.Failed && (
              <span style={{ color: "#bb3b3b", fontWeight: "bold" }}>
                <CloseCircleFilled style={{ fontSize: 24, marginRight: 16 }} />
                <span>执行异常</span>
              </span>
            )}
            {execution?.status === executionStatus.Terminated && (
              <span style={{ color: "#e19e1b", fontWeight: "bold" }}>
                <StopFilled style={{ fontSize: 24, marginRight: 16 }} />
                <span>执行终止</span>
              </span>
            )}
          </span>

          <span>
            <div>名称：{execution?.metadata?.name}</div>
            <div
              style={{
                fontSize: 14,
                color: "rgba(0,0,0,0.6)",
              }}
            >
              描述：{execution?.metadata?.description}
            </div>

            <div
              style={{
                fontSize: 14,
                color: "rgba(0,0,0,0.6)",
              }}
            >
              当前进度：{" "}
              <Progress
                percent={progress}
                stroke={[
                  { percent: 0, color: "#F87E51" },
                  { percent: 50, color: "#25AEFF" },
                  { percent: 100, color: "#008C62" },
                ]}
                strokeGradient={true}
                showInfo
                size="large"
                aria-label="file download speed"
              />
            </div>

            <div
              style={{
                fontSize: 14,
                color: "rgba(0,0,0,0.6)",
              }}
            >
              {`${execution?.metadata?.created_by} 创建于 ${formatDatetime(
                execution?.metadata?.created_at
              )}`}
            </div>
          </span>

          <Stack direction="column" alignItems="center" justifyContent="center">
            <Popconfirm
              title="确定是否要终止此实例？"
              content="确定是否要终止此实例？终止后不可继续执行"
              onConfirm={() =>
                handleConfirmTerminateExecution(execution?.metadata.uid)
              }
              // onCancel={onCancel}
            >
              <Button color="warning">终止当前实例</Button>
            </Popconfirm>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

export default ExecutionStatusBar;
