export const workflowEditor = {
  // 工作流编辑 node 节点高度
  nodeHeight: 183,
  // 工作流编辑 node 节点宽度
  nodeWidth: 240,
};

export const workflowTaskRangePolicy = {
  // 并行执行
  Parallel: "Parallel",
  // 滚动执行
  Rolling: "Rolling",
};
