export const gridLocaleTextZh = {
  // Root
  noRowsLabel: "无数据",
  noResultsOverlayLabel: "无结果",
  errorOverlayDefaultLabel: "发生错误",

  // Density selector toolbar button text
  toolbarDensity: "展示密集度",
  toolbarDensityLabel: "展示密集度",
  toolbarDensityCompact: "紧凑",
  toolbarDensityStandard: "标准",
  toolbarDensityComfortable: "宽松",

  // Columns selector toolbar button text
  toolbarColumns: "字段",
  toolbarColumnsLabel: "选择字段",

  // Filters toolbar button text
  toolbarFilters: "过滤数据",
  toolbarFiltersLabel: "显示过滤器",
  toolbarFiltersTooltipHide: "隐藏过滤器",
  toolbarFiltersTooltipShow: "显示过滤器",
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} 个可用过滤器` : `${count} 个可用过滤器`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: "快速搜索...",
  toolbarQuickFilterLabel: "快速搜索",
  toolbarQuickFilterDeleteIconLabel: "清空",

  // Export selector toolbar button text
  toolbarExport: "导出报表",
  toolbarExportLabel: "导出报表",
  toolbarExportCSV: "导出报表 (CSV)",
  toolbarExportPrint: "打印",
  toolbarExportExcel: "导出报表 (Excel)",

  // Columns panel text
  columnsPanelTextFieldLabel: "查看字段",
  columnsPanelTextFieldPlaceholder: "字段标题",
  columnsPanelDragIconLabel: "拖拽字段",
  columnsPanelShowAllButton: "展示全部",
  columnsPanelHideAllButton: "隐藏全部",

  // Filter panel text
  filterPanelAddFilter: "添加过滤器",
  filterPanelDeleteIconLabel: "删除",
  filterPanelLinkOperator: "逻辑运算",
  filterPanelOperators: "操作", // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: "且",
  filterPanelOperatorOr: "或",
  filterPanelColumns: "字段",
  filterPanelInputLabel: "值",
  filterPanelInputPlaceholder: "过滤值",

  // Filter operators text
  filterOperatorContains: "包含",
  filterOperatorEquals: "等于",
  filterOperatorStartsWith: "前缀",
  filterOperatorEndsWith: "后缀",
  filterOperatorIs: "是",
  filterOperatorNot: "不是",
  filterOperatorAfter: "is after", // TODO 斟酌翻译
  filterOperatorOnOrAfter: "is on or after", // TODO 斟酌翻译
  filterOperatorBefore: "is before", // TODO 斟酌翻译
  filterOperatorOnOrBefore: "is on or before", // TODO 斟酌翻译
  filterOperatorIsEmpty: "为空",
  filterOperatorIsNotEmpty: "不为空",
  filterOperatorIsAnyOf: "任意",

  // Filter values text
  filterValueAny: "任意",
  filterValueTrue: "是",
  filterValueFalse: "否",

  // Column menu text
  columnMenuLabel: "菜单",
  columnMenuShowColumns: "显示字段",
  columnMenuFilter: "过滤器",
  columnMenuHideColumn: "隐藏",
  columnMenuUnsort: "无序",
  columnMenuSortAsc: "升序",
  columnMenuSortDesc: "降序",

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} 个可用过滤器` : `${count} 个可用过滤器`,
  columnHeaderFiltersLabel: "显示过滤器",
  columnHeaderSortIconLabel: "排序",

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} 个字段已选择`
      : `${count.toLocaleString()} 个字段已选择`,

  // Total row amount footer text
  footerTotalRows: "总数据量:",

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} of ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: "框选器",
  checkboxSelectionSelectAllRows: "选择所有字段",
  checkboxSelectionUnselectAllRows: "取消选择所有字段",
  checkboxSelectionSelectRow: "选择数据",
  checkboxSelectionUnselectRow: "取消选择数据",

  // Boolean cell text
  booleanCellTrueLabel: "是",
  booleanCellFalseLabel: "否",

  // Actions cell more text
  actionsCellMore: "更多",

  // Column pinning text
  pinToLeft: "固定至左边",
  pinToRight: "固定至右边",
  unpin: "取消固定",

  // Tree Data
  treeDataGroupingHeaderName: "组",
  treeDataExpand: "展开子数据",
  treeDataCollapse: "收起子数据",

  // Grouping columns
  groupingColumnHeaderName: "聚合",
  groupColumn: (name) => `以 ${name} 聚合`,
  unGroupColumn: (name) => `取消以 ${name} 聚合`,

  // Master/detail
  detailPanelToggle: "详情面板开关",
  expandDetailPanel: "展开",
  collapseDetailPanel: "收起",

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: "数据重排序",
};
