import React, { useContext, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getWorkflowByUid,
  listWorkflows,
  createWorkflow,
  deleteWorkflowByUid,
  patchWorkflowByUid,
  saveWorkflowByUid,
} from "../../api/workflow";
import { AppContext } from "../../App";
import { localGetCurrentNamespace } from "../../localstorage/namespace";

export const useCreateWorkflow = () => {
  return useMutation(createWorkflow);
};

export const useListWorkflows = (listOptions = {}) => {
  return useQuery(
    ["workflows", JSON.stringify(listOptions)],
    () => listWorkflows(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
    }
  );
};

export const useGetWorkflowByUid = (uid, loadOptions = false, auto = true) => {
  return useQuery(
    ["workflows", uid],
    () => getWorkflowByUid(uid, loadOptions),
    {
      placeholderData: {
        metadata: {
          uid: "",
        },
        spec: {},
      },
      enabled: auto,
    }
  );
};

export const usePatchWorkflowByUid = () => {
  return useMutation(patchWorkflowByUid);
};

export const useSaveWorkflowByUid = () => {
  return useMutation(saveWorkflowByUid);
};

export const useDeleteWorkflowByUid = () => {
  return useMutation(deleteWorkflowByUid);
};
