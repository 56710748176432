import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { getAuthUserName } from "../../../../../../../utils/cookie";

function TaskExecutionLogs({ taskExecutionUid, show }) {
  const ws = useRef(null);

  const [podLogs, setPodLogs] = useState("");

  useEffect(() => {
    if (taskExecutionUid && taskExecutionUid !== "" && show) {
      // websocket 注意，要用 ingress 地址，客户端从外部请求需要访问 ingress 地址，nginx 配置 proxy_pass 比较麻烦
      ws.current = new WebSocket(
        `wss://${process.env.REACT_APP_OPS_SERVER}/api/v1/stream/task-executions/${taskExecutionUid}/logs`
      );
      //连接错误时触发
      ws.current.onerror = function (err) {
        console.log("ws: task-execution logs watch ERROR:", err);
      };
      //连接打开时触发
      ws.current.onopen = function (evt) {
        console.log("ws: task-execution logs watch started~");
        ws.current.send(
          `task-execution logs watch started~, user: ${getAuthUserName()}`
        );
      };
      //接收到消息时触发
      ws.current.onmessage = function (evt) {
        const logs = JSON.parse(evt.data);
        setPodLogs(logs);
      };
      //连接关闭时触发
      ws.current.onclose = function (evt) {
        console.log("ws: task-execution logs watch stopped~");
      };
    }
  }, [taskExecutionUid, show]);

  useEffect(() => {
    console.log("stop task-execution logs polling");
    return () => {
      ws.current?.close(
        1000,
        `task-execution logs watch stopped~, user: ${getAuthUserName()}`
      );
    };
  }, []);

  return (
    <Box
      sx={{
        background: "#eef0f1",
        px: 2,
        py: 1,
        borderRadius: 1,
        border: "1px solid #ccc",
        maxHeight: "600px",
        overflow: "auto",
      }}
    >
      <pre style={{ margin: 0 }}>{podLogs}</pre>
    </Box>
  );
}

export default TaskExecutionLogs;
