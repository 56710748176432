import { configureStore } from "@reduxjs/toolkit";

import siteSlice from "./modules/site";
import actionSlice from "./modules/action";
import actionflowSlice from "./modules/actionflow";
import datasourceSlice from "./modules/datasource";
import executionSlice from "./modules/execution";
import taskExecutionSlice from "./modules/task_execution";
import triggerSlice from "./modules/trigger";
import userSlice from "./modules/user";
import workflowSlice from "./modules/workflow";

export const store = configureStore({
  reducer: {
    site: siteSlice,
    action: actionSlice,
    actionflow: actionflowSlice,
    datasource: datasourceSlice,
    execution: executionSlice,
    taskExecution: taskExecutionSlice,
    trigger: triggerSlice,
    user: userSlice,
    workflow: workflowSlice,
  },
});
