import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-idle_fingers";
import {
  patchExecutionByUid,
  patchExecutionStateVarsByUid,
} from "../../../../api/execution";
import { useNotification } from "../../../../hooks/common/useNotification";

function StateVarsEditDialog({ execution, refetchExecution, buttonProps }) {
  const notification = useNotification();

  const [open, setOpen] = useState(false);
  const [editingVarsJSON, setEditingVarsJSON] = useState("");

  useEffect(() => {
    if (open && execution.metadata?.uid) {
      console.log(execution);
      setEditingVarsJSON(JSON.stringify(execution.state?.vars, "", "  "));
    }
  }, [open, execution]);

  const handleConfirm = () => {
    let varsPatch = {};
    try {
      varsPatch = JSON.parse(editingVarsJSON);
    } catch (e) {
      alert(`无效的 JSON 格式: ${e.toString()}`);
      return;
    }

    patchExecutionStateVarsByUid({
      uid: execution.metadata.uid,
      payload: varsPatch,
    })
      .then((res) => {
        notification.success("修改实例变量成功");
        setOpen(false);
        refetchExecution();
      })
      .catch((err) => {
        notification.error("修改实例变量失败", err.toString());
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="text"
        startIcon={<EditIcon />}
        {...buttonProps}
      >
        修改
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="1000px">
        <DialogTitle>实例执行变量修改</DialogTitle>

        <DialogContent sx={{ width: "1000px", maxWidth: "1000px" }}>
          <Stack spacing={2} sx={{ py: 2 }}>
            <AceEditor
              mode="json"
              theme="idle_fingers"
              width="950px"
              minLines={20}
              maxLines={60}
              value={editingVarsJSON || ""}
              onChange={(val) => setEditingVarsJSON(val)}
              name="http-getter-script"
              fontSize={14}
              editorProps={{ $blockScrolling: true }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="text">
            取消
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            确认修改
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default StateVarsEditDialog;
