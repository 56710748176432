export const localSaveAuthUser = (authUser) => {
  localStorage.setItem("authUser", JSON.stringify(authUser));
};
export const localGetAuthUser = () => {
  try {
    return JSON.parse(localStorage.getItem("authUser"));
  } catch (err) {
    return null;
  }
};
export const localRemoveAuthUser = () => {
  localStorage.removeItem("authUser");
};
