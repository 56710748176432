import React from "react";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";

function CustomToolbar(props) {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter />
      <Box sx={{ position: "absolute", right: 16 }}>
        <GridToolbarFilterButton variant="text" size="small" />
        <GridToolbarExport variant="text" size="small" />
      </Box>
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
