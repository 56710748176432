import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

function ButtonWithConfirmDialog({
  confirmTitle = "确认",
  confirmText = "是否确认",
  startIcon = null,
  onConfirm,
  ButtonProps,
  ...props
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleOpen} {...ButtonProps}>
        {props.children || "二次确认"}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="1000">
        <DialogTitle>{confirmTitle}</DialogTitle>

        <DialogContent>{confirmText}</DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="text">
            取消
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ButtonWithConfirmDialog;
