import React, { useEffect, useState } from "react";
import TopActionsArea from "../../../components/TopBar/TopActionsArea";
import {
  Box,
  Button,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import TitledCard from "../../../components/TitledCard/TitledCard";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  DescriptionItem,
  Descriptions,
} from "../../../components/Descriptions/Descriptions";
import { formatDatetime } from "../../../utils/datetime";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ContentArea from "../../../components/ContentArea/ContentArea";
import { useNotification } from "../../../hooks/common/useNotification";
import { useSearchParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import {
  useDebugDatasource,
  useGetDatasourceByUid,
  useSaveDatasourceByUid,
} from "../../../hooks/query/datasource";
import AceEditor from "react-ace";
import Base64 from "base-64";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/theme-idle_fingers";
import "ace-builds/src-noconflict/theme-tomorrow";
import FieldEditDialogWithButton from "../../../components/Dialog/FieldPatchEdit/FieldEditDialogWithButton";
import { patchActionflowByUid } from "../../../api/actionflow";
import { patchDatasourceByUid } from "../../../api/datasource";
import LinkIcon from "@mui/icons-material/Link";
import copy from "copy-to-clipboard";

function DatasourceEdit(props) {
  const notification = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: datasource,
    isFetching,
    refetch,
  } = useGetDatasourceByUid(searchParams.get("uid"));
  const { mutate: debugDatasource } = useDebugDatasource();
  const { mutate: saveDatasourceByUid } = useSaveDatasourceByUid();

  const [editingDatasource, setEditingDatasource] = useState({
    metadata: {
      uid: "",
      name: "",
      description: "",
    },
    spec: {
      source_type: "",
      format_type: "",
      constraint_type: "",
    },
    data: {
      raw: [],
    },
  });

  const [httpGetter, setHttpGetter] = useState("");
  const [customData, setCustomData] = useState("");
  const [debugPreview, setDebugPreview] = useState("");

  useEffect(() => {
    if (datasource.metadata.uid && datasource.metadata.uid !== "") {
      setEditingDatasource(cloneDeep(datasource));
      setHttpGetter(JSON.stringify(datasource.spec.http_getter, "", "  "));
      setCustomData(datasource.data.string || "");
    }
  }, [datasource]);

  const handleSaveDatasource = () => {
    let newDatasource = cloneDeep(editingDatasource);

    // http-getter edit save
    let newHttpGetter;
    try {
      newHttpGetter = JSON.parse(httpGetter);
    } catch (err) {
      notification.warning("无法解析 HttpGetter JSON 配置", err.toString());
      return;
    }
    newDatasource.spec.http_getter = newHttpGetter;

    // custom data edit save
    newDatasource.data.string = customData;

    saveDatasourceByUid(
      { uid: newDatasource.metadata.uid, payload: newDatasource },
      {
        onSuccess: () => {
          notification.success("数据源配置保存成功");
          refetch();
        },
      }
    );
  };

  const handleClickDebug = () => {
    setDebugPreview("");
    // http-getter edit save
    let debugHttpGetter;
    try {
      debugHttpGetter = JSON.parse(httpGetter);
    } catch (err) {
      notification.warning(
        "无法解析 HttpGetter JSON Debug 配置",
        err.toString()
      );
      return;
    }

    const debugPayload = {
      http_getter: debugHttpGetter,
      format_type: editingDatasource.spec.format_type,
      constraint_type: editingDatasource.spec.constraint_type,
    };

    debugDatasource(debugPayload, {
      onSuccess: (data) => {
        notification.success("调试执行完成", "调试结果已同步至数据预览区");
        setDebugPreview(Base64.decode(data));
      },
    });
  };

  return (
    <ContentArea>
      <TopActionsArea>
        <Button onClick={handleSaveDatasource}>保存</Button>
        <Button
          startIcon={<LinkIcon />}
          variant="outlined"
          onClick={() => {
            const dataUrl = `${process.env.REACT_APP_OPS_SERVER_BASEURL}/api/v1/public/datasources/${editingDatasource.metadata.uid}/data`;
            copy(dataUrl);
            notification.info("数据源链接已复制");
          }}
        >
          数据链接
        </Button>
      </TopActionsArea>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitledCard
            isLoading={isFetching}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="数据源基本信息"
            subtitle="数据源定义元数据信息"
          >
            <Descriptions>
              <DescriptionItem label={"名称"} span={6}>
                <Stack spacing={1} direction="row">
                  <span>{datasource?.metadata?.name}</span>
                  <FieldEditDialogWithButton
                    initialValues={{ name: datasource?.metadata?.name }}
                    onConfirm={(values) => {
                      if (values.name !== "") {
                        patchDatasourceByUid({
                          uid: datasource?.metadata?.uid,
                          payload: { metadata: { name: values.name } },
                        })
                          .then((res) => {
                            refetch();
                            notification.success("修改成功");
                          })
                          .catch((err) => {
                            notification.error("修改失败", err.toString());
                          });
                      }
                    }}
                  />
                </Stack>
              </DescriptionItem>
              <DescriptionItem label={"UID"} span={6}>
                {datasource?.metadata.uid}
              </DescriptionItem>
              <DescriptionItem label={"命名空间"} span={6}>
                {datasource?.metadata?.namespace}
              </DescriptionItem>
              <DescriptionItem label={"描述"} span={6}>
                {datasource?.metadata?.description}
              </DescriptionItem>
              <DescriptionItem label={"创建人"} span={6}>
                {datasource?.metadata?.created_by}
              </DescriptionItem>
              <DescriptionItem label={"创建时间"} span={6}>
                {formatDatetime(datasource?.metadata?.created_at)}
              </DescriptionItem>
              <DescriptionItem label={"更新人"} span={6}>
                {datasource?.metadata?.updated_by}
              </DescriptionItem>
              <DescriptionItem label={"更新时间"} span={6}>
                {formatDatetime(datasource?.metadata?.updated_at)}
              </DescriptionItem>
            </Descriptions>
          </TitledCard>
        </Grid>

        <Grid item xs={12}>
          <TitledCard
            isLoading={isFetching}
            icon={<SettingsApplicationsIcon sx={{ fontSize: 16 }} />}
            title="数据源配置"
            subtitle="数据源配置"
          >
            <Stack spacing={2}>
              <Stack direction={"row"} spacing={2}>
                <Box sx={{ fontSize: 14, lineHeight: "40px" }}>数据来源</Box>
                <ToggleButtonGroup
                  exclusive
                  color="primary"
                  size="small"
                  value={editingDatasource.spec.source_type}
                  onChange={(e, val) => {
                    if (val !== null) {
                      let newDatasource = cloneDeep(editingDatasource);
                      newDatasource.spec.source_type = val;
                      setEditingDatasource(newDatasource);
                    }
                  }}
                >
                  <ToggleButton value="HttpGet">HTTP 数据源</ToggleButton>
                  <ToggleButton value="Custom">自定义数据源</ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              <Stack direction={"row"} spacing={2}>
                <Box sx={{ fontSize: 14, lineHeight: "40px" }}>数据格式</Box>
                <ToggleButtonGroup
                  exclusive
                  color="primary"
                  size="small"
                  value={editingDatasource.spec.format_type}
                  onChange={(e, val) => {
                    if (val !== null) {
                      let newDatasource = cloneDeep(editingDatasource);
                      newDatasource.spec.format_type = val;
                      setEditingDatasource(newDatasource);
                    }
                  }}
                >
                  <ToggleButton value="MapList">
                    映射列表 (MapList)
                  </ToggleButton>
                  <ToggleButton value="List">列表 (List)</ToggleButton>
                  <ToggleButton value="Map">映射 (Map)</ToggleButton>
                  <ToggleButton value="Text">纯文本 (PlainText)</ToggleButton>
                </ToggleButtonGroup>
              </Stack>

              {(editingDatasource.spec.format_type === "MapList" ||
                editingDatasource.spec.format_type === "List") && (
                <Stack direction={"row"} spacing={2}>
                  <Box sx={{ fontSize: 14, lineHeight: "40px" }}>约束类型</Box>
                  <ToggleButtonGroup
                    exclusive
                    color="primary"
                    size="small"
                    value={editingDatasource.spec.constraint_type}
                    onChange={(e, val) => {
                      if (val !== null) {
                        let newDatasource = cloneDeep(editingDatasource);
                        newDatasource.spec.constraint_type = val;
                        setEditingDatasource(newDatasource);
                      }
                    }}
                  >
                    <ToggleButton value="">无</ToggleButton>
                    <ToggleButton value="SelectOptionList">
                      下拉选项列表
                    </ToggleButton>
                    <ToggleButton value="TaskRangeList">
                      节点执行列表
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              )}
            </Stack>

            <Box sx={{ mt: 2 }}>
              {editingDatasource.spec.source_type === "HttpGet" && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button sx={{ mb: 1 }} onClick={handleClickDebug}>
                      预览/调试
                    </Button>
                    <AceEditor
                      mode="json"
                      theme="idle_fingers"
                      width="100%"
                      minLines={20}
                      maxLines={20}
                      value={httpGetter}
                      onChange={(val) => {
                        setHttpGetter(val);
                      }}
                      name="http-getter-script"
                      fontSize={14}
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {/* data preview and custom data editor */}
                    <Box sx={{ fontSize: 16, fontWeight: "bold", my: 1 }}>
                      数据预览
                    </Box>
                    <AceEditor
                      mode="json"
                      theme="tomorrow"
                      width="100%"
                      minLines={20}
                      maxLines={20}
                      readOnly={true}
                      value={debugPreview}
                      name="debug-preview"
                      fontSize={14}
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Grid>
                </Grid>
              )}
              {editingDatasource.spec.source_type === "Custom" && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AceEditor
                      mode="json"
                      theme="tomorrow"
                      width="100%"
                      minLines={10}
                      maxLines={30}
                      value={customData}
                      onChange={(val) => {
                        setCustomData(val);
                      }}
                      name="custom-data"
                      fontSize={14}
                      editorProps={{ $blockScrolling: true }}
                    />
                  </Grid>
                </Grid>
              )}
            </Box>
          </TitledCard>
        </Grid>
      </Grid>
    </ContentArea>
  );
}

export default DatasourceEdit;
