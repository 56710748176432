import React from "react";
import { Chip, Divider, Stack } from "@mui/material";

export function FlowEditorNode({ node, handleAddNextNode, ...props }) {
  const nodeData = node.data; // 重要: data 要通过 getData() 进行获取，否则数据变化无法重绘

  return (
    // node height
    <div className={`node primary`}>
      <span className="info">
        <div style={{ fontSize: 12, color: "rgba(0,0,0,0.5)" }}>
          {nodeData.id || "[Task ID]"}
        </div>
        <div className="title">
          <strong>{nodeData.name}</strong>
        </div>
        <div className="description" style={{ color: "rgba(0,0,0,0.5)" }}>
          {nodeData.description || "(待添加描述)"}
        </div>
        <Divider style={{ margin: "8px 0" }} />
        <Stack className="option-tag-group" spacing={1}>
          <div>
            执行方式:{" "}
            {nodeData.options?.auto?.enabled ? (
              <Chip color="info" size="small" label="自动执行" />
            ) : (
              <Chip color="warning" size="small" label="手动确认" />
            )}
          </div>
          <div>
            绑定 Actionflow:{" "}
            {nodeData.actionflow_uid && nodeData.actionflow_uid !== "" ? (
              <Chip color="info" size="small" label="是" />
            ) : (
              <Chip size="small" label="否" />
            )}
          </div>
          <div>
            绑定执行列表:{" "}
            {nodeData.range?.datasource_uid !== "" ? (
              <Chip color="info" size="small" label="已绑定" />
            ) : (
              <Chip size="small" label="默认单执行项" />
            )}
          </div>
        </Stack>
      </span>
    </div>
  );
}
