import React, { useContext, useRef } from "react";
import { Box, Grid, LinearProgress } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import ConfirmDialog from "../../../../components/Dialog/Confirm";
import { useNotification } from "../../../../hooks/common/useNotification";
import { useNavigate } from "react-router-dom";
import { useCreateDatasource } from "../../../../hooks/query/datasource";
import { getAuthUserName } from "../../../../utils/cookie";
import { useCreateExecutionWorkOrderByUid } from "../../../../hooks/query/execution";
import { AppContext } from "../../../../App";

function WorkOrderDialog({ execution, open, setOpen, refetchExecution }) {
  const { authUser } = useContext(AppContext);

  const notification = useNotification();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const { mutate: createWorkOrder } = useCreateExecutionWorkOrderByUid();

  return (
    <ConfirmDialog
      open={open}
      title="登记工单"
      onClose={() => setOpen(false)}
      onConfirm={() => formRef.current.submitForm()}
    >
      <Box sx={{ width: 600 }}>
        <Formik
          innerRef={formRef}
          initialValues={{
            name: execution.state?.work_order?.name,
            description: execution.state?.work_order?.description,
            assigned_to: `${authUser.metadata.fullname}(${getAuthUserName()})`,
            estimated_hours: 0,
          }}
          onSubmit={(values, { setSubmitting }) => {
            // 时间转字符串，SAPM API 要求
            values.estimated_hours = values.estimated_hours.toString();

            createWorkOrder(
              {
                uid: execution.metadata?.uid,
                payload: values,
              },
              {
                onSuccess: (data) => {
                  setSubmitting(false);
                  notification.success("工单登记成功");
                  refetchExecution();
                  setOpen(false);
                },
                onError: (err) => {
                  setSubmitting(false);
                },
              }
            );
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    disabled
                    component={TextField}
                    label="工单名称"
                    name="name"
                    size="small"
                    validate={(val) => {
                      let error;
                      if (!val) {
                        error = `工单名称为必填项`;
                      }
                      return error;
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label="工单描述"
                    name="description"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    disabled
                    component={TextField}
                    label="工单指派目标"
                    name="assigned_to"
                    size="small"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label="登记工时 (单位:人天)"
                    name="estimated_hours"
                    size="small"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    validate={(val) => {
                      let error;
                      if (val < 0) {
                        error = `工单工时须≥0(人天)`;
                      }
                      return error;
                    }}
                  />
                </Grid>

                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </ConfirmDialog>
  );
}

export default WorkOrderDialog;
