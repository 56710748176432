import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import lodash, { toNumber } from "lodash";
import { Alert } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";

function FieldEditDialogWithButton({
  title = "修改",
  buttonName = "修改",
  onConfirm,
  buttonProps,
  initialValues = {},
  initialFields = [], // { label: "", key: "", value: "" }
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [editingFields, setEditingFields] = useState(initialFields);

  useEffect(() => {
    // 只有 initialValues 时，进行转换
    if (Object.keys(initialValues).length > 0 && initialFields.length === 0) {
      let initEditingFields = Object.keys(initialValues).map((k) => {
        return { label: k, key: k, value: initialValues[k] };
      });

      setEditingFields(initEditingFields);
    }
  }, [initialValues]);

  const onButtonClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onConfirmEdit = () => {
    let values = {};
    editingFields.forEach((f) => {
      values[f.key] = f.value;
    });
    console.log("confirmed values:", values);
    onConfirm(values);
    setOpen(false);
  };

  return (
    <Box sx={{ marginBottom: "8px" }}>
      <Button
        onClick={onButtonClick}
        variant="text"
        startIcon={<EditIcon />}
        {...buttonProps}
      >
        {buttonName}
      </Button>

      <Dialog open={open} onClose={onClose} maxWidth="600px">
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <Stack spacing={2} sx={{ py: 2 }}>
            {editingFields.length < 1 && (
              <Alert severity="info" sx={{ width: "600px" }}>
                暂无可修改的内容
              </Alert>
            )}
            {editingFields.map((field) => {
              switch (typeof field.value) {
                case "string":
                  return (
                    <TextField
                      key={field.key}
                      label={field.label}
                      size="small"
                      value={field.value}
                      onChange={(e) => {
                        setEditingFields(
                          editingFields.map((f) => {
                            if (f.key === field.key) {
                              f.value = e.target.value;
                            }
                            return f;
                          })
                        );
                      }}
                      sx={{ width: "600px" }}
                    />
                  );
                case "number":
                  return (
                    <TextField
                      key={field.key}
                      label={field.label}
                      type="number"
                      size="small"
                      value={field.value}
                      onChange={(e) => {
                        setEditingFields(
                          editingFields.map((f) => {
                            if (f.key === field.key) {
                              f.value = toNumber(e.target.value);
                            }
                            return f;
                          })
                        );
                      }}
                      sx={{ width: "600px" }}
                    />
                  );
                case "boolean":
                  return (
                    <Box
                      key={field.key}
                      sx={{ width: "600px", textAlign: "left" }}
                    >
                      <FormControlLabel
                        label={field.label}
                        checked={field.value}
                        control={<Switch color="primary" />}
                        onChange={(e, checked) => {
                          setEditingFields(
                            editingFields.map((f) => {
                              if (f.key === field.key) {
                                f.value = checked;
                              }
                              return f;
                            })
                          );
                        }}
                        labelPlacement="start"
                      />
                    </Box>
                  );
                default:
                  return null;
              }
            })}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} variant="text">
            取消
          </Button>
          <Button
            onClick={onConfirmEdit}
            autoFocus
            disabled={editingFields.length < 1}
          >
            确认修改
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default FieldEditDialogWithButton;
