import React, { useState } from "react";
import ContentArea from "../../components/ContentArea/ContentArea";
import { Button, Grid } from "@mui/material";
import TriggerTable from "./Table";
import TopActionsArea from "../../components/TopBar/TopActionsArea";
import TriggerCreateDialog from "./Create";

function Trigger(props) {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <ContentArea>
      <TopActionsArea>
        <Button onClick={() => setCreateDialogOpen(true)}>创建</Button>
      </TopActionsArea>

      <Grid container spacing={2}>
        <Grid sx={{ mb: 3 }} item xs={12}>
          <TriggerTable />
        </Grid>
      </Grid>

      <TriggerCreateDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
      />
    </ContentArea>
  );
}

export default Trigger;
