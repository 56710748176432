import React from "react";
import { Field, Form } from "formik";
import { Grid, ToggleButton } from "@mui/material";
import { TextField } from "formik-mui";
import TitledCard from "../../../../TitledCard/TitledCard";
import FormikToggleButtonGroup from "../../../../Formik/Toggle";

function FlowEditorDrawerForm({ formRef, node, ...props }) {
  return (
    <Form>
      <TitledCard title="基本信息" subtitle="简洁有效的内容将能传达更多的信息">
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={6}>
            <Field
              fullWidth
              component={TextField}
              label="任务节点名称"
              name="name"
              size="small"
              validate={(val) => {
                let error;
                if (!val) {
                  error = "任务节点名称不可为空";
                }
                return error;
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Field
              fullWidth
              disabled
              component={TextField}
              label="ID/Ref"
              name="id"
              size="small"
              validate={(val) => {
                let error;
                if (!val) {
                  error = "任务节点 ID/Ref 不可为空";
                }
                return error;
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              fullWidth
              component={TextField}
              label="任务节点描述"
              size="small"
              name="description"
            />
          </Grid>
        </Grid>
      </TitledCard>

      <TitledCard title="基本执行选项" subtitle="任务节点执行基本选项">
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item xs={12}>
            <span style={{ fontSize: 14, marginRight: 8 }}>执行类型</span>
            <Field
              component={FormikToggleButtonGroup}
              type="checkbox"
              name="options.auto.enabled"
              size="small"
            >
              <ToggleButton value={false}>
                手动确认（适用于人工操作任务）
              </ToggleButton>
              <ToggleButton value={true}>
                自动执行（适用于绑定 Action 场景）
              </ToggleButton>
            </Field>
          </Grid>
        </Grid>
      </TitledCard>
    </Form>
  );
}

export default FlowEditorDrawerForm;
