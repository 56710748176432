import React, { useRef } from "react";
import ConfirmDialog from "../../../components/Dialog/Confirm";
import { Box, Grid, LinearProgress } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useNotification } from "../../../hooks/common/useNotification";
import { useNavigate } from "react-router-dom";
import { useCreateActionflow } from "../../../hooks/query/actionflow";

function ActionflowCreateDialog({ open, setOpen }) {
  const notification = useNotification();
  const navigate = useNavigate();
  const formRef = useRef(null);

  const { mutate: createActionflow } = useCreateActionflow();

  return (
    <ConfirmDialog
      open={open}
      title="创建 Actionflow"
      onClose={() => setOpen(false)}
      onConfirm={() => formRef.current.submitForm()}
    >
      <Box sx={{ width: 600 }}>
        <Formik
          innerRef={formRef}
          initialValues={{
            metadata: {
              name: "",
              description: "",
            },
          }}
          onSubmit={(values, { setSubmitting }) => {
            let newActionflow = {
              metadata: {
                name: values.metadata.name,
                description: values.metadata.description,
              },
            };

            console.log("newActionflow:", newActionflow);

            createActionflow(
              { newActionflow },
              {
                onSuccess: (data) => {
                  setSubmitting(false);
                  notification.success(
                    "数据源创建成功",
                    `UID: ${data.metadata.uid}`
                  );
                  navigate(`/actionflows/edit?uid=${data.metadata.uid}`);
                },
                onError: (err) => {
                  setSubmitting(false);
                },
              }
            );
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label="名称"
                    name="metadata.name"
                    size="small"
                    validate={(val) => {
                      let error;
                      if (!val) {
                        error = `名称为必填项`;
                      }
                      return error;
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    component={TextField}
                    label="描述"
                    name="metadata.description"
                    size="small"
                  />
                </Grid>

                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </ConfirmDialog>
  );
}

export default ActionflowCreateDialog;
