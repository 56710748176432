import React from "react";
import { Grid, Stack, Box } from "@mui/material";

export function Descriptions({ title, ...props }) {
  return (
    <>
      <Box sx={{ mb: 2, fontSize: 18 }}>{title}</Box>
      <Grid container spacing={0.5} sx={{ flexGrow: 1 }}>
        {props.children}
      </Grid>
    </>
  );
}

export function DescriptionItem({ label, span, ...props }) {
  return (
    <Grid item xs={span || 4}>
      <Stack direction={"row"} spacing={1} alignItems="center">
        <span style={{ fontSize: 14, fontWeight: 500 }}>{label}:</span>
        <span style={{ fontSize: 14, lineHeight: "30px", height: "30px" }}>
          {props.children}
        </span>
      </Stack>
    </Grid>
  );
}
