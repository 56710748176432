import { del, get, patch, post, put } from "../utils/request";

const create = ({ data, derivedFrom, derivedName }) => {
  return post(
    `/proxy/@ops/api/v1/actions?derived_from=${derivedFrom}&derived_name=${derivedName}`,
    data
  );
};

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/actions`, listOptions);
};

const getByUid = ({ uid }) => {
  return get(`/proxy/@ops/api/v1/actions/${uid}`);
};

const patchByUid = ({ uid, data }) => {
  return patch(`/proxy/@ops/api/v1/actions/${uid}`, data);
};

const saveByUid = ({ uid, data }) => {
  return put(`/proxy/@ops/api/v1/actions/${uid}`, data);
};

const deleteByUid = ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  return del(`/proxy/@ops/api/v1/actions/${uid}?force=${force}`);
};

const debugAByUid = ({ data }) => {
  return post(`/proxy/@ops/api/v1/actions/debug`, data);
};

export const ActionService = {
  create,
  listAll,
  getByUid,
  patchByUid,
  saveByUid,
  deleteByUid,
  debugAByUid,
};
