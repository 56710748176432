import { del, get, handleResponse, patch, post, put } from "../utils/request";

export const createDatasource = async (payload) => {
  const response = await post(`/proxy/@ops/api/v1/datasources`, payload);
  return handleResponse(response);
};

export const listDatasources = async (listOptions = {}) => {
  const response = await get(`/proxy/@ops/api/v1/datasources`, listOptions);
  return handleResponse(response);
};

export const getDatasourceByUid = async (uid) => {
  const response = await get(`/proxy/@ops/api/v1/datasources/${uid}`);
  return handleResponse(response);
};

// public
export const getDatasourceDataByUid = async (uid) => {
  return await get(`/proxy/@ops/api/v1/datasources/${uid}/data`);
};

export const debugDatasource = async (payload) => {
  const response = await post(`/proxy/@ops/api/v1/datasources/debug`, payload);
  return handleResponse(response);
};

export const patchDatasourceByUid = async ({ uid, payload }) => {
  const response = await patch(
    `/proxy/@ops/api/v1/datasources/${uid}`,
    payload
  );
  return handleResponse(response);
};

export const saveDatasourceByUid = async ({ uid, payload }) => {
  const response = await put(`/proxy/@ops/api/v1/datasources/${uid}`, payload);
  return handleResponse(response);
};

export const deleteDatasourceByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const response = await del(
    `/proxy/@ops/api/v1/datasources/${uid}?force=${force}`
  );
  return handleResponse(response);
};
