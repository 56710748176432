import React from 'react';
import {Stack} from "@mui/material";

function TopActionsArea({children}) {

    return (
        <Stack direction="row" sx={{mb: 2}} spacing={1}>
            {children}
        </Stack>
    );
}

export default TopActionsArea;