import React, { useEffect, useState } from "react";
import ExecutionStatusBar from "./components/ExecutionStatusBar";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount";
import TitledArea from "../../../components/TitledArea/TitledArea";
import TaskNodeCard from "./components/TaskNodeCard";
import { executionStatus, taskStatus } from "../../../constants/execution";
import { Alert } from "@mui/lab";
import FlowExecutionDrawer from "./components/ExecutionGraph/Drawer";

const filterTaskTypes = {
  All: "all",
  Processing: "processing",
  Waiting: "waiting",
  Done: "done",
};

function DashboardConsoleView({
  execution,
  confirmingTaskExecutions,
  ...props
}) {
  const [filterTaskType, setFilterTaskType] = useState(
    filterTaskTypes.Processing
  );
  const [filterTaskIds, setFilterTaskIds] = useState([]);

  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedTaskState, setSelectedTaskState] = useState({});
  const [taskDrawerVisible, setTaskDrawerVisible] = useState(false);
  const [confirmingTaskIds, setConfirmingTaskIds] = useState([]);

  useEffect(() => {
    let taskIdSet = new Set();
    confirmingTaskExecutions?.items?.forEach((te) => {
      taskIdSet.add(te.metadata?.owner_references?.task_id);
    });
    setConfirmingTaskIds(Array.from(taskIdSet));
  }, [confirmingTaskExecutions]);

  useEffect(() => {
    if (execution.metadata && execution.metadata.uid) {
      const taskIds = Object.keys(execution?.state?.tasks).filter((taskId) => {
        switch (filterTaskType) {
          case filterTaskTypes.Waiting:
            return execution.state.tasks[taskId].status === taskStatus.Waiting;
          case filterTaskTypes.Done:
            return (
              execution.state.tasks[taskId].status === taskStatus.Succeeded ||
              execution.state.tasks[taskId].status === taskStatus.Skipped
            );
          case filterTaskTypes.Processing:
            return (
              execution.state.tasks[taskId].status === taskStatus.Running ||
              execution.state.tasks[taskId].status === taskStatus.Confirming ||
              execution.state.tasks[taskId].status === taskStatus.Failed ||
              execution.state.tasks[taskId].status === taskStatus.Pending
            );
          case filterTaskTypes.All:
            return true;
          default:
            return false;
        }
      });
      setFilterTaskIds(taskIds);
    }
  }, [filterTaskType, execution]);

  const handleChange = (event, newFilterTaskType) => {
    if (newFilterTaskType) {
      setFilterTaskType(newFilterTaskType);
    }
  };

  const handleClickDetails = (taskId, taskState) => {
    setSelectedTaskId(taskId);
    setSelectedTaskState(taskState);
    setTaskDrawerVisible(true);
  };

  return (
    <>
      {/* 节点执行 Drawer */}
      <FlowExecutionDrawer
        executionUid={execution.metadata?.uid || ""}
        taskId={selectedTaskId}
        taskState={selectedTaskState}
        visible={taskDrawerVisible}
        setVisible={setTaskDrawerVisible}
      />

      <Grid container spacing={1}>
        {/* 任务节点总览 */}
        <Grid item xs={12}>
          <TitledArea
            isLoading={false}
            icon={<DiscountIcon sx={{ fontSize: 16 }} />}
            title="任务节点总览"
            subtitle="以节点卡片的形式呈现工作流各任务节点，可点击卡片按钮查看执行详情"
            style={{ marginTop: "8px" }}
          >
            <ToggleButtonGroup
              color="primary"
              value={filterTaskType}
              exclusive
              onChange={handleChange}
            >
              <ToggleButton value={filterTaskTypes.All}>全部节点</ToggleButton>
              <ToggleButton value={filterTaskTypes.Processing}>
                进行中节点
              </ToggleButton>
              <ToggleButton value={filterTaskTypes.Waiting}>
                待执行节点
              </ToggleButton>
              <ToggleButton value={filterTaskTypes.Done}>
                已完成节点
              </ToggleButton>
            </ToggleButtonGroup>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
                padding: "16px 0",
              }}
            >
              {filterTaskIds.map((taskId) => (
                <TaskNodeCard
                  key={taskId}
                  taskId={taskId}
                  taskState={execution.state.tasks[taskId]}
                  onClickDetails={handleClickDetails}
                  isNeedConfirm={confirmingTaskIds.includes(taskId)}
                />
              ))}

              {filterTaskIds.length === 0 &&
                filterTaskType === filterTaskTypes.Processing && (
                  <Alert severity="info" sx={{ width: "100%", mt: 2 }}>
                    暂无进行中的任务节点
                  </Alert>
                )}

              {filterTaskIds.length === 0 &&
                filterTaskType === filterTaskTypes.Waiting && (
                  <Alert severity="info" sx={{ width: "100%", mt: 2 }}>
                    暂无等待执行的任务节点
                  </Alert>
                )}

              {filterTaskIds.length === 0 &&
                filterTaskType === filterTaskTypes.Done && (
                  <Alert severity="info" sx={{ width: "100%", mt: 2 }}>
                    暂无已完成的任务节点
                  </Alert>
                )}
            </Box>
          </TitledArea>
        </Grid>

        {/* 实例状态条 */}
        <Grid item xs={12}>
          <ExecutionStatusBar execution={execution} />
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardConsoleView;
