import { useMutation, useQuery } from "react-query";
import {
  createTrigger,
  debugTrigger,
  deleteTriggerByUid,
  getTriggerByUid,
  listTriggers,
  patchTriggerByUid,
  saveTriggerByUid,
} from "../../api/trigger";

export const useCreateTrigger = () => {
  return useMutation(createTrigger);
};

export const useListTriggers = (listOptions) => {
  return useQuery(
    ["triggers", JSON.stringify(listOptions)],
    () => listTriggers(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
    }
  );
};

export const useGetTriggerByUid = (uid) => {
  return useQuery(["triggers", uid], () => getTriggerByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
      },
      status: "",
      state: {},
    },
  });
};

export const useDebugTrigger = () => {
  return useMutation(debugTrigger);
};

export const usePatchTriggerByUid = () => {
  return useMutation(patchTriggerByUid);
};

export const useSaveTriggerByUid = () => {
  return useMutation(saveTriggerByUid);
};

export const useDeleteTriggerByUid = () => {
  return useMutation(deleteTriggerByUid);
};
