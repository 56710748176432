import React, {useState} from 'react';
import {Button, Grid} from "@mui/material";
import WorkflowTable from "./Table";
import ContentArea from "../../components/ContentArea/ContentArea";
import TopActionsArea from "../../components/TopBar/TopActionsArea";
import WorkflowCreateDialog from "./Create";

function Workflow(props) {

    const [createDialogOpen, setCreateDialogOpen] = useState(false)

    return (
        <ContentArea>
            <TopActionsArea>
                <Button onClick={() => setCreateDialogOpen(true)}>创建</Button>
            </TopActionsArea>

            <Grid container spacing={2}>
                <Grid sx={{mb: 3}} item xs={12}>
                    <WorkflowTable/>
                </Grid>
            </Grid>

            <WorkflowCreateDialog open={createDialogOpen} setOpen={setCreateDialogOpen}/>
        </ContentArea>
    );
}

export default Workflow;