import React, { useState } from "react";
import ContentArea from "../../components/ContentArea/ContentArea";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  Tab,
  TextField as MuiTextField,
} from "@mui/material";
import ActionTable from "./Table/Action";
import TopActionsArea from "../../components/TopBar/TopActionsArea";
import { useListActions } from "../../hooks/query/action";

import ActionRegisterDialog from "./Create/action_register";
import ConfirmDialog from "../../components/Dialog/Confirm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import OuterBox from "../../components/Box/OuterBox/OuterBox";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import moment from "moment/moment";
import ActionflowTable from "./Table/Actionflow";
import { useListActionflows } from "../../hooks/query/actionflow";
import ActionflowCreateDialog from "./Create/actionflow_create";
import ActionflowActionAddDialog from "./Edit/Dialog/ActionflowActionAddDialog";

function Actionflow(props) {
  const [actionRegisterDialogOpen, setActionRegisterDialogOpen] =
    useState(false);
  const [actionflowCreateDialogOpen, setActionflowCreateDialogOpen] =
    useState(false);
  const {
    data: actions,
    isFetching: isActionsFetching,
    refetch: refetchActions,
  } = useListActions({}, true);

  const {
    data: actionflows,
    isFetching: isActionflowsFetching,
    refetch: refetchActionflows,
  } = useListActionflows({}, true);

  const [activeTab, setActiveTab] = useState("1");

  return (
    <TabContext value={activeTab}>
      <TabList
        onChange={(e, tabValue) => {
          setActiveTab(tabValue);
        }}
        aria-label="lab API tabs example"
        sx={{ background: "#fff", borderBottom: "1px solid #ddd" }}
      >
        <Tab label="Actionflows" value="1" />
        <Tab label="已注册 Actions" value="2" />
      </TabList>

      <Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <ContentArea>
            <TopActionsArea>
              <Button onClick={() => setActionflowCreateDialogOpen(true)}>
                创建 Actionflow
              </Button>
            </TopActionsArea>
            <Grid container spacing={2}>
              <Grid sx={{ mb: 3 }} item xs={12}>
                <ActionflowTable
                  actions={actionflows}
                  isFetching={isActionflowsFetching}
                  refetch={refetchActionflows}
                />
              </Grid>
            </Grid>
          </ContentArea>

          {/* actionflow create */}
          <ActionflowCreateDialog
            open={actionflowCreateDialogOpen}
            setOpen={setActionflowCreateDialogOpen}
          />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <ContentArea>
            <TopActionsArea>
              <Button onClick={() => setActionRegisterDialogOpen(true)}>
                注册 Action
              </Button>
            </TopActionsArea>
            <Grid container spacing={2}>
              <Grid sx={{ mb: 3 }} item xs={12}>
                <ActionTable
                  actions={actions}
                  isFetching={isActionsFetching}
                  refetch={refetchActions}
                />
              </Grid>
            </Grid>

            {/* action register */}
            <ActionRegisterDialog
              open={actionRegisterDialogOpen}
              setOpen={setActionRegisterDialogOpen}
              actions={actions}
            />
          </ContentArea>
        </TabPanel>
      </Box>
    </TabContext>
  );
}

export default Actionflow;
