import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  MenuItem,
  Stack,
  Tab,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AppBar from "@mui/material/AppBar";
import TitledCard from "../../../TitledCard/TitledCard";
import FlowEditorDrawerForm from "./Form";
import ParamsDisplayTable from "../../../Params/Table/ParamsDisplayTable";
import MDEditor from "@uiw/react-md-editor";
import OuterBox from "../../../Box/OuterBox/OuterBox";
import { deepOverride } from "../../../../utils/object";
import { Field, Formik } from "formik";
import ParamsEditDialog from "../../../Params/Dialog/ParamsEditDialog";
import { useListActions } from "../../../../hooks/query/action";
import AceEditor from "react-ace";
import YAML from "yaml";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-idle_fingers";
import { useNotification } from "../../../../hooks/common/useNotification";
import TitledArea from "../../../TitledArea/TitledArea";
import { useListDatasources } from "../../../../hooks/query/datasource";
import { datasourceConstraintType } from "../../../../constants/datasource";
import lodash, { cloneDeep } from "lodash";
import { workflowTaskRangePolicy } from "../../../../constants/workflow";
import { useListActionflows } from "../../../../hooks/query/actionflow";

function FlowEditorDrawer({
  graph,
  visible,
  setVisible,
  node,
  graphData,
  handleAddNextNode,
  handleRemoveNode,
}) {
  const notification = useNotification();
  const formRef = useRef(null);
  const [activeTab, setActiveTab] = useState("basic");
  const [paramsEditDialogOpen, setParamsEditDialogOpen] = useState(false);
  // 初始化
  const [docs, setDocs] = useState("");
  const [actionRef, setActionRef] = useState("");
  const [actionflowUid, setActionflowUid] = useState("");
  const [rangeListDatasourceUid, setRangeListDatasourceUid] = useState("");
  const [rangeExecPolicy, setRangeExecPolicy] = useState(
    workflowTaskRangePolicy.Rolling
  );
  const [next, setNext] = useState([]);
  const [params, setParams] = useState([]);
  const [publishYaml, setPublishYaml] = useState("");
  // data init
  // const {
  //   data: actions,
  //   isFetching: isActionsFetching,
  //   refetch: refetchActions,
  // } = useListActions({}, false);

  const {
    data: actionflows,
    isFetching: isActionflowsFetching,
    refetch: refetchActionflows,
  } = useListActionflows({}, false);

  const {
    data: datasourceOfTaskRangeList,
    isFetching: isTaskRangeListFetching,
    refetch: refetchTaskRangeList,
  } = useListDatasources({
    "spec.constraint_type": datasourceConstraintType.TaskRangeList,
  });

  useEffect(() => {
    if (visible) {
      setActiveTab("basic");
      // refetchActions();
      refetchActionflows();
    }
    initDrawerData();
  }, [visible]);

  // 初始化数据
  useEffect(() => {
    initDrawerData();
  }, [node]);

  const initDrawerData = () => {
    if (node && node.data) {
      setDocs(node.data.docs);
      setNext(node.data.next);
      setParams(node.data.params);
      // setActionRef(node.data.action_ref);
      setActionflowUid(node.data.actionflow_uid);
      setRangeListDatasourceUid(node.data.range.datasource_uid);
      setRangeExecPolicy(
        node.data.range?.exec_policy || workflowTaskRangePolicy.Rolling
      );
      setPublishYaml(YAML.stringify(node.data.publish));
    }
  };

  const saveNodeData = () => {
    // 校验生成 publish
    let newPublish;
    try {
      newPublish = YAML.parse(publishYaml);
      console.log("newPublish:", newPublish);
    } catch (err) {
      notification.warning("无法解析变量发布定义 YAML", err.toString());
      return;
    }

    // 保存表单数据 + newDocs + newNext + newParams
    const formData = formRef.current.values;
    let merged = deepOverride(node.data, formData);

    merged.docs = docs;
    merged.next = next;
    merged.params = params;
    merged.publish = newPublish;
    // merged.action_ref = actionRef;
    merged.actionflow_uid = actionflowUid;
    merged.range.datasource_uid = rangeListDatasourceUid;
    merged.range.exec_policy = rangeExecPolicy;

    console.log("final data:", merged);
    node.replaceData(merged);

    setVisible(false);
  };

  const handleClickAddNextNode = () => {
    handleAddNextNode(node?.getData().id);
    setVisible(false);
  };

  const handleClickRemoveCurrentNode = () => {
    handleRemoveNode(node.id);
    setVisible(false);
  };

  return (
    <TabContext value={activeTab}>
      <Drawer
        anchor="right"
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        PaperProps={{ style: { background: "#eaeff1" } }}
      >
        <Formik
          innerRef={formRef}
          initialValues={{
            ...node?.data,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              alert(JSON.stringify(values, null, 2));
            }, 500);
          }}
        >
          <div style={{ width: 700 }}>
            <AppBar
              color="inherit"
              component="div"
              position="static"
              elevation={1}
              sx={{ zIndex: 0 }}
            >
              <TabList
                onChange={(e, tabValue) => {
                  setActiveTab(tabValue);
                }}
                aria-label="lab API tabs example"
              >
                <Tab label="基本配置" value="basic" />
                <Tab label="执行配置" value="exec" />
                <Tab label="参数配置" value="params" />
                <Tab label="任务转移" value="next" />
              </TabList>
            </AppBar>

            <Stack sx={{ m: 2 }} direction={"row"} spacing={1}>
              <Button onClick={saveNodeData}>暂存修改</Button>
              <Button onClick={handleClickAddNextNode}>添加后序节点</Button>
              <Button
                onClick={handleClickRemoveCurrentNode}
                disabled={node?.id === "entry" || node?.id === "task-0"}
              >
                移除当前节点{" "}
                {(node?.id === "entry" || node?.id === "task-0") &&
                  "（入口节点不可移除）"}
              </Button>
            </Stack>

            <TabPanel value="basic" sx={{ p: 0 }}>
              <Stack sx={{ m: 2 }}>
                {/* 基本信息 */}
                <FlowEditorDrawerForm
                  formRef={formRef}
                  node={node}
                  // isActionsFetching={isActionsFetching}
                  // actions={actions}
                />

                {/* 指引文档 */}
                <TitledArea
                  title="指引文档"
                  subtitle="一般用于手动执行时提供指引或自动执行失效时备用方案"
                >
                  <Stack spacing={2}>
                    <MDEditor value={docs} onChange={setDocs} preview="edit" />

                    <TitledCard title="文档渲染模板（预览）">
                      <MDEditor.Markdown source={docs} />
                    </TitledCard>
                  </Stack>
                </TitledArea>
              </Stack>
            </TabPanel>

            <TabPanel value="exec" sx={{ p: 0 }}>
              <OuterBox>
                {/* Actionflow 绑定 */}
                <TitledCard
                  title="绑定 Actionflow"
                  subtitle="绑定后支持任务节点自动执行一系列操作"
                >
                  <TextField
                    fullWidth
                    select
                    size="small"
                    label="绑定 Actionflow"
                    name="actionflow_uid"
                    value={actionflowUid}
                    onChange={(e) => setActionflowUid(e.target.value)}
                  >
                    <MenuItem
                      key="none"
                      style={{ width: "100%", color: "rgba(0,0,0,0.5)" }}
                      value=""
                    >
                      [移除 Actionflow 绑定]
                    </MenuItem>
                    {isActionflowsFetching ? (
                      <MenuItem>
                        <CircularProgress />
                      </MenuItem>
                    ) : (
                      actionflows.items?.map((af) => (
                        <MenuItem
                          key={af.metadata.uid}
                          style={{ width: "100%" }}
                          value={af.metadata.uid}
                        >
                          {af.metadata.name}{" "}
                          <span
                            style={{
                              marginLeft: 6,
                              fontSize: 12,
                              color: "#808080",
                            }}
                          >
                            ({af.metadata.description})
                          </span>
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                </TitledCard>

                {/* 遍历执行 */}
                <TitledCard
                  title="节点执行列表"
                  subtitle="根据指定执行列表执行当前任务，默认执行单个 default 子任务"
                >
                  <Stack spacing={2}>
                    {/* 执行列表数据源绑定 */}
                    <TextField
                      fullWidth
                      select
                      size="small"
                      label="默认执行单个 default 子任务"
                      name="rangeList"
                      value={rangeListDatasourceUid}
                      onChange={(e) =>
                        setRangeListDatasourceUid(e.target.value)
                      }
                    >
                      <MenuItem
                        key="none"
                        style={{ width: "100%", color: "rgba(0,0,0,0.5)" }}
                        value=""
                      >
                        [移除节点执行列表]
                      </MenuItem>
                      {isTaskRangeListFetching ? (
                        <MenuItem>
                          <CircularProgress />
                        </MenuItem>
                      ) : (
                        datasourceOfTaskRangeList.items?.map((item) => (
                          <MenuItem
                            key={item.metadata.uid}
                            style={{ width: "100%" }}
                            value={item.metadata.uid}
                          >
                            {item.metadata.name}{" "}
                            <span
                              style={{
                                marginLeft: 6,
                                fontSize: 12,
                                color: "#808080",
                              }}
                            >
                              ({item.metadata.description})
                            </span>
                          </MenuItem>
                        ))
                      )}
                    </TextField>

                    {/* 执行策略设定（并行执行、滚动执行） */}
                    <ToggleButtonGroup
                      exclusive
                      color="primary"
                      size="small"
                      value={rangeExecPolicy}
                      onChange={(e, val) => {
                        if (val !== null) {
                          setRangeExecPolicy(val);
                        }
                      }}
                    >
                      <ToggleButton value={workflowTaskRangePolicy.Rolling}>
                        滚动执行
                      </ToggleButton>
                      <ToggleButton value={workflowTaskRangePolicy.Parallel}>
                        并行执行
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                </TitledCard>

                {/* 变量发布 */}
                <TitledCard title="发布变量定义">
                  {/* TODO 发布变量列表 or 发布变量 YAML 定义 */}
                  <AceEditor
                    mode="yaml"
                    theme="idle_fingers"
                    width="100%"
                    minLines={10}
                    maxLines={30}
                    value={publishYaml}
                    onChange={(val) => setPublishYaml(val)}
                    name="custom-data"
                    fontSize={14}
                    editorProps={{ $blockScrolling: true }}
                  />
                </TitledCard>
              </OuterBox>
            </TabPanel>

            <TabPanel value="params" sx={{ p: 0 }}>
              <OuterBox>
                {/* 节点参数 */}
                <TitledCard
                  title="节点参数"
                  subtitle="节点参数作用域限制在当前任务节点"
                >
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1}>
                      <Button onClick={() => setParamsEditDialogOpen(true)}>
                        参数编辑
                      </Button>
                    </Stack>
                    <ParamsDisplayTable params={params || []} />

                    {/* 参数编辑 */}
                    <ParamsEditDialog
                      open={paramsEditDialogOpen}
                      params={params || []}
                      onClose={() => setParamsEditDialogOpen(false)}
                      onConfirm={(newParams) => {
                        setParams(newParams);
                        setParamsEditDialogOpen(false);
                      }}
                    />
                  </Stack>
                </TitledCard>
              </OuterBox>
            </TabPanel>

            <TabPanel value="next" sx={{ p: 0 }}>
              <OuterBox>
                <Stack direction={"column"}>
                  {next.map((n) => {
                    const nextId = n.do;
                    return (
                      <TitledCard
                        key={nextId}
                        title={`任务转移目标节点：${nextId}`}
                      >
                        <Stack spacing={2}>
                          <TextField
                            label="任务转移条件表达式（Go 模板语法）"
                            placeholder={`任务转移条件表达式 (Go 模板语法，例: {{- eq .status "ok" -}} )`}
                            size="small"
                            value={n.if}
                            onChange={(e) => {
                              setNext(
                                next.map((newN) => {
                                  if (newN.do === nextId) {
                                    newN.if = e.target.value;
                                    return newN;
                                  } else {
                                    return newN;
                                  }
                                })
                              );
                            }}
                          />
                          <Button
                            variant={"outlined"}
                            color={"error"}
                            onClick={() => {
                              setNext(
                                next.filter((newN) => newN.do !== nextId)
                              );

                              graph.current.removeEdge(
                                `${node.data.id}-${nextId}`
                              );
                            }}
                          >
                            移除该触发任务
                          </Button>
                        </Stack>
                      </TitledCard>
                    );
                  })}
                </Stack>
              </OuterBox>
            </TabPanel>
          </div>
        </Formik>
      </Drawer>
    </TabContext>
  );
}

export default FlowEditorDrawer;
