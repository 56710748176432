import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  LinearProgress,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { LoadingOutlined } from "@ant-design/icons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import CachedIcon from "@mui/icons-material/Cached";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import NearMeIcon from "@mui/icons-material/NearMe";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import {
  useConfirmTaskExecutionByUid,
  useRerunTaskExecutionByUid,
  useSkipTaskExecutionByUid,
  useTerminateTaskExecutionByUid,
} from "../../../../../../../hooks/query/task_execution";
import { useNotification } from "../../../../../../../hooks/common/useNotification";
import { taskExecutionStatus } from "../../../../../../../constants/task_execution";
import MDEditor from "@uiw/react-md-editor";
import TitledArea from "../../../../../../../components/TitledArea/TitledArea";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DvrIcon from "@mui/icons-material/Dvr";
import moment from "moment/moment";
import ButtonWithConfirmDialog from "../../../../../../../components/Button/ButtonWithConfirmDialog";
import { Alert, TabContext, TabList, TabPanel } from "@mui/lab";
import TaskExecutionLogs from "./TaskExecutionLogs";
import { getS3FileLog } from "../../../../../../../api/task_execution";
import { useSearchParams } from "react-router-dom";

function TaskExecutionAccordion({
  drawerVisible,
  taskExecutions,
  isTaskExecutionsFetching,
  setDrawerVisible,
  ...props
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const notification = useNotification();

  const [expanded, setExpanded] = useState(false);
  const { mutate: confirmTaskExecutionByUid } = useConfirmTaskExecutionByUid();
  const { mutate: rerunTaskExecutionByUid } = useRerunTaskExecutionByUid();
  const { mutate: skipTaskExecutionByUid } = useSkipTaskExecutionByUid();
  const { mutate: terminateTaskExecutionByUid } =
    useTerminateTaskExecutionByUid();

  // 展开的 TaskExecution Logs，s3_log_ref 不为空的话展示 s3 的 fileLog，否则展示 data（TODO 过渡阶段）
  const [expandedTeLogsLoading, setExpandedTeLogsLoading] = useState(false);
  const [expandedTeLogs, setExpandedTeLogs] = useState("");
  const [expandedTeUid, setExpandedTeUid] = useState("");
  const [activeTab, setActiveTab] = useState("result");

  useEffect(() => {
    if (taskExecutions.items.length === 1) {
      const defaultTe = taskExecutions.items[0];
      setExpanded(defaultTe.metadata.uid);
      setExpandedTeUid(defaultTe.metadata.uid);
    }
  }, [taskExecutions]);

  useEffect(() => {
    if (taskExecutions?.items?.length > 0) {
      setExpandedTeLogs("");
      if (expandedTeUid && expandedTeUid !== "") {
        setupTaskExecutionLogs(expandedTeUid);
      }
    }
  }, [expandedTeUid, taskExecutions]);

  const setupTaskExecutionLogs = (teUid) => {
    const te = taskExecutions.items?.find((te) => te.metadata.uid === teUid);

    if (te && te.metadata && te.metadata.uid) {
      // 展示 defaultTe 的日志
      if (
        te.results[0] &&
        te.results[0].s3_log_ref &&
        te.results[0].s3_log_ref !== ""
      ) {
        setExpandedTeLogsLoading(true);
        getS3FileLog({ logRef: te.results[0].s3_log_ref })
          .then((res) => {
            setExpandedTeLogsLoading(false);
            setExpandedTeLogs(res.data);
          })
          .catch((err) => {
            console.log(err.toString());
            setExpandedTeLogsLoading(false);
          });
      } else {
        if (te.results[0] && te.results[0].data) {
          setExpandedTeLogs(te.results[0].data);
        }
      }
    }
  };

  const handleChange = (teId) => (event, isExpanded) => {
    setExpanded(isExpanded ? teId : false);
    setExpandedTeUid(teId);
  };

  const handleConfirmTaskExecution = (uid) => {
    confirmTaskExecutionByUid(
      { uid: uid, payload: {} },
      {
        onSuccess: () => {
          notification.success("确认执行", `uid: ${uid}`);
        },
      }
    );
  };

  const handleRerunTaskExecution = (uid, is_ignore_next = false) => {
    setExpandedTeLogs("");

    rerunTaskExecutionByUid(
      { uid: uid, is_ignore_next: is_ignore_next, payload: {} },
      {
        onSuccess: () => {
          notification.success("重新执行已发起", `uid: ${uid}`);
        },
      }
    );
  };

  const handleSkipTaskExecution = (uid) => {
    skipTaskExecutionByUid(
      { uid: uid, payload: {} },
      {
        onSuccess: () => {
          notification.success("确认跳过", `uid: ${uid}`);
        },
      }
    );
  };

  const handleTerminateTaskExecution = (uid) => {
    terminateTaskExecutionByUid(
      { uid: uid, payload: {} },
      {
        onSuccess: () => {
          notification.success("确认终止执行", `uid: ${uid}`);
        },
      }
    );
  };

  const renderStatusChip = (status = "") => {
    switch (status) {
      case taskExecutionStatus.Waiting:
        return (
          <Chip
            label="等待执行"
            icon={<HourglassTopIcon sx={{ fontSize: 18 }} />}
            color="default"
          />
        );
      case taskExecutionStatus.Scheduling:
        return (
          <Chip
            label="正在调度"
            icon={<NearMeIcon sx={{ fontSize: 18 }} />}
            color="statusScheduling"
          />
        );
      case taskExecutionStatus.Skipped:
        return (
          <Chip
            label="跳过执行"
            icon={<DoDisturbOnIcon sx={{ fontSize: 18 }} />}
            color="default"
          />
        );
      case taskExecutionStatus.Running:
        return (
          <Chip
            label="正在执行"
            icon={<LoadingOutlined sx={{ fontSize: 18 }} />}
            color="info"
          />
        );
      case taskExecutionStatus.Confirming:
        return (
          <Chip
            label="等待确认"
            icon={<InfoIcon sx={{ fontSize: 18 }} />}
            color="warning"
          />
        );
      case taskExecutionStatus.Succeeded:
        return (
          <Chip
            label="执行成功"
            icon={<CheckCircleIcon sx={{ fontSize: 18 }} />}
            color="statusSucceeded"
          />
        );
      case taskExecutionStatus.Failed:
        return (
          <Chip
            label="执行失败"
            icon={<CancelIcon sx={{ fontSize: 18 }} />}
            color="error"
          />
        );
      case taskExecutionStatus.Terminated:
        return (
          <Chip
            label="终止执行"
            icon={<IndeterminateCheckBoxIcon sx={{ fontSize: 18 }} />}
            color="statusTerminated"
          />
        );
      default:
        return <Chip label={status} />;
    }
  };

  return (
    <div>
      {isTaskExecutionsFetching && <LinearProgress />}
      {taskExecutions?.items
        ?.sort((a, b) => a.stage - b.stage)
        .map((te) => (
          <Accordion
            key={te.metadata?.uid}
            expanded={expanded === te.metadata?.uid}
            onChange={handleChange(te.metadata?.uid)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {te.metadata?.owner_references.range_item}
              </Typography>
              <Stack direction={"row"} spacing={2}>
                {renderStatusChip(te.status)}

                {te.status === taskExecutionStatus.Confirming && (
                  <ButtonWithConfirmDialog
                    ButtonProps={{
                      startIcon: <PlaylistAddCheckCircleIcon />,
                      variant: "outlined",
                    }}
                    confirmTitle={"确认执行"}
                    confirmText={
                      <div>
                        确认 <b>{te.metadata?.owner_references.range_item}</b>{" "}
                        执行当前任务节点
                      </div>
                    }
                    onConfirm={() =>
                      handleConfirmTaskExecution(te.metadata?.uid)
                    }
                  >
                    确认执行
                  </ButtonWithConfirmDialog>
                )}
                {te.status === taskExecutionStatus.Running && (
                  <ButtonWithConfirmDialog
                    ButtonProps={{
                      startIcon: <IndeterminateCheckBoxIcon />,
                      variant: "outlined",
                      color: "error",
                    }}
                    confirmTitle={"终止执行"}
                    confirmText={
                      <div>
                        确认 <b>{te.metadata?.owner_references.range_item}</b>{" "}
                        立即终止执行当前任务节点
                      </div>
                    }
                    onConfirm={() =>
                      handleTerminateTaskExecution(te.metadata?.uid)
                    }
                  >
                    终止执行
                  </ButtonWithConfirmDialog>
                )}
                {/* 重新执行并继续 */}
                {(te.status === taskExecutionStatus.Failed ||
                  te.status === taskExecutionStatus.Terminated) && (
                  <ButtonWithConfirmDialog
                    ButtonProps={{
                      startIcon: <CachedIcon />,
                      variant: "outlined",
                    }}
                    confirmTitle={"确认重新执行并继续"}
                    confirmText={
                      <div>
                        确认 <b>{te.metadata?.owner_references.range_item}</b>{" "}
                        是否重新执行当前任务节点，并继续后续任务
                      </div>
                    }
                    onConfirm={() =>
                      handleRerunTaskExecution(te.metadata?.uid, false)
                    }
                  >
                    重新执行并继续
                  </ButtonWithConfirmDialog>
                )}
                {/* 重新执行当前节点 */}
                {(te.status === taskExecutionStatus.Succeeded ||
                  te.status === taskExecutionStatus.Skipped) && (
                  <Stack direction="row" spacing={1}>
                    <ButtonWithConfirmDialog
                      ButtonProps={{
                        startIcon: <CachedIcon />,
                        variant: "outlined",
                      }}
                      confirmTitle={
                        "确认重新执行当前节点（不会触发后续任务节点）"
                      }
                      confirmText={
                        <div>
                          确认 <b>{te.metadata?.owner_references.range_item}</b>{" "}
                          是否重新执行当前任务节点
                        </div>
                      }
                      onConfirm={() =>
                        handleRerunTaskExecution(te.metadata?.uid, true)
                      }
                    >
                      重新执行
                    </ButtonWithConfirmDialog>

                    {searchParams.get("debug") === "true" && (
                      <ButtonWithConfirmDialog
                        ButtonProps={{
                          startIcon: <CachedIcon />,
                          variant: "outlined",
                        }}
                        confirmTitle={"确认重新执行并继续"}
                        confirmText={
                          <div>
                            确认{" "}
                            <b>{te.metadata?.owner_references.range_item}</b>{" "}
                            是否重新执行当前任务节点，并继续后续任务
                          </div>
                        }
                        onConfirm={() =>
                          handleRerunTaskExecution(te.metadata?.uid, false)
                        }
                      >
                        !重新执行并继续
                      </ButtonWithConfirmDialog>
                    )}
                  </Stack>
                )}
                {te.status !== taskExecutionStatus.Waiting &&
                  te.status !== taskExecutionStatus.Running &&
                  te.status !== taskExecutionStatus.Skipped &&
                  te.status !== taskExecutionStatus.Succeeded && (
                    <ButtonWithConfirmDialog
                      ButtonProps={{
                        startIcon: <SkipNextIcon />,
                        variant: "outlined",
                      }}
                      confirmTitle={"确认跳过执行"}
                      confirmText={
                        <div>
                          确认 <b>{te.metadata?.owner_references.range_item}</b>{" "}
                          跳过执行当前任务节点
                        </div>
                      }
                      onConfirm={() =>
                        handleSkipTaskExecution(te.metadata?.uid)
                      }
                    >
                      跳过执行
                    </ButtonWithConfirmDialog>
                  )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {/* 指引文档 */}
              <TitledArea
                icon={<MenuBookIcon sx={{ fontSize: 16 }} />}
                title="指引文档"
              >
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                    borderRadius: 1,
                    border: "1px solid #ccc",
                  }}
                >
                  <MDEditor.Markdown source={te.docs || "无"} />
                </Box>
              </TitledArea>

              <TabContext value={activeTab}>
                <TabList
                  onChange={(e, tabValue) => {
                    setActiveTab(tabValue);
                  }}
                  aria-label="lab API tabs example"
                >
                  <Tab label="执行结果" value="result" />
                  <Tab label="实时输出" value="podlogs" />
                </TabList>

                <TabPanel value="result" sx={{ p: 0 }}>
                  {/* 执行结果 */}
                  <TitledArea
                    sx={{ mt: 3 }}
                    title="执行结果"
                    icon={<DvrIcon sx={{ fontSize: 16 }} />}
                  >
                    {te.status === taskExecutionStatus.Running ? (
                      <LinearProgress />
                    ) : (
                      <Box
                        sx={{
                          background: "#eef0f1",
                          px: 2,
                          py: 1,
                          borderRadius: 1,
                          border: "1px solid #ccc",
                          maxHeight: "600px",
                          overflow: "auto",
                        }}
                      >
                        <pre style={{ margin: 0 }}>
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "rgba(0,0,0,0.4)",
                            }}
                          >
                            {te.results[0]?.created_at &&
                              `${te.results[0]?.created_by} 执行于 ${moment(
                                te.results[0]?.created_at
                              ).format("YYYY-MM-DD HH:mm:ss")}`}
                          </div>

                          {/* TODO 展示结果列表 */}

                          {expandedTeLogsLoading ? (
                            <div style={{ margin: "16px 0" }}>
                              <div style={{ textAlign: "center" }}>
                                <CircularProgress />
                              </div>
                              <div style={{ textAlign: "center" }}>
                                执行日志加载中
                              </div>
                            </div>
                          ) : (
                            // 日志展示出口
                            expandedTeLogs
                          )}

                          {expandedTeLogsLoading === false &&
                            expandedTeLogs === "" && (
                              <Alert severity="info" sx={{ mt: 2 }}>
                                无 Actionflow 日志输出
                              </Alert>
                            )}
                        </pre>
                      </Box>
                    )}
                  </TitledArea>
                </TabPanel>

                {/* 实时输出（保留1d） */}
                <TabPanel value="podlogs" sx={{ p: 0 }}>
                  <TitledArea
                    sx={{ mt: 3 }}
                    title="实时输出"
                    subtitle="保留1d"
                    icon={<DvrIcon sx={{ fontSize: 16 }} />}
                  >
                    <TaskExecutionLogs
                      taskExecutionUid={te?.metadata?.uid}
                      show={drawerVisible && activeTab === "podlogs"}
                    />
                  </TitledArea>
                </TabPanel>
              </TabContext>
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}

export default TaskExecutionAccordion;
