import React from "react";
import { gridLocaleTextZh } from "../../i18n/datagrid";
import CustomToolbar from "./CustomToolbar";
import { DataGrid } from "@mui/x-data-grid";

function CustomTable({ rows, columns, getRowId, loading }) {
  return (
    <DataGrid
      autoHeight
      disableSelectionOnClick
      disableColumnSelector
      sx={{ p: 2 }}
      rows={rows}
      columns={columns}
      getRowId={getRowId}
      pageSize={15}
      rowsPerPageOptions={[15]}
      loading={loading}
      localeText={gridLocaleTextZh}
      components={{ Toolbar: CustomToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
}

export default CustomTable;
