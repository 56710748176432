import React, { useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import FlowExecution from "./components/ExecutionGraph";
import LoadingProgress from "../../../components/LoadingProgress/LoadingProgress";
import ExecutionStatusBar from "./components/ExecutionStatusBar";

function DashboardGraphView({
  graphData,
  executionUid,
  execution,
  refetchExecution,
  ...props
}) {
  return (
    <>
      <Stack spacing={2} sx={{ pb: 1 }}>
        {graphData.length > 0 ? (
          <FlowExecution graphData={graphData} executionUid={executionUid} />
        ) : (
          <LoadingProgress />
        )}

        {/* 实例状态条 */}
        <ExecutionStatusBar execution={execution} />
      </Stack>
    </>
  );
}

export default DashboardGraphView;
