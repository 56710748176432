import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseEnvelope } from "../../utils/request";
import { ExecutionService } from "../../services/execution";

export const createExecution = createAsyncThunk(
  "execution/create",
  async ({ data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.listAll({ data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const listExecutions = createAsyncThunk(
  "execution/listAll",
  async ({ listOptions }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.listAll({ listOptions })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getExecutionByUid = createAsyncThunk(
  "execution/getByUid",
  async ({ uid }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.getByUid({ uid })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const createExecutionWorkOrderByUid = createAsyncThunk(
  "execution/createWorkOrderByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.createWorkOrderByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const patchExecutionByUid = createAsyncThunk(
  "execution/patchByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.patchByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const saveExecutionByUid = createAsyncThunk(
  "execution/saveByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.saveByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const deleteExecutionByUid = createAsyncThunk(
  "execution/deleteByUid",
  async ({ uid, force }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.deleteByUid({ uid, force })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const confirmExecutionTask = createAsyncThunk(
  "execution/confirmTask",
  async ({ uid, taskId }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.confirmTask({ uid, taskId })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const rerunExecutionTask = createAsyncThunk(
  "execution/rerunTask",
  async ({ uid, taskId }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ExecutionService.rerunTask({ uid, taskId })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const executionSlice = createSlice({
  name: "execution",
  initialState: {
    current: {
      metadata: {
        uid: "",
        name: "",
      },
    },
    list: { items: [], total: 0 },
    isCurrentLoading: false,
    isListLoading: false,
    error: "",
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.current = { name: "", uid: "" };
    },
    clearList: (state, action) => {
      state.list = { items: [], total: 0 };
    },
  },
  extraReducers: {
    [listExecutions.pending]: (state, action) => {
      state.isListLoading = true;
    },
    [listExecutions.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isCurrentLoading = false;
    },
    [listExecutions.rejected]: (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    [getExecutionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [getExecutionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [getExecutionByUid.fulfilled]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [patchExecutionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [patchExecutionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listExecutions.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [saveExecutionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [saveExecutionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listExecutions.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [deleteExecutionByUid.fulfilled]: (state, action) => {},
    [deleteExecutionByUid.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { clearCurrent, clearList } = executionSlice.actions;

export default executionSlice.reducer;
