import React from "react";
import { useMutation, useQuery } from "react-query";
import {
  confirmTaskExecutionByUid,
  getTaskExecutionByUid,
  listTaskExecutions,
  rerunTaskExecutionByUid,
  skipTaskExecutionByUid,
  terminateTaskExecutionByUid,
} from "../../api/task_execution";

export const useListTaskExecutions = (listOptions, options = {}) => {
  return useQuery(
    ["task-executions", JSON.stringify(listOptions)],
    () => listTaskExecutions(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
      enabled: false,
      ...options,
    }
  );
};

export const useListConfirmingTaskExecutions = (listOptions, options = {}) => {
  return useQuery(
    ["task-executions", "confirming"],
    () => listTaskExecutions(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
      enabled: false,
      ...options,
    }
  );
};

export const useGetTaskExecutionByUid = (uid) => {
  return useQuery(["task-executions", uid], () => getTaskExecutionByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
      },
      status: "",
      result: "",
      docs: "",
    },
  });
};

export const useConfirmTaskExecutionByUid = () => {
  return useMutation(confirmTaskExecutionByUid);
};

export const useRerunTaskExecutionByUid = () => {
  return useMutation(rerunTaskExecutionByUid);
};

export const useSkipTaskExecutionByUid = () => {
  return useMutation(skipTaskExecutionByUid);
};

export const useTerminateTaskExecutionByUid = () => {
  return useMutation(terminateTaskExecutionByUid);
};
