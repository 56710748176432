import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import {useNavigate} from "react-router-dom";
import {GlobalConfig} from "../../globalConfig";
import {useSelector} from "react-redux";

function HeaderBreadcrumbs() {
    const navigate = useNavigate();

    const {routeStack} = useSelector(store => store.site)

    return (
        <Breadcrumbs
            sx={{ fontSize: 14, display: "inline" }}
            aria-label="breadcrumb"
        >
            <Link underline="hover" color="inherit" onClick={() => navigate("/")}>
                {GlobalConfig.site.title}
            </Link>
            {routeStack.map((route, i) => {
                if (i < routeStack.length - 1) {
                    return (
                        <Link
                            key={route.path}
                            underline="hover"
                            color="inherit"
                            onClick={() => navigate(route.path)}
                        >
                            {route.title}
                        </Link>
                    );
                } else {
                    return (
                        <Typography
                            key={route.path}
                            sx={{ fontSize: 14 }}
                            color="text.primary"
                        >
                            {route.title}
                        </Typography>
                    );
                }
            })}
        </Breadcrumbs>
    );
}

export default HeaderBreadcrumbs;
