import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseEnvelope } from "../../utils/request";
import { ActionService } from "../../services/action";

export const listActions = createAsyncThunk(
  "action/listAll",
  async ({ listOptions }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionService.listAll({ listOptions })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getActionByUid = createAsyncThunk(
  "action/getByUid",
  async ({ uid }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionService.getByUid({ uid })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const patchActionByUid = createAsyncThunk(
  "action/patchByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionService.patchByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const saveActionByUid = createAsyncThunk(
  "action/saveByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionService.saveByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const deleteActionByUid = createAsyncThunk(
  "action/deleteByUid",
  async ({ uid, force }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionService.deleteByUid({ uid, force })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const actionSlice = createSlice({
  name: "action",
  initialState: {
    current: {
      metadata: {
        uid: "",
        name: "",
      },
    },
    list: { items: [], total: 0 },
    isCurrentLoading: false,
    isListLoading: false,
    error: "",
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.current = { name: "", uid: "" };
    },
    clearList: (state, action) => {
      state.list = { items: [], total: 0 };
    },
  },
  extraReducers: {
    [listActions.pending]: (state, action) => {
      state.isListLoading = true;
    },
    [listActions.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isCurrentLoading = false;
    },
    [listActions.rejected]: (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    [getActionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [getActionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [getActionByUid.fulfilled]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [patchActionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [patchActionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listActions.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [saveActionByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [saveActionByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listActions.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [deleteActionByUid.fulfilled]: (state, action) => {},
    [deleteActionByUid.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { clearCurrent, clearList } = actionSlice.actions;

export default actionSlice.reducer;
