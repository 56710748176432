import React, {useEffect} from 'react';
import {GlobalConfig} from "../globalConfig";
import {useSelector} from "react-redux";

function useSiteTitle(props) {

    const {routeStack} = useSelector(store => store.site)

    useEffect(()=>{
        const siteTitle = GlobalConfig.site.title

        if (routeStack.length >= 1) {
            const pageTitle = routeStack[routeStack.length - 1].title
            document.title = `${pageTitle} - ${siteTitle}`
        }

    }, [routeStack])

}

export default useSiteTitle;
