import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";
import { localGetCurrentNamespace } from "../localstorage/namespace";

export const createActionflow = async ({ newActionflow }) => {
  const { data } = await post(`/proxy/@ops/api/v1/actionflows`, newActionflow);
  return handleResponseEnvelope(data);
};

export const listActionflows = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/actionflows`, listOptions);
  return handleResponseEnvelope(data);
};

export const getActionflowByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/actionflows/${uid}`);
  return handleResponseEnvelope(data);
};

export const patchActionflowByUid = async ({ uid, payload }) => {
  const { data } = await patch(
    `/proxy/@ops/api/v1/actionflows/${uid}`,
    payload
  );
  return handleResponseEnvelope(data);
};

export const saveActionflowByUid = async ({ uid, payload }) => {
  const { data } = await put(`/proxy/@ops/api/v1/actionflows/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const deleteActionflowByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@ops/api/v1/actionflows/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};

export const debugActionflowByUid = async ({ payload }) => {
  const { data } = await post(`/proxy/@ops/api/v1/actionflows/debug`, payload);
  return handleResponseEnvelope(data);
};
