import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { Button, Chip, Paper, Stack } from "@mui/material";
import {
  useDeleteExecutionByUid,
  useListExecutions,
} from "../../../hooks/query/execution";
import { formatDatetime } from "../../../utils/datetime";
import CountdownDialog from "../../../components/Dialog/Countdown";
import { getExecutionStatusChip } from "../../../utils/chips";
import CustomTable from "../../../components/Table/CustomTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PreviewIcon from "@mui/icons-material/Preview";
import { executionStatus } from "../../../constants/execution";

function ExecutionTable(props) {
  let navigate = useNavigate();
  const {
    data: executions,
    isFetching,
    refetch,
  } = useListExecutions({}, "metadata,status");

  const { mutate: deleteExecutionByUid } = useDeleteExecutionByUid();
  const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
  const [deleteExecutionUid, setDeleteExecutionUid] = useState("");

  const handleClickDelete = () => {
    deleteExecutionByUid(
      { uid: deleteExecutionUid },
      { onSuccess: () => refetch() }
    );
    setDialogConfirmDeleteOpen(false);
    setDeleteExecutionUid("");
  };

  const columns = [
    {
      field: "name",
      headerName: "名称/UID",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.metadata.name} [uid: ${params.row.metadata.uid}]`,
      renderCell: (data) => {
        return (
          <div>
            <div style={{ fontWeight: "bold" }}>
              <Link
                color={"inherit"}
                underline="none"
                onClick={() => {
                  if (data.row.status === executionStatus.Initializing) {
                    // Initializing 的转入 workflow exec 页面
                    navigate(
                      `/workflows/exec?uid=${data.row.metadata.owner_references.workflow_uid}&from_execution_uid=${data.row.metadata.uid}`
                    );
                  } else {
                    navigate(
                      `/executions/dashboard?uid=${data.row.metadata.uid}`
                    );
                  }
                }}
              >
                {data.row.metadata.name}
              </Link>
            </div>
            <div style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>
              {data.row.metadata.uid}
            </div>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "描述",
      minWidth: 200,
      valueGetter: (params) => params.row.metadata.description,
    },
    {
      field: "status",
      headerName: "状态",
      width: 130,
      valueGetter: (params) => params.row.status,
      renderCell: (data) => getExecutionStatusChip(data.row.status),
    },
    {
      field: "created_at",
      headerName: "创建时间",
      width: 170,
      valueGetter: (params) => formatDatetime(params.row.metadata.created_at),
    },
    {
      field: "maintainer",
      headerName: "维护人",
      width: 120,
      valueGetter: (params) => params.row.metadata.owner_references?.maintainer,
    },
    {
      field: "actions",
      headerName: "操作",
      width: 170,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<PreviewIcon />}
              variant={"outlined"}
              onClick={() => {
                if (data.row.status === executionStatus.Initializing) {
                  // Initializing 的转入 workflow exec 页面
                  navigate(
                    `/workflows/exec?uid=${data.row.metadata.owner_references.workflow_uid}&from_execution_uid=${data.row.metadata.uid}`
                  );
                } else {
                  navigate(
                    `/executions/dashboard?uid=${data.row.metadata.uid}`
                  );
                }
              }}
            >
              详情
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant={"outlined"}
              color={"error"}
              onClick={() => {
                setDeleteExecutionUid(data.row.metadata.uid);
                setDialogConfirmDeleteOpen(true);
              }}
            >
              移除
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CountdownDialog
        open={dialogConfirmDeleteOpen}
        title="确认删除"
        description="是否确认删除工作流实例？请确认影响，谨慎操作"
        handleClose={() => setDialogConfirmDeleteOpen(false)}
        handleConfirm={handleClickDelete}
      />
      <CustomTable
        rows={executions.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default ExecutionTable;
