import { del, get, patch, post, put } from "../utils/request";

const create = ({ data }) => {
  return post(`/proxy/@ops/api/v1/actionflows`, data);
};

const listAll = ({ listOptions }) => {
  return get(`/proxy/@ops/api/v1/actionflows`, listOptions);
};

const getByUid = ({ uid }) => {
  return get(`/proxy/@ops/api/v1/actionflows/${uid}`);
};

const patchByUid = ({ uid, data }) => {
  return patch(`/proxy/@ops/api/v1/actionflows/${uid}`, data);
};

const saveByUid = ({ uid, data }) => {
  return put(`/proxy/@ops/api/v1/actionflows/${uid}`, data);
};

const deleteByUid = ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  return del(`/proxy/@ops/api/v1/actionflows/${uid}?force=${force}`);
};

const debugByUid = ({ data }) => {
  return post(`/proxy/@ops/api/v1/actionflows/debug`, data);
};

export const ActionflowService = {
  create,
  listAll,
  getByUid,
  patchByUid,
  saveByUid,
  deleteByUid,
  debugByUid,
};
