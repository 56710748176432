import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";

export const getUserByName = async (username) => {
  const { data } = await get(`/proxy/@ops/api/v1/users/${username}`);
  return handleResponseEnvelope(data);
};

export const listUsers = async (listOptions) => {
  const { data } = await get(`/proxy/@ops/api/v1/users`, listOptions);
  return handleResponseEnvelope(data);
};

export const patchUserByName = async ({ username, payload }) => {
  const { data } = await patch(`/proxy/@ops/api/v1/users/${username}`, payload);
  return handleResponseEnvelope(data);
};

export const saveUserByName = async ({ username, payload }) => {
  const { data } = await put(`/proxy/@ops/api/v1/users/${username}`, payload);
  return handleResponseEnvelope(data);
};
