import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { Button, Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useDeleteDatasourceByUid,
  useListDatasources,
} from "../../../hooks/query/datasource";
import CountdownDialog from "../../../components/Dialog/Countdown";
import { ConstraintTypeChip, FormatTypeChip, SourceTypeChip } from "./Chip";
import CustomTable from "../../../components/Table/CustomTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TuneIcon from "@mui/icons-material/Tune";
import copy from "copy-to-clipboard";
import { useNotification } from "../../../hooks/common/useNotification";
import LinkIcon from "@mui/icons-material/Link";

function DatasourceTable(props) {
  let navigate = useNavigate();
  const notification = useNotification();

  const { data: datasources, isFetching, refetch } = useListDatasources();

  const { mutate: deleteDatasourceByUid } = useDeleteDatasourceByUid();
  const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
  const [deleteDatasourceId, setDeleteDatasourceId] = useState("");

  const handleClickDelete = () => {
    deleteDatasourceByUid(
      { uid: deleteDatasourceId },
      { onSuccess: () => refetch() }
    );
    setDialogConfirmDeleteOpen(false);
    setDeleteDatasourceId("");
  };

  const columns = [
    {
      field: "name",
      headerName: "名称/UID",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.metadata.name} [uid: ${params.row.metadata.uid}]`,
      renderCell: (data) => {
        return (
          <div>
            <div style={{ fontWeight: "bold" }}>
              <Link
                color={"inherit"}
                underline="none"
                onClick={() => {
                  navigate(`/datasources/edit?uid=${data.row.metadata.uid}`);
                }}
              >
                {data.row.metadata.name}
              </Link>
            </div>
            <div style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>
              {data.row.metadata.uid}
            </div>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "描述",
      flex: 1,
      valueGetter: (params) => params.row.metadata.description,
    },
    {
      field: "source_type",
      headerName: "来源类型",
      minWidth: 100,
      renderCell: (data) => SourceTypeChip(data.row.spec.source_type),
    },
    {
      field: "format_type",
      headerName: "数据格式",
      minWidth: 100,
      renderCell: (data) => FormatTypeChip(data.row.spec.format_type),
    },
    {
      field: "constraint_type",
      headerName: "约束类型",
      minWidth: 100,
      renderCell: (data) => ConstraintTypeChip(data.row.spec.constraint_type),
    },
    {
      field: "actions",
      headerName: "操作",
      width: 270,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<TuneIcon />}
              variant={"outlined"}
              onClick={() =>
                navigate(`/datasources/edit?uid=${data.row.metadata.uid}`)
              }
            >
              编辑
            </Button>
            <Button
              startIcon={<LinkIcon />}
              variant={"outlined"}
              onClick={() => {
                const dataUrl = `${process.env.REACT_APP_OPS_SERVER_BASEURL}/api/v1/public/datasources/${data.row.metadata.uid}/data`;
                copy(dataUrl);
                notification.info("数据源链接已复制");
              }}
            >
              链接
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant={"outlined"}
              color={"error"}
              onClick={() => {
                setDeleteDatasourceId(data.row.metadata.uid);
                setDialogConfirmDeleteOpen(true);
              }}
            >
              移除
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CountdownDialog
        open={dialogConfirmDeleteOpen}
        title="确认删除"
        description="是否确认删除数据源？请确认影响，谨慎操作"
        handleClose={() => setDialogConfirmDeleteOpen(false)}
        handleConfirm={handleClickDelete}
      />
      <CustomTable
        rows={datasources.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default DatasourceTable;
