import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { GlobalConfig } from "../../globalConfig";
import useRouteStack from "../../hooks/useRouteStack";
import { Layout } from "@douyinfe/semi-ui";
import LayoutFooter from "./LayoutFooter";
import HeaderBreadcrumbs from "../Breadcrumb/HeaderBreadcrumbs";
import useSiteTitle from "../../hooks/useSiteTitle";
import LayoutHeader from "./LayoutHeader";
import LayoutSiderNav from "./LayoutSiderNav";
import { useWindowSize } from "../../hooks/useWindowSize";

let theme = createTheme({
  palette: {
    primary: {
      light: GlobalConfig.style.color.light,
      main: GlobalConfig.style.color.main,
      dark: GlobalConfig.style.color.dark,
    },
    // 自定义颜色：customBlack
    customBlack: {
      main: "#000000",
      contrastText: "#fff",
    },
    // 自定义颜色：statusRunning
    statusRunning: {
      main: "#1b76d2",
      contrastText: "#fff",
    },
    // 自定义颜色：statusSucceeded
    statusSucceeded: {
      main: "#09a979",
      contrastText: "#fff",
    },
    // 自定义颜色：statusFailed
    statusFailed: {
      main: "#bb3b3b",
      contrastText: "#fff",
    },
    // 自定义颜色：statusTerminated
    statusTerminated: {
      main: "#8a5841",
      contrastText: "#fff",
    },
    // 自定义颜色：statusScheduling
    statusScheduling: {
      main: "#b7a274",
      contrastText: "#fff",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiButton: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple!
        variant: "contained",
        size: "small",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#3F51B5",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

export default function LayoutContainer(props) {
  // 布局初始化相关 hooks
  useRouteStack();
  useSiteTitle();
  const { width, height } = useWindowSize();

  const { Header, Sider, Content } = Layout;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", minHeight: "100vh", overflow: "hidden" }}>
        <CssBaseline />

        <Box sx={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          <Layout>
            <Header>
              <LayoutHeader />
            </Header>
            <Layout>
              <Sider>
                <LayoutSiderNav />
              </Sider>
              <Content
                style={{
                  padding: "16px",
                  backgroundColor: GlobalConfig.style.layout.background,
                  display: "flex",
                  flexDirection: "column",
                  height: height - 69,
                }}
              >
                <HeaderBreadcrumbs />
                <div style={{ flex: 1, margin: "16px 0" }}>
                  {/* 路由页面出口 */}
                  <Outlet />
                </div>
                <LayoutFooter />
              </Content>
            </Layout>
          </Layout>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
