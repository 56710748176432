import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { Button, Paper, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useDeleteActionByUid } from "../../../../hooks/query/action";
import { formatDatetime } from "../../../../utils/datetime";
import CountdownDialog from "../../../../components/Dialog/Countdown";
import CustomTable from "../../../../components/Table/CustomTable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import TuneIcon from "@mui/icons-material/Tune";
import { ActionCreateTypeChip } from "../Chip";
import { actionCreateType } from "../../../../constants/action";

function ActionTable({ actions, isFetching, refetch, ...props }) {
  let navigate = useNavigate();
  const { mutate: deleteActionByUid } = useDeleteActionByUid();
  const [dialogConfirmDeleteOpen, setDialogConfirmDeleteOpen] = useState(false);
  const [deleteActionId, setDeleteActionId] = useState("");

  const handleClickDelete = () => {
    deleteActionByUid({ uid: deleteActionId }, { onSuccess: () => refetch() });
    setDialogConfirmDeleteOpen(false);
    setDeleteActionId("");
  };

  const columns = [
    {
      field: "ref",
      headerName: "Ref/UID",
      minWidth: 300,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.metadata.name} [uid: ${params.row.metadata.uid}]`,
      renderCell: (data) => {
        return (
          <div>
            <div style={{ fontWeight: "bold" }}>
              <Link
                color={"inherit"}
                underline="none"
                onClick={() => {
                  navigate(`/actions/edit?uid=${data.row.metadata.uid}`);
                }}
              >
                {data.row.metadata.ref}
              </Link>
            </div>
            <div style={{ fontSize: 12, color: "rgba(0,0,0,0.6)" }}>
              {data.row.metadata.uid}
            </div>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "名称",
      minWidth: 300,
      valueGetter: (data) => data.row.metadata.name,
    },
    {
      field: "type",
      headerName: "类型",
      minWidth: 130,
      valueGetter: (data) => data.row.spec.type,
    },
    {
      field: "create_type",
      headerName: "创建类型",
      minWidth: 200,
      renderCell: (data) => {
        if (
          data.row.metadata?.derived &&
          data.row.metadata?.derived?.base_action_ref &&
          data.row.metadata?.derived?.base_action_ref !== ""
        ) {
          return ActionCreateTypeChip(actionCreateType.Derived);
        } else {
          return ActionCreateTypeChip(actionCreateType.Base);
        }
      },
    },
    {
      field: "description",
      headerName: "描述",
      minWidth: 200,
      valueGetter: (data) => data.row.metadata.description,
    },
    {
      field: "created_at",
      headerName: "创建时间",
      width: 170,
      valueGetter: (data) => formatDatetime(data.row.metadata.created_at),
    },
    {
      field: "actions",
      headerName: "操作",
      width: 200,
      renderCell: (data) => {
        return (
          <Stack direction={"row"} spacing={1}>
            <Button
              startIcon={<TuneIcon />}
              variant={"outlined"}
              onClick={() =>
                navigate(`/actions/edit?uid=${data.row.metadata.uid}`)
              }
            >
              编辑
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant={"outlined"}
              color={"error"}
              onClick={() => {
                setDeleteActionId(data.row.metadata.uid);
                setDialogConfirmDeleteOpen(true);
              }}
            >
              移除
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CountdownDialog
        open={dialogConfirmDeleteOpen}
        title="确认删除"
        description="是否确认删除 Action？请确认影响，谨慎操作"
        handleClose={() => setDialogConfirmDeleteOpen(false)}
        handleConfirm={handleClickDelete}
      />
      <CustomTable
        rows={actions.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default ActionTable;
