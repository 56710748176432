import { useQuery } from "react-query";
import { listNcrImages, listNcrImageTags } from "../../api/ncr";

export const useListNcrImages = () => {
  return useQuery("repos", listNcrImages, {
    placeholderData: [],
    enabled: false,
  });
};

export const useListNcrImageTags = (repo) => {
  return useQuery(["repos", repo, "tags"], () => listNcrImageTags(repo), {
    placeholderData: [],
    enabled: false,
  });
};
