import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

function ConfirmDialog({open, title, onConfirm, onClose, ...props}) {

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="1000"
        >
            <DialogTitle>
                {title}
            </DialogTitle>

            <DialogContent>
                {props.children}
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant="text">取消</Button>
                <Button onClick={onConfirm} autoFocus>
                    确认
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;