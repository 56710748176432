import React from "react";
import { useNavigate } from "react-router-dom";
import {Divider} from "@mui/material";

function PageNotFound(props) {
  const navigate = useNavigate();

  return (
    <div>
      <h3>404</h3>
      <div>Sorry, page not found~</div>
      <Divider/>
      <div onClick={() => navigate(-1)}>
        click here back to <strong>prev page</strong>
      </div>
    </div>
  );
}

export default PageNotFound;
