import React from "react";
import TitledCard from "../../../components/TitledCard/TitledCard";
import { Grid } from "@mui/material";
import { Alert } from "@mui/lab";

function DashboardLogs(props) {
  return (
    <>
      <TitledCard title="诊断日志" subtitle="实例执行调试日志">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">暂未开放</Alert>
          </Grid>
        </Grid>
      </TitledCard>
    </>
  );
}

export default DashboardLogs;
