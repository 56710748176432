import React, { useEffect, useRef, useState } from "react";
import { Alert, TabContext, TabList, TabPanel } from "@mui/lab";
import AppBar from "@mui/material/AppBar";
import { Box, Button, Grid, Paper, Stack, Tab } from "@mui/material";
import OuterBox from "../../../components/Box/OuterBox/OuterBox";
import TitledCard from "../../../components/TitledCard/TitledCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetExecutionByUid } from "../../../hooks/query/execution";
import { getAuthUserName } from "../../../utils/cookie";
import {
  executionEventMsgType,
  executionStatus,
} from "../../../constants/execution";
import { useListConfirmingTaskExecutions } from "../../../hooks/query/task_execution";
import {
  taskExecutionEventMsgType,
  taskExecutionStatus,
} from "../../../constants/task_execution";
import DashboardDetails from "./DashboardDetails";
import DashboardGraphView from "./DashboardGraphView";
import DashboardLogs from "./DashboardLogs";
import DashboardConsoleView from "./DashboardConsoleView";
import usePageVisibility from "../../../hooks/usePageVisibility";

function ExecutionDashboard(props) {
  const navigate = useNavigate();
  const ws = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: execution,
    isLoading,
    isFetching,
    refetch,
  } = useGetExecutionByUid(searchParams.get("uid"));

  // data init
  const {
    data: confirmingTaskExecutions,
    refetch: refetchConfirmingTaskExecutions,
  } = useListConfirmingTaskExecutions({
    "metadata.owner_references.execution_uid": searchParams.get("uid"),
    status: taskExecutionStatus.Confirming,
  });

  const [activeTab, setActiveTab] = useState("console-view");
  const [graphData, setGraphData] = useState([]);
  const visibility = usePageVisibility();

  // 选项卡切换监听
  useEffect(() => {
    if (visibility) {
      console.log("Page is focused, resume polling");
      startStatusPolling();
    }
    return () => {
      // console.log("Page is not focused, stop polling");
      // ws.current?.close(
      //   1000,
      //   `execution events watch stopped~ (visible false), user: ${getAuthUserName()}`
      // );
    };
  }, [visibility]);

  // 选项卡聚焦监听
  useEffect(() => {
    const handleFocus = () => {
      console.log("Tab has focus");
      startStatusPolling();
      // setTabHasFocus(true);
    };

    const handleBlur = () => {
      console.log("Tab lost focus");
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  // 执行过程 websocket 监听
  useEffect(() => {
    startStatusPolling();
    console.log("Page open, start polling");
    return () => {
      console.log("Page close, stop polling");
      ws.current?.close(
        1000,
        `execution events watch stopped~ (page close), user: ${getAuthUserName()}`
      );
    };
  }, []);

  // 生成 graph data 用于 graph 展示
  useEffect(() => {
    if (
      execution.metadata.uid &&
      execution.metadata.uid !== "" &&
      isFetching === false
    ) {
      // 如果是初始化状态，则跳转页面
      ifInitializingAndRedirect(execution);

      let newGraphData = [];

      // 确认中的 task-execution 同步至 node data 里
      let tasksInConfirming = new Set();

      console.log("confirmingTaskExecutions", confirmingTaskExecutions);

      confirmingTaskExecutions.items?.forEach((te) => {
        const taskId = te.metadata?.owner_references?.task_id;
        tasksInConfirming.add(taskId);
      });

      Object.keys(execution.state?.tasks).forEach((taskId) => {
        const currentTask = execution.state?.tasks[taskId];
        let hasConfirmingTaskExecutions = false;
        if (Array.from(tasksInConfirming).includes(taskId)) {
          hasConfirmingTaskExecutions = true;
        }

        // node
        let newNode = {
          id: taskId,
          shape: "execution-node",
          data: {
            ...currentTask,
            id: taskId,
            has_confirming_task_executions: hasConfirmingTaskExecutions, // 是否存在需要确认的 task-execution
          },
          ports: [
            {
              id: `${taskId}-L`,
              group: "top",
            },
            {
              id: `${taskId}-R`,
              group: "bottom",
            },
          ],
        };
        newGraphData = [...newGraphData, newNode];

        currentTask.next?.forEach((next) => {
          // edge
          let newEdge = {
            id: `${taskId}-${next.do}`,
            shape: "execution-edge",
            source: {
              cell: `${taskId}`,
              port: `${taskId}-R`,
            },
            target: {
              cell: `${next.do}`,
              port: `${taskId}-L`,
            },
            zIndex: 0,
          };

          newGraphData = [...newGraphData, newEdge];
        });
      });
      setGraphData(newGraphData);
    }
  }, [execution, confirmingTaskExecutions]);

  useEffect(() => {
    const viewType = searchParams.get("view");
    if (viewType === "graph") {
      setActiveTab("graph-view");
    } else if (viewType === "console") {
      setActiveTab("console-view");
    }
  }, []);

  // 启动监听
  const startStatusPolling = () => {
    const executionUid = searchParams.get("uid");
    if (executionUid && executionUid !== "") {
      // 初始化需要确认的 task-executions 列表
      refetchConfirmingTaskExecutions();

      if (ws.current !== null) {
        ws.current?.close(
          1000,
          `execution events watch clear, user: ${getAuthUserName()}`
        );
      }

      // websocket 注意，要用 ingress 地址，客户端从外部请求需要访问 ingress 地址，nginx 配置 proxy_pass 比较麻烦
      ws.current = new WebSocket(
        `wss://${process.env.REACT_APP_OPS_SERVER}/api/v1/stream/executions/${executionUid}/events`
      );
      //连接错误时触发
      ws.current.onerror = function (err) {
        console.log("ws: execution events watch ERROR:", err);
      };
      //连接打开时触发
      ws.current.onopen = function (evt) {
        console.log("ws: execution events watch started~");
        ws.current.send(
          `execution events watch started~, user: ${getAuthUserName()}`
        );
      };
      //接收到消息时触发
      ws.current.onmessage = function (evt) {
        const eventMsg = JSON.parse(evt.data);
        switch (eventMsg.type) {
          case executionEventMsgType.Updated:
            refetch();
            break;
          case taskExecutionEventMsgType.Confirming:
            refetchConfirmingTaskExecutions();
            break;
          case taskExecutionEventMsgType.Updated:
            refetchConfirmingTaskExecutions();
            break;
        }
      };
      //连接关闭时触发
      ws.current.onclose = function (evt) {
        console.log("ws: execution events watch stopped~");
      };
    }
  };

  // 初始化中的实例拦截跳转至初始化界面
  const ifInitializingAndRedirect = (execution) => {
    if (execution && execution.metadata?.uid !== "") {
      if (execution.status === executionStatus.Initializing) {
        navigate(
          `/workflows/exec?uid=${execution.metadata.owner_references?.workflow_uid}&from_execution_uid=${execution.metadata.uid}`
        );
      }
    }
  };

  return (
    <TabContext value={activeTab}>
      <AppBar
        color="inherit"
        component="div"
        position="static"
        elevation={1}
        sx={{ zIndex: 0 }}
      >
        <TabList
          onChange={(e, tabValue) => {
            setActiveTab(tabValue);
          }}
          aria-label="lab API tabs example"
        >
          <Tab label="执行面板" value="console-view" />
          <Tab label="流程拓扑" value="graph-view" />
          <Tab label="实例详情" value="details" />
          <Tab label="诊断日志" value="debug-logs" />
        </TabList>
      </AppBar>

      <OuterBox>
        {/* 执行面板 */}
        <TabPanel value="console-view" sx={{ p: 0 }}>
          <DashboardConsoleView
            execution={execution}
            confirmingTaskExecutions={confirmingTaskExecutions}
          />
        </TabPanel>

        {/* 流程拓扑 */}
        <TabPanel value="graph-view" sx={{ p: 0 }}>
          <DashboardGraphView
            graphData={graphData}
            executionUid={searchParams.get("uid")}
            execution={execution}
            refetchExecution={refetch}
          />
        </TabPanel>

        {/* 实例详情 */}
        <TabPanel value="details" sx={{ p: 0 }}>
          <DashboardDetails
            execution={execution}
            isLoading={isLoading}
            refetchExecution={refetch}
          />
        </TabPanel>

        {/* 诊断日志 */}
        <TabPanel value="debug-logs" sx={{ p: 0 }}>
          <DashboardLogs />
        </TabPanel>
      </OuterBox>
    </TabContext>
  );
}

export default ExecutionDashboard;
