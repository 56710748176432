import React, {useRef} from 'react';
import ConfirmDialog from "../../../components/Dialog/Confirm";
import {Box, Grid, LinearProgress} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-mui";
import {useNotification} from "../../../hooks/common/useNotification";
import {useNavigate} from "react-router-dom";
import {useCreateWorkflow} from "../../../hooks/query/workflow";

function WorkflowCreateDialog({open, setOpen}) {

    const notification = useNotification()
    const navigate = useNavigate()
    const formRef = useRef(null)

    const {mutate: createWorkflow} = useCreateWorkflow()

    return (
        <ConfirmDialog
            open={open}
            title="创建工作流"
            onClose={() => setOpen(false)}
            onConfirm={() => formRef.current.submitForm()}>
            <Box sx={{width: 600}}>
                <Formik
                    innerRef={formRef}
                    initialValues={{
                        metadata: {
                            name: "",
                            description: ""
                        },
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        let newWorkflow = {
                            metadata: {
                                name: values.metadata.name,
                                description: values.metadata.description,
                            },
                        }

                        console.log("newWorkflow:", newWorkflow)

                        createWorkflow(newWorkflow, {
                            onSuccess: (data) => {
                                setSubmitting(false);
                                notification.success("工作流创建成功", `UID: ${data.metadata.uid}`)
                                navigate(`/workflows/edit?uid=${data.metadata.uid}`)
                            },
                            onError: (err) => {
                                setSubmitting(false);
                            }
                        })
                    }}
                >
                    {({submitForm, isSubmitting}) => (
                        <Form>
                            <Grid container spacing={2} sx={{py: 2}}>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="名称"
                                        name="metadata.name"
                                        size="small"
                                        validate={(val) => {
                                            let error;
                                            if (!val) {
                                                error = `名称为必填项`;
                                            }
                                            return error;
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label="描述"
                                        name="metadata.description"
                                        size="small"
                                    />
                                </Grid>

                                {isSubmitting && <Grid item xs={12}><LinearProgress/></Grid>}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </ConfirmDialog>
    );
}

export default WorkflowCreateDialog;