import {
  del,
  get,
  handleResponseEnvelope,
  patch,
  post,
  put,
} from "../utils/request";
import { localGetCurrentNamespace } from "../localstorage/namespace";

export const createTrigger = async (payload) => {
  const { data } = await post(`/proxy/@ops/api/v1/triggers`, payload);
  return handleResponseEnvelope(data);
};

export const listTriggers = async (listOptions = {}) => {
  const { data } = await get(`/proxy/@ops/api/v1/triggers`, listOptions);
  return handleResponseEnvelope(data);
};

export const getTriggerByUid = async (uid) => {
  const { data } = await get(`/proxy/@ops/api/v1/triggers/${uid}`);
  return handleResponseEnvelope(data);
};

export const debugTrigger = async (payload) => {
  const { data } = await post(`/proxy/@ops/api/v1/triggers/debug`, payload);
  return handleResponseEnvelope(data);
};

export const patchTriggerByUid = async ({ uid, payload }) => {
  const { data } = await patch(`/proxy/@ops/api/v1/triggers/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const saveTriggerByUid = async ({ uid, payload }) => {
  const { data } = await put(`/proxy/@ops/api/v1/triggers/${uid}`, payload);
  return handleResponseEnvelope(data);
};

export const deleteTriggerByUid = async ({ uid, force }) => {
  if (force === undefined) {
    force = false;
  }
  const { data } = await del(
    `/proxy/@ops/api/v1/triggers/${uid}?force=${force}`
  );
  return handleResponseEnvelope(data);
};
