import React from "react";
import { useMutation, useQuery } from "react-query";
import {
  confirmTaskByExecutionUidAndTaskId,
  createExecution,
  createExecutionWorkOrderByUid,
  deleteExecutionByUid,
  getExecutionByUid,
  listExecutionLogs,
  listExecutions,
  patchExecutionByUid,
  rerunTaskByExecutionUidAndTaskId,
  saveExecutionByUid,
} from "../../api/execution";
import { confirmTaskExecutionByUid } from "../../api/task_execution";

export const useCreateExecution = () => {
  return useMutation(createExecution);
};

export const useListExecutions = (listOptions = {}, scope = "") => {
  // TODO
  listOptions.scope = scope;

  return useQuery(
    ["executions", JSON.stringify(listOptions)],
    () => listExecutions(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
    }
  );
};

export const useGetExecutionByUid = (uid, options = {}) => {
  return useQuery(["executions", uid], () => getExecutionByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
      },
      status: "",
      state: {},
    },
    ...options,
  });
};

export const useCreateExecutionWorkOrderByUid = () => {
  return useMutation(createExecutionWorkOrderByUid);
};

export const usePatchExecutionByUid = () => {
  return useMutation(patchExecutionByUid);
};

export const useSaveExecutionByUid = () => {
  return useMutation(saveExecutionByUid);
};

export const useDeleteExecutionByUid = () => {
  return useMutation(deleteExecutionByUid);
};

export const useConfirmTaskByExecutionUidAndTaskId = () => {
  return useMutation(confirmTaskByExecutionUidAndTaskId);
};

export const useRerunTaskByExecutionUidAndTaskId = () => {
  return useMutation(rerunTaskByExecutionUidAndTaskId);
};
