import { GlobalConfig } from "../globalConfig";

export const localSaveCurrentNamespace = (ns) => {
  localStorage.removeItem("currentNamespace");
  localStorage.setItem("currentNamespace", ns);
};
export const localGetCurrentNamespace = () => {
  if (localStorage.getItem("currentNamespace") === null) {
    return GlobalConfig.ops.defaultNamespace;
  } else {
    return localStorage.getItem("currentNamespace");
  }
};
export const localRemoveCurrentNamespace = () => {
  localStorage.removeItem("currentNamespace");
};
