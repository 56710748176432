import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseEnvelope } from "../../utils/request";
import { ActionflowService } from "../../services/actionflow";

export const listActionflows = createAsyncThunk(
  "actionflow/listAll",
  async ({ listOptions }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionflowService.listAll({ listOptions })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getActionflowByUid = createAsyncThunk(
  "actionflow/getByUid",
  async ({ uid }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionflowService.getByUid({ uid })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const patchActionflowByUid = createAsyncThunk(
  "actionflow/patchByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionflowService.patchByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const saveActionflowByUid = createAsyncThunk(
  "actionflow/saveByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionflowService.saveByUid({ uid, data })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const deleteActionflowByUid = createAsyncThunk(
  "actionflow/deleteByUid",
  async ({ uid, force }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        (await ActionflowService.deleteByUid({ uid, force })).data
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const actionflowSlice = createSlice({
  name: "actionflow",
  initialState: {
    current: {
      metadata: {
        uid: "",
        name: "",
      },
    },
    list: { items: [], total: 0 },
    isCurrentLoading: false,
    isListLoading: false,
    error: "",
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.current = { name: "", uid: "" };
    },
    clearList: (state, action) => {
      state.list = { items: [], total: 0 };
    },
  },
  extraReducers: {
    [listActionflows.pending]: (state, action) => {
      state.isListLoading = true;
    },
    [listActionflows.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isCurrentLoading = false;
    },
    [listActionflows.rejected]: (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    [getActionflowByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [getActionflowByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [getActionflowByUid.fulfilled]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [patchActionflowByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [patchActionflowByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listActionflows.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [saveActionflowByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [saveActionflowByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listActionflows.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [deleteActionflowByUid.fulfilled]: (state, action) => {},
    [deleteActionflowByUid.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { clearCurrent, clearList } = actionflowSlice.actions;

export default actionflowSlice.reducer;
