import React from "react";
import { Box, Button, Chip, Paper, Stack, Tooltip } from "@mui/material";
import CustomTable from "../../../components/Table/CustomTable";
import { useNavigate } from "react-router-dom";
import { useListEvents } from "../../../hooks/query/event";
import { formatDatetime } from "../../../utils/datetime";
import PreviewIcon from "@mui/icons-material/Preview";
import { useListSyslogs } from "../../../hooks/query/syslog";
import Link from "@mui/material/Link";

function EventTriggerLogs({ selector }) {
  let navigate = useNavigate();

  const { data: logs, isFetching, refetch } = useListSyslogs(selector);

  const columns = [
    {
      field: "level",
      headerName: "等级",
      width: 100,
      valueGetter: (params) => params.row.level,
      renderCell: (data) => {
        switch (data.row.level) {
          case "INFO":
            return <Chip label="INFO" color="info" size="small" />;
          case "WARN":
            return <Chip label="WARN" color="warning" size="small" />;
          case "ERROR":
            return <Chip label="ERROR" color="error" size="small" />;
          case "DEBUG":
            return <Chip label="DEBUG" color="default" size="small" />;
        }
      },
    },
    {
      field: "created_at",
      headerName: "时间",
      width: 180,
      valueGetter: (params) => formatDatetime(params.row.metadata.created_at),
    },
    {
      field: "content",
      headerName: "内容",
      flex: 1,
      valueGetter: (params) => params.row.content,
      renderCell: (data) => (
        <Tooltip title={data.row.content} placement="top">
          <Box>{data.row.content}</Box>
        </Tooltip>
      ),
    },
  ];

  return (
    <Paper sx={{ width: "100%" }}>
      <CustomTable
        rows={logs.items}
        columns={columns}
        getRowId={(row) => row.metadata.uid}
        loading={isFetching}
      />
    </Paper>
  );
}

export default EventTriggerLogs;
