import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseEnvelope } from "../../utils/request";
import { DatasourceService } from "../../services/datasource";

export const listDatasources = createAsyncThunk(
  "datasource/listAll",
  async ({ listOptions }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        await DatasourceService.listAll({ listOptions })
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const getDatasourceByUid = createAsyncThunk(
  "datasource/getByUid",
  async ({ uid }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(await DatasourceService.getByUid({ uid }));
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const patchDatasourceByUid = createAsyncThunk(
  "datasource/patchByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        await DatasourceService.patchByUid({ uid, data })
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const saveDatasourceByUid = createAsyncThunk(
  "datasource/saveByUid",
  async ({ uid, data }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        await DatasourceService.saveByUid({ uid, data })
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const deleteDatasourceByUid = createAsyncThunk(
  "datasource/deleteByUid",
  async ({ uid, force }, { rejectWithValue }) => {
    try {
      return handleResponseEnvelope(
        await DatasourceService.deleteByUid({ uid, force })
      );
    } catch (e) {
      return rejectWithValue(e.toString());
    }
  }
);

export const datasourceSlice = createSlice({
  name: "datasource",
  initialState: {
    current: {
      metadata: {
        uid: "",
        name: "",
      },
    },
    list: { items: [], total: 0 },
    isCurrentLoading: false,
    isListLoading: false,
    error: "",
  },
  reducers: {
    clearCurrent: (state, action) => {
      state.current = { name: "", uid: "" };
    },
    clearList: (state, action) => {
      state.list = { items: [], total: 0 };
    },
  },
  extraReducers: {
    [listDatasources.pending]: (state, action) => {
      state.isListLoading = true;
    },
    [listDatasources.fulfilled]: (state, action) => {
      state.list = action.payload;
      state.isCurrentLoading = false;
    },
    [listDatasources.rejected]: (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    },
    [getDatasourceByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [getDatasourceByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [getDatasourceByUid.fulfilled]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [patchDatasourceByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [patchDatasourceByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listDatasources.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [saveDatasourceByUid.pending]: (state, action) => {
      state.isCurrentLoading = true;
    },
    [saveDatasourceByUid.fulfilled]: (state, action) => {
      state.current = action.payload;
      state.isCurrentLoading = false;
    },
    [listDatasources.rejected]: (state, action) => {
      state.isCurrentLoading = false;
      state.error = action.payload;
    },
    [deleteDatasourceByUid.fulfilled]: (state, action) => {},
    [deleteDatasourceByUid.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { clearCurrent, clearList } = datasourceSlice.actions;

export default datasourceSlice.reducer;
