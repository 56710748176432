import React from 'react';
import Box from "@mui/material/Box";

function ContentArea(props) {
    return (
        <Box sx={{p: 2}}>
            {props.children}
        </Box>
    );
}

export default ContentArea;