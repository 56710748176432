import { useMutation, useQuery } from "react-query";
import {
  createDatasource,
  debugDatasource,
  deleteDatasourceByUid,
  getDatasourceByUid,
  getDatasourceDataByUid,
  listDatasources,
  patchDatasourceByUid,
  saveDatasourceByUid,
} from "../../api/datasource";

export const useCreateDatasource = () => {
  return useMutation(createDatasource);
};

export const useListDatasources = (listOptions) => {
  return useQuery(
    ["datasources", JSON.stringify(listOptions)],
    () => listDatasources(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
    }
  );
};

export const useGetDatasourceByUid = (uid) => {
  return useQuery(["datasources", uid], () => getDatasourceByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
      },
      status: "",
      state: {},
    },
  });
};

export const useDebugDatasource = () => {
  return useMutation(debugDatasource);
};

export const usePatchDatasourceByUid = () => {
  return useMutation(patchDatasourceByUid);
};

export const useSaveDatasourceByUid = () => {
  return useMutation(saveDatasourceByUid);
};

export const useDeleteDatasourceByUid = () => {
  return useMutation(deleteDatasourceByUid);
};
