import React from 'react';
import {CircularProgress} from "@mui/material";
import Box from "@mui/material/Box";

function LoadingProgress() {
    return (
        <Box sx={{textAlign: 'center', p: 2, margin: '0 auto'}}>
            <CircularProgress/>
        </Box>
    );
}

export default LoadingProgress;