import { useMutation, useQuery } from "react-query";
import {
  deleteEventByUid,
  getEventByUid,
  listEvents,
  replayEvent,
} from "../../api/event";

export const useReplayEvent = () => {
  return useMutation(replayEvent);
};

export const useListEvents = (listOptions) => {
  return useQuery(
    ["events", JSON.stringify(listOptions)],
    () => listEvents(listOptions),
    {
      placeholderData: {
        items: [],
        total: 0,
      },
    }
  );
};

export const useGetEventByUid = (uid) => {
  return useQuery(["events", uid], () => getEventByUid(uid), {
    placeholderData: {
      metadata: {
        uid: "",
      },
      status: "",
      state: {},
    },
  });
};

export const useDeleteEventByUid = () => {
  return useMutation(deleteEventByUid);
};
