import React from 'react';
import ContentArea from "../../components/ContentArea/ContentArea";
import {Grid} from "@mui/material";
import ExecutionTable from "./Table";

function Execution(props) {
    return (
        <ContentArea>
            <Grid container spacing={2}>
                <Grid sx={{mb: 3}} item xs={12}>
                    <ExecutionTable/>
                </Grid>
            </Grid>
        </ContentArea>
    );
}

export default Execution;